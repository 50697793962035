Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableuserprofiles2";
exports.labelBodyText = "customisableuserprofiles2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.numberRegisteredTxt = "This number is already registerd.";
exports.datePlaceholder = "mm/dd/yyyy";
exports.dateRequired = "Date of birth is required";
exports.dateIsInvalid = "Date must be above 18+ year";
// Customizable Area End