// Customizable Area Start
import React from 'react'

export const DeactivationIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.08 12.59L13.67 10H4V8H13.67L11.08 5.41L12.5 4L17.5 9L12.5 14L11.08 12.59ZM16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V6.67L16 4.67V2H2V16H16V13.33L18 11.33V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16Z" fill="#999999" />
    </svg>
  )
}
// Customizable Area End