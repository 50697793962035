// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router";
import moment from "moment";
import { universalApiCall } from "../../../components/src/universalAPICallAndMore";
import { EmployeeData,defaultEmployeeData } from "./consts";


export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  isLoading: boolean,
  ErrorText: boolean,
  PasscodeAttempet: number,
  PassCodeFalse: boolean,
  token:any;
  isModalVisible:boolean;
  selectedWorkerName: string;
  reactivationAccountsList:any;
  deactivationAccountsList:any;
  smsSuccess:boolean;
  mobileForOTPCheck:string
  confirmedTokenAfterOTP:string;
  employeeData:EmployeeData;
  selectedEmployeeId: any;
  changeModalViewDeactivation:boolean;
  changeModalViewReactivation:boolean;
  phonenoError:string

}

interface SS {
  id: string;
}

export default class AccountCreateController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postAccountCreateApiCall: string = "";
  getReactivationAccountsList: string = "";
  getDeactivationAccountsList: string = "";
  putDeactivateEmployeeAccount:string=""; 
  putReactivateEmployeeAccount:string="";
  _isMounted = false;

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      PasscodeAttempet: 0,
      isLoading: false,
      PassCodeFalse: false,
      ErrorText: false,
      token:"",
      isModalVisible: false,
      selectedWorkerName: "",
      reactivationAccountsList:null,
      deactivationAccountsList:null,
      smsSuccess:false,
      mobileForOTPCheck:"",
      confirmedTokenAfterOTP:"",
      employeeData:defaultEmployeeData,
      selectedEmployeeId: null,
      changeModalViewDeactivation:false,
      changeModalViewReactivation:false,
      phonenoError:""
    };
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
   
    if(getName(MessageEnum.SessionResponseMessage) === message.id){
      let token=message.getData(getName(MessageEnum.SessionResponseToken));
    
      if (this._isMounted) {
      this.setState({token:token},async ()=>{         
        if (this.state.token!=="") {
                await this.getAccountsToDeactivate();
              
               await  this.getAccountsToReactivate();
          
            
            }
      });
    }
    
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    await this.handleDifferentAPISessions(apiRequestCallId,responseJson);  
  
  
    await this.handleDifferentAPISessions(apiRequestCallId,responseJson);  
  
  
     // Customizable Area Start
     // Customizable Area End
  }


  

  

  SetNationality = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Nationality", e.target.value);
  }

  SetFullName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Fullname", e.target.value);
  }

  SetDateofBirth = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("DateofBirth", e.target.value);
  }

  SetUserId = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("UserID", e.target.value);
  }
 
  SetPhoneNumber = (value: string, data: any, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({phonenoError:''})
    setFieldValue("MobileNo", value);
  }
 

  ConfirmPasscode = async (values: {
    Fullname: string,
    Nationality: string,
    DateofBirth: string,
    UserID: string,
    MobileNo:string,
  }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   

    this.postAccountCreateApiCall = requestMessage.messageId;
    let Body = {
      "token": this.state.token,
      
        "account": {
          "full_name": values.Fullname,
          "nationality": values.Nationality,
          "dob": moment(values.DateofBirth).format("YYYY/MM/DD"),
          "user_name":"" ,
          "user_id": values.UserID,
          "mobile_number":"+" + values.MobileNo
      }
      
    }
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "account_block/accounts/temporary_account_creation"
    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.setState({employeeData:{
      ...this.state.employeeData,
      full_name: values.Fullname,
      nationality: values.Nationality,
      date_of_birth: moment(values.DateofBirth).format("YYYY-MM-DD"),
      user_name: values.UserID,
    
    },mobileForOTPCheck:values.MobileNo})

  

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

 async toggleModal (workerName:string,employeeId:any) {
    if (this.state.changeModalViewDeactivation===true) {
      this.props.history.push({
        pathname: `/AccountReactivation`
      });
      this.setState({changeModalViewDeactivation:false})

    }
    if (this.state.changeModalViewReactivation===true) {
      this.props.history.push({
        pathname: `/AccountDeactivation`
      });
      this.setState({changeModalViewReactivation:false})

    }
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
      selectedWorkerName: workerName,
      selectedEmployeeId: employeeId,
 
    }));
 
  }
 async componentDidMount() {
    this._isMounted = true;
  
 
  }


 async componentWillUnmount() {
    this._isMounted = false;
  }


  async getAccountsToReactivate() {
  

    this.getReactivationAccountsList = await universalApiCall({
 
      method: "GET",
      endPoint: "account_block/accounts/list_deactivated_account",
    },this.state.token);
  }
  async getAccountsToDeactivate() {
  
    this.getDeactivationAccountsList = await universalApiCall({
      method: "GET",
      endPoint: "account_block/accounts/list_reactivated_account",
 
    },this.state.token);
  }

  getToken = () => {
    this._isMounted=true;
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  handleResponseErrors = (responseJson:any, setStateCallback:any) => {
    if (responseJson && responseJson.errors) {
      if (typeof responseJson.errors === 'string') {
        setStateCallback({ phonenoError: responseJson.errors, isLoading: false });
      } else if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
        const errorData = responseJson.errors[0];
        const errorMessage = errorData.mobile_number || 'Unknown error';
        setStateCallback({ phonenoError: errorMessage, isLoading: false });
      } else {
        setStateCallback({ smsSuccess: true });
      }
    } else {
      setStateCallback({ smsSuccess: true });
    }
  };


  async handleDifferentAPISessions(apiRequestCallId: string, responseJson: any){
 
    switch (apiRequestCallId) {
      case this.postAccountCreateApiCall:
          this.handleResponseErrors(responseJson, this.setState.bind(this));
          break;
  
      case this.getDeactivationAccountsList:
       
          this.setState({ reactivationAccountsList: responseJson });
          break;

      case this.getReactivationAccountsList:
     
          this.setState({ deactivationAccountsList: responseJson });
          break;

      case this.putDeactivateEmployeeAccount:
          if (responseJson.message==="Employee Account deactivated successfully") {
            this.setState({changeModalViewDeactivation:true});
          }
          break;

      case this.putReactivateEmployeeAccount:
       
          if (responseJson.message==="Employee Account reactivated successfully") {
            this.setState({changeModalViewReactivation:true});
          }
          break;


      default:
        break;
    }
  }

  async deactivateEmployeeAccount(itemID:any){
  
  
    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putDeactivateEmployeeAccount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `/account_block/accounts/deactivate_employee_account?id=${itemID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
  
    this.setState({changeModalViewDeactivation:true});

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async reactivateEmployeeAccount(itemID:any){

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putReactivateEmployeeAccount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `/account_block/accounts/reactivate_employee_account?id=${itemID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 formatMembersCount(membersArray: any[]): string {
    const count = membersArray.length;
  
    if (count === 0) {
      return "0 MEMBERS";
    } else if (count === 1) {
      return "1 MEMBER";
    } else {
      return `${count} MEMBERS`;
    }
  }
  
}
// Customizable Area End