import React from "react";
import { styled } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ProjectText from "./ProjectText";
import { ContainedButton } from "./GlobalButton";
import ProjectSideBar from "./ProjectSideBar";
import { withRouter } from "react-router-dom";
import LogoutAlertController from "./LogoutAlertController.web";

const DialogContainer = styled("div")({
  "&": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 40px",
    width: "600px",
    height: "252px",
    borderRadius: "8px 8px 32px 8px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  },
  "& .CloseIcon": {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});

// Customizable Area Start

const CustomDialogueContainer = styled(DialogContainer)({

  '@media (max-width: 1200px)':{
    width: "400px"
  },
  '@media (max-width: 400px)':{
    width: "350px"
  }
})

const DialogWrapper = styled("div")({
  position: "fixed",
  top: "50%",
  left: "calc(50% + 220px)",
  transform: "translate(-50%, -50%)",
  '@media (max-width: 900px)':{
    left: "calc(30% + 220px)",
  },
  '@media (max-width: 700px)':{
    left: "50%",
  }
})

class LogoutAlert extends LogoutAlertController {
  render() {
    return (
      <ProjectSideBar>
        <DialogWrapper

        >
          <CustomDialogueContainer aria-labelledby="customized-dialog-title">
            <div className="dialog-title" id="customized-dialog-title">
              <IconButton aria-label="close" className="CloseIcon">
                <CloseIcon />
              </IconButton>
            </div>
            <div style={{ padding: "40 0px" }}>
              <ProjectText
                variant="subtitle1"
                style={{ fontWeight: 700, fontSize: "20px", color: "black" }}
              >
                Logout of your account?
              </ProjectText>
              <ProjectText
                variant="subtitle2"
                style={{ fontWeight: 400, fontSize: "14px", color: "black" }}
              >
                Are you sure you want to logout from all devices?
              </ProjectText>
            </div>

            <Divider />

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                gap: "16px",
                padding: "24px",
              }}
            >
              <ContainedButton
                // bgcolor="success"
                style={{
                  margin: "0px",
                  borderRadius: "4px",
                  padding: "10px 24px",
                  backgroundColor: "#EDF3F8",
                  color: "black",
                }}
                size="medium"
                onClick={() => this.cancelHandler()}
              >
                CANCEL
              </ContainedButton>

              <ContainedButton
                bgcolor="success"
                style={{
                  margin: "0px",
                  borderRadius: "4px",
                  padding: "10px 24px",
                }}
                size="medium"
                onClick={() => this.logoutHandler()}
              >
                LOGOUT
              </ContainedButton>
            </div>
          </CustomDialogueContainer>
        </DialogWrapper>
      </ProjectSideBar>
    );
  }
}

export default withRouter(LogoutAlert);

// Customizable Area End
