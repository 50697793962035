// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { setStorageData } from "../../../framework/src/Utilities";
export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextFiledTypeForConfirm: boolean,
  otp: string | number,
  Sendotp: boolean,
  VerifyCode: Array<string> | string,
  PassWordMatchText: string,
  ErrorPopUpType: string
}

interface SS {
  id: string;
}

export default class NewPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      token: "",
      termsCondsId: "",
      TextFiledTypeForConfirm: false,
      Phone: "",
      isLoading: false,
      PhoneInputBorder: false,
      Password: "",
      TextFiledType: false,
      CountryCode: "",
      otp: "",
      Sendotp: false,
      VerifyCode: "",
      PassWordMatchText: "",
      ErrorPopUpType: ""
    };
  }

  SetTextFiledType = () => {
    this.setState({
      TextFiledType: !this.state.TextFiledType
    })
  }
  SetTextFiledTypeForConfirm = () => {
    this.setState({
      TextFiledTypeForConfirm: !this.state.TextFiledTypeForConfirm
    })
  }

  SetNewPassword = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("newPassword", e.target.value); this.setState({ Password: e.target.value,ErrorPopUpType:"" })
  }

  SetConfirmPassoword = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("ConfirmPassword", e.target.value);
    this.setState({ Password: e.target.value,ErrorPopUpType:"" })
  }
  PasswordChangeAPi: string = "";

  LoginApiFuncation = (values: { ConfirmPassword: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SetNewPassowrd = requestMessage.messageId;
    let Body = {
      "token": localStorage.getItem("newpassword"),
      "data": {
        "type": "sms_account",
        "attributes": {
          "password": values?.ConfirmPassword
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/set_password"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  ChangePassword = (values: { ConfirmPassword: string, newPassword: string }) => {
    if (values?.ConfirmPassword !== values?.newPassword) {
      this.setState({
        Sendotp: true,
        PassWordMatchText: "Password do not match",
        ErrorPopUpType: "Error"
      }, ()=> {
        let popupTimeOut = setTimeout(()=> {
        clearTimeout(popupTimeOut)
          this.setState({
            Sendotp: false
          })
        }, 5000)
      })
    } else {
      this.setState({
        Sendotp: true,
        PassWordMatchText: "Password Match",
        ErrorPopUpType: "Success"
      })
      const forhotpassword = localStorage.getItem("LastPath")?.includes("/signup")
      if (forhotpassword) {
      let popupTimeOut =  setTimeout(() => {
        clearTimeout(popupTimeOut)
        this.props.history.replace({
          pathname: "/PasswordCreated"
        })
        setStorageData("UserData", values.ConfirmPassword)
      },3000)
      } else {
        this.ForgotApiFuncation(values)
      }
    }
  }

  ForgotApiFuncation = (values: { ConfirmPassword: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SetNewPassowrdForgot = requestMessage.messageId;
    let Body = {
      "data": {
        "token": localStorage.getItem("forgotOtpScreen"),
        "new_password": values?.ConfirmPassword
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/passwords"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  SetNewPassowrd: string = "";
  SetNewPassowrdForgot: string = "";

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdOtp = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonOtp = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallIdOtp === this.SetNewPassowrd) {
        this.SetNewPassowrdApiResponse(responseJsonOtp)
      }
      if (apiRequestCallIdOtp === this.SetNewPassowrdForgot) {
        this.SetNewPassowrdForgotResponse(responseJsonOtp)
      }
    }
  }

  SetNewPassowrdForgotResponse = (responseJsonOtp: { errors: object, error: object, data: { attributes: { pin: string | number } }, meta: { token: string, pin: string | number } }) => {
    const forhotpassword = localStorage.getItem("forgot") === null
    let apiRespond = responseJsonOtp?.errors ?? responseJsonOtp?.error
    if (apiRespond) {
      for (const obj of Object.values(apiRespond)) {
        for (const key in obj) {
          this.setState({
            PassWordMatchText:  obj[key],
            ErrorPopUpType: "Error"
          }, ()=> {
            let popupTimeOut = setTimeout(()=> {
              clearTimeout(popupTimeOut)
                this.setState({
                  Sendotp: false
                })
              }, 5000)
          })
        }
      }

    } else {
      if (!forhotpassword) {
        localStorage.removeItem("forgot");
      }
      this.props.history.replace({
        pathname: "/PasswordChanged"
      })
    }
  }

  SetNewPassowrdApiResponse = (responseJsonOtp: { errors: object, error: object, data: { attributes: { pin: string | number } }, meta: { token: string, pin: string | number } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {
      let data = responseJsonOtp?.errors ?? responseJsonOtp?.error
      for (const obj of Object.values(data)) {
        this.setState({
          PassWordMatchText:  obj,
          ErrorPopUpType: "Error"
        }, ()=> {
          let popupTimeOut = setTimeout(()=> {
            clearTimeout(popupTimeOut)
              this.setState({
                Sendotp: false
              })
            }, 5000)
        })
      }
    } else {
      this.props.history.replace({
        pathname: "/PasswordCreated"
      })
      localStorage.setItem("SetPasswordToken", responseJsonOtp.meta.token)
    }
  }

}
// Customizable Area End