// Customizable Area Start
import React from "react";
import PasswordChangedController from "./PasswordChangedController.web";
import LoginLayout from "../../../components/src/PassWordResetLayout";
import ProjectText from "../../../components/src/ProjectText";
import 'react-phone-input-2/lib/style.css'
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import { ContainedButton } from '../../../components/src/GlobalButton'
import { Box } from "@material-ui/core";
const Check = require("../assets/check.png");
class PasswordChanged extends PasswordChangedController {
  render() {
    return (
      <PasswordChangedLayout>
      <LoginLayout BackButton BackButtonOnClick={this.NavigateToBack} Buttontext="Back to login" ButtonFuncation={this.NavigateToLogin} Descripetion="Password changed successfully" />
      </PasswordChangedLayout>
    )
  }
}

export default withRouter(PasswordChanged)
const PasswordChangedLayout = styled(Box)({
  "& .logoContainer": {
    '& img': {
      "@media (max-width: 414px)": {
        display:"none"
      } ,
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  "& .DescripionText": {
    "@media (max-width: 414px)": {
     width:"98vw"
    } ,
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"98vw"
    },   
  },
  "& .boyImage": {
    '& img': {
      height: "59.10vh",
      width: "30.47vw",
      "@media (min-width:360px) and (max-width: 449px)": {
        height: "100%",
        width: "100%",
       } ,
       "@media (min-width:450px) and (max-width:1280px)": {
        height: "100%",
        width: "100%",
       }, 
    },
  }
})
// Customizable Area End