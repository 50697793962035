import React from "react";
 // Customizable Area Start
import HelpCentreController,{Props} from "./HelpCentreController";
import { Box, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProjectSideBar from "../../../components/src/ProjectSideBar";
// Customizable Area End

 // Customizable Area Start
 const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);
 // Customizable Area End
export default class FAQ extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 
  render() {
    // Customizable Area Start
    console.log("help center from faq",this.state.helpCentreNewQA)
    return (
       <>
       <ProjectSideBar pageThisVar={this}>
       <HomePageStyle>
            <Box sx={{marginBottom:'5%'}}>
                <Box sx={webStyle.topBartext}>
                  FAQ:
                </Box>

                <Box sx={webStyle.textContainer}>
                  {this.state.helpCentreNewQA.map((item:any,i:any) =>(
                     <Accordion>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                       style={webStyle.faq}
                     >
                       <Box sx={webStyle.titleTextContainer}>
                     <Typography style={webStyle.titleText1}>{i+1}</Typography>
                     <Typography style={webStyle.titleText2}>{item.attributes.question}</Typography>
                     </Box>
                     
                       
                     </AccordionSummary>
                     <AccordionDetails>
                       <Box sx={webStyle.subtitleContainer}>
                     
                       <ol style={webStyle.list}>
                         {item.attributes.answer.split('\r\n').map((step:any, index:any) => (
                         <>
                      { step!=="" && 
                      
                        (
                            <>
                              <Typography style={webStyle.subtitle}>
                                      <li key={index}>
                                {step}
                                </li>
                                </Typography>
                                <br />
                            </>
                          )
                          }
                                </>
                        ))}
                        </ol>
                        
                       </Box>
                     </AccordionDetails>
                     </Accordion>
                  ))}
              
                
                </Box>
            </Box>
            </HomePageStyle>
        </ProjectSideBar>
       </>
    )
  // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  list: {
      marginTop: "50px"
  },
  faq: {
      marginBottom: "50px"
  },
  topBartext:{  
    fontSize: 24,
    color: "#1A1A1A",
    userSelect:'none',
    margin:'4%',
    fontFamily:'Montserrat-Bold'
  },
  subtitle:{
    fontWeight: 400,
    fontSize:'14px',
    marginLeft:'4%',
    width:'90%'
  },
  subtitleContainer:{
    justifyContent:'space-evenly',
    display:'flex',
    height: '184px',
    flexDirection:'column'
  },
  titleText1:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
  },
  titleText2:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
    marginLeft:'1%'
  },
  titleTextContainer:{
    display:'flex',
    flexDirection:'row',
    marginLeft:'4%',
    marginTop:'2%', 
    marginBottom:'1%',
    width:'80%'
  },
  textContainer:{
    display:'flex',
    flexDirection:'column',
    marginLeft:'6%',
    paddingLeft:'1%',
    height: "70vh"
  },
  contentText:{
    color:'#333333',
    fontSize:'16px',
    fontFamily:'Montserrat-Regular',
    marginBottom:'1%',
    marginTop:'1%', 
  },
  subcontentText:{
    color:'#4D4D4D',
    fontSize:'16px',
    fontFamily:'Montserrat-SemiBold',
    marginBottom:'1%',
    marginTop:'1%', 
  }
}
const HomePageStyle = styled("div")({
  "& .resetPasscode": {
    marginTop: "110px",
    gap: "20px",
    position: "relative",
    zIndex: 6
  },
  "& .MuiPaper-elevation1": {
    boxShadow:"none"
  }
})
// Customizable Area End




