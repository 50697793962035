// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";

export const configJSON = require("./config");
export  interface Props extends RouteComponentProps {
  id?: string;
}


interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextFiledTypeForConfirm: boolean,
  otp: string | number,
  Sendotp: boolean,
  VerifyCode: Array<string> | string
}

interface SS {
  id: string;
}

export default class VerifyingCodeChangeController extends BlockComponent<Props, S, SS> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      token: "",
      Password: "",
      TextFiledType: false,
      TextFiledTypeForConfirm: false,
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "",
      PhoneInputBorder: false,
      otp: "",
      Sendotp: false,
      VerifyCode: []
    };
  }

  SetVerfiyCode = (e: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({
      VerifyCode: e
    })
    setFieldValue('VerfiyCode', e)
  }
}
// Customizable Area End