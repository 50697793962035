import React from "react";
import { COLORS } from "../../framework/src/Globals";

import { CSSProperties } from "react";

import { styled } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import ProjectText from "./ProjectText";
import { ContainedButton } from "./GlobalButton";
interface CustomModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  workerName?: string;
  confirmText: string;
  confirmed: boolean;
  whatWasDone?: string;
  charRole?: "d" | "r";
}

const ModalCustom: React.FC<CustomModalProps> = ({
  visible,
  onClose,
  onConfirm,
  workerName,
  confirmText,
  confirmed,
  whatWasDone = "reactivated",
  charRole = "d",
}) => {
  return (
    <>
      {visible && (
        <DivStyle>
           <ModelContainer>
            {confirmed === true ? (
              <DialogContainerSecond aria-labelledby="customized-dialog-title">
          
              <div className="dialog-title" id="customized-dialog-title">
                <IconButton  onClick={onClose} aria-label="close" className="CloseIcon">
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ padding: "40 0px" }}>
                <ProjectText
                  variant="subtitle1"
                  style={{ fontWeight: 700, fontSize: "20px", color: "#031417",fontFamily:"Montserrat-bold",textAlign:"center" }}
                >
                     {workerName}`s account has been successfully {whatWasDone} .
                                
                 </ProjectText>
              </div>
            
          
            </DialogContainerSecond>

            ) :(
           <DialogContainer aria-labelledby="customized-dialog-title">
          
             <div className="dialog-title" id="customized-dialog-title">
               <IconButton  onClick={onClose} aria-label="close" className="CloseIcon">
                 <CloseIcon />
               </IconButton>
             </div>
             <div style={{ padding: "40 0px" }}>
               <ProjectText
                 variant="subtitle1"
                 style={{ fontWeight: 700, fontSize: "20px", color: "#031417",fontFamily:"Montserrat-bold",padding:"40px"}}
               >
  
                   Are you sure you want to {charRole}eactivate {workerName}'s account?
                </ProjectText>
             </div>
           
              
             <Divider />
 
             <div
               style={{
                 display: "flex",
                 justifyContent: "end",
                 gap: "16px",
                 padding: "24px",
               }}
             >
               <ContainedButton
                 style={{
                   margin: "0px",
                   borderRadius: "4px",
                   padding: "10px 24px",
                   backgroundColor: "#EDF3F8",
                   color: "black",
                 }}
                 size="medium"
                 onClick={onClose}
               >
                 CANCEL
               </ContainedButton>
 
               <ContainedButton
                 bgcolor="success"
                 style={{
                   margin: "0px",
                   borderRadius: "4px",
                   padding: "10px 24px",
                 }}
                 size="medium"
                 onClick={onConfirm}
               >
                 {confirmText}
               </ContainedButton>
             </div>
          
           </DialogContainer>
            ) }
         </ModelContainer>
         </DivStyle>
      )}
    </>
  );
};
const ModelContainer = styled("div")({
    position: "fixed",
    top: "50%",
    transform: "translate(-50%, -50%",
    '@media (min-width: 1000px)':{
      left: "calc(40% + 220px)",
    },
    '@media (min-width: 360px) and (max-width: 700px)':{
      left: 0,
    },
    '@media (min-width: 700px) and (max-width: 1000px)':{
      left: "calc(20% + 220px)",
    },
    
})
const DialogContainer = styled("div")({
  "&": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 40px",
    width: "600px",
    height: "252px",
    borderRadius: "8px 8px 32px 8px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
      
      '@media (min-width: 360px) and (max-width: 700px)':{
        width: "450px",

      },
      '@media (min-width: 700px) and (max-width: 1000px)':{
        width: "550px",

      },
  },
  "& .CloseIcon": {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});
const DialogContainerSecond = styled("div")({
  "&": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "0px 40px",
    width: "600px",
    height: "180px",
    borderRadius: "8px 8px 32px 8px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
  },
  "& .CloseIcon": {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});
const DivStyle=styled('div')({

   height:"100%",
   width:"100%",
backgroundColor: "rgba(0, 0, 0, 0.5)",
     position: "fixed",
    top: 0,

})
const ButtonConfirm = styled('div') ({

  backgroundColor: "#3FB98C",
  width: 239,
  height: 56,
  borderRadius: 4,
  marginRight: 30,
  justifyContent: "center",
  alignItems: "center",

  alignContent: "center",
  display: "flex",
  cursor: "pointer",
  '@media (max-width: 700px)': {
    marginRight: 10,
    padding: 10,
    width: "auto"
  }
})

const ButtonNo = styled('div') ({
  backgroundColor: "#EDF3F8",
  width: 75,
  height: 56,
  borderRadius: 4,
  marginRight: 40,
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  display: "flex",
  cursor: "pointer",
  '@media (max-width: 700px)': {
    marginRight: 10,
    padding: 10,
    width: "auto"
  }
})
const styles: { [key: string]: CSSProperties } = {
  modalMain: {

    width: "100%",
    height: "100%",

    position: "fixed",
    top: 0,
  

    paddingLeft: "36%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
  
    backgroundColor: "rgba(0, 0, 0, 0.5)",

    height: "100%",

    display: "flex",
    alignItems: "center",
    paddingLeft:"15%"

   
  },
  modal: {
    height: 252,

    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 32,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,

    maxWidth: 600, 

    overflow: "hidden",

    marginRight: 20,
    marginLeft: 20,


  
   
  },
  xClose: {
    cursor: "pointer",
    display: "flex",
    width: 13,
    height: 13,
    userSelect: "none",
  },
  conatinerText: {
    display: "flex",
    height: 100,

    flexDirection: "column",
    alignItems: "flex-start",
    borderBottom: "1px solid #E0E0E0",
  },
  textStyle: {
    color: COLORS.black,
    fontWeight: 700,
    fontSize: 20,
    userSelect: "none",
    marginTop: 5,
    marginLeft: 50,
    textAlign:'left',
    fontFamily:"Montserrat-bold"
  },
  textStyleConfirm: {
    color: COLORS.black,
    fontWeight: 400,
    fontSize: 18,
    userSelect: "none",
    marginRight: 10,
  },
  textStyleConfirmWorker: {
    color: COLORS.black,
    fontWeight: 600,
    fontSize: 18,
    userSelect: "none",
    marginTop: 10,
  },
  textStyleConfirmUser: {
    color: COLORS.black,
    fontWeight: 600,
    fontSize: 18,
    userSelect: "none",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",

    height: 74,
    alignItems: "center",
    marginTop: 15,
  },
  
  buttonConfrimText: {
    color: "#fff",
    fontSize: 16,
    fontWeight: 500,
    marginTop: 7,
    userSelect: "none",
  },
  
  buttonNoText: {
    color: "#4D4D4D",

    fontSize: 16,
    fontWeight: 500,
    marginTop: 7,
    userSelect: "none",
  },
  modalSuccess: {
    width: "100%",
    maxWidth: 600,


    overflow: "hidden",

    height: 252,
    padding: 20,
    backgroundColor: "white",
    borderRadius: 8,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 32,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    position: "relative",
    marginLeft: 720,
    display: "flex",
    justifyContent: "center",
  },
};

export default ModalCustom;
