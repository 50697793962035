// Customizable Area Start
import React from "react";
import LoginPageController from "./LoginPageController.web";
import { Box, Grid, InputLabel, Button, Grow, InputAdornment, IconButton, styled } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { ContainedButton, Outlined } from '../../../components/src/GlobalButton'
import ProjectText from "../../../components/src/ProjectText";
import ProjectTextField from "../../../components/src/ProjectTextField";
import LoginLayout from "../../../components/src/LoginLayout";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import * as Yup from 'yup';

export class LoginPage extends LoginPageController {
    LoginSchema = () => {
        return Yup.object().shape({
            Phone: Yup.string().required("Phone number is required").min(12, "The phone number you have entered is invalid").max(15, "The phone number you have entered is invalid").nullable(),
            password: Yup.string().matches(/^\S+$/, "Please enter the valid password").min(8, "Password Must Be At Least 8 Characters").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Must be 8 characters and contain at least 1 number ,1 uppercase ,1 lowercase and 1 special character").required("Password is required")
        })
    };

    renderPhoneNumberField = (values: { Phone: string }, errors: { Phone?: string }, touched: { Phone?: string | boolean }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Box className="mb-16" style={{ width: "fit-content" }}>
                <StyledBox>
                    {values.Phone !== "" && errors.Phone === undefined &&
                        <div className="CheckIcon">
                            <DoneAllIcon />
                        </div>}
                    <StyledBox className="PhoneInputBox">
                        <PhoneInput
                            country={"in"}
                            placeholder={"Enter Phone Number"}
                            value={this.state.Phone?.toString()}
                            onChange={(number) => {
                                this.SetPhoneNumber(number, setFieldValue)
                            }}
                            inputProps={{
                                required: true,
                                id: "PhoneNumber"
                            }}
                            disableSearchIcon={true}
                            jumpCursorToEnd={true}
                            countryCodeEditable={false}
                            inputClass={this.phoneInputClassHandler(values, touched, errors)}
                        />
                    </StyledBox>
                </StyledBox>
                {(touched.Phone && errors.Phone) &&
                    <Grow
                        in={errors.Phone !== undefined}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(!errors.Phone ? { timeout: 800 } : {})}
                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText"
                        >{errors.Phone}</ProjectText>
                    </Grow>
                }
            </Box>
        );
    }
    renderPasswordField = (errors: { password?: string }, touched: { password?: string | boolean }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Box className="mb-8">
                <StyledBox>
                    <ProjectTextField
                        error={(errors.password !== undefined && touched.password !== undefined) || this.state.PasswordError}
                        id="Password"
                        name="password"
                        type={this.state.TextFiledType ? 'text' : 'password'}
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    id="SetTextFiledType"
                                    onClick={() => {
                                        this.SetTextFiledType()
                                    }}
                                >
                                    {this.state.TextFiledType ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            this.SetPassword(e, setFieldValue)
                        }}
                    />
                </StyledBox>
                {(touched.password && errors.password) &&
                    <Grow
                        in={errors.password !== undefined}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(!errors.password ? { timeout: 800 } : {})}
                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.password}</ProjectText>
                    </Grow>
                }
                {this.state.PasswordError &&
                    <Grow
                        in={this.state.PasswordError}
                        style={{ transformOrigin: '0 0 0' }}
                        {...(this.state.PasswordError ? { timeout: 800 } : {})}
                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>
                    </Grow>
                }
                {!this.state.PasswordError && this.state.PassWordErrorText && <ProjectText type="error" className="errorText" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>}
            </Box>
        );

    }

    render() {
        return (
            <LoginLayout>
                <Formik
                    initialValues={{
                        Phone: "",
                        password: ""
                    }}
                    validationSchema={() => this.LoginSchema()}
                    validateOnChange={true}
                    validateOnBlur={true}
                    onSubmit={(values) => {
                        this.LoginApiFuncation(values)
                    }}
                >
                    {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => (
                        <form onSubmit={handleSubmit} autoComplete="off" className="loginContainer" style={{ width: "100%", height: "100%", }}>
                            <StyledBox style={{ height: "100%" }}>
                                <Grid container spacing={2} style={{ height: "100%" }}>
                                    <Grid item className="loginContent">
                                        <ProjectText variant="h4" className="LoginText" fontfamily="Montserrat-Bold" textcolor="#031417" gutterBottom>
                                            Log in
                                        </ProjectText>
                                        <ProjectText variant="subtitle2" className="DescriptionText" TextSize="14px" fontweight="400" textcolor="#333" gutterBottom>
                                            Please enter your registered phone number and password to login.
                                        </ProjectText>
                                    </Grid>
                                    <Grid item>
                                        <InputLabel htmlFor="PhoneNumber">
                                            <ProjectText variant="subtitle1" className="inputName" fontweight="700" fontfamily="Montserrat-Bold" textcolor="#031417">
                                                Phone number
                                            </ProjectText>
                                        </InputLabel>
                                        {this.renderPhoneNumberField(values, errors, touched, setFieldValue)}
                                    </Grid>
                                    <Grid item className="passwordBox">
                                        <InputLabel htmlFor="Password">
                                            <ProjectText variant="subtitle1" className="inputName" fontweight="700" fontfamily="Montserrat-Bold" textcolor="#031417">
                                                Password
                                            </ProjectText>
                                        </InputLabel>
                                        {this.renderPasswordField(errors, touched, setFieldValue)}
                                        <Box className="mb-16" style={{display:'flex',justifyContent:'right',marginTop:"16px"}}>
                                                <ProjectText fontfamily="Montserrat-SemiBold" fontweight="600" textcolor="#118799" style={{ fontSize: '12px' }}>
                                            <Outlined variant="text" className="fogot-pass-text"  id="ForgotPassword" onClick={this.NavigateToForgotPassword}>
                                                    Forgot Password?
                                            </Outlined>
                                                </ProjectText>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <ContainedButton type="submit" bgcolor="success" fullWidth size="medium" textalign="center" data-testid="loginbutoon" className="loginButton">
                                            login
                                        </ContainedButton>
                                    </Grid>
                                    <Grid item style={{marginBottom: "50px"}}>
                                        <ProjectText variant="subtitle1" className="alreadyTxt" align="center">
                                            Don’t have an account? <Button className="FogotandSignup" onClick={this.NavigateToSignUp}>Sign up</Button>
                                        </ProjectText>
                                    </Grid>
                                </Grid>
                            </StyledBox>
                        </form>
                    )}
                </Formik>
            </LoginLayout>
        )
    }
}

export default withRouter(LoginPage)
const StyledBox = styled(Box)({
    position: "relative",
    "& .loginContent" : {
        "@media (min-width:1281px)": {
            width: '66%',
        },
    },
    "& .passwordBox" : {
        "@media (max-width:1280px)": {
            width: '92%',
        },
    },
    "& .loginContainer": {
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'auto',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'auto',
        },
        '& img': {
            "@media (min-width:360px) and (max-width:449px)": {
                display: "none"
            },
            "@media (min-width:450px) and (max-width:1280px)": {
                display: "none"
            },
        }
    },
    '& .fogot-pass-text': {
        display: "flex", justifyContent: "flex-end", padding: "0px",
        color:"#118799",
        "& .MuiButton-label":{
         
         fontFamily:"Montserrat-semibold ",
         color:"#118799",
         fontWeight:600,
        fontSize:"12px"
        }
      
    },
    "& .MuiGrid-container": {
        justifyContent: "center",
        "@media(min-width:360px) and (max-width:449px)": {
            display: "flex",
            width: "fit-content",
        },

        "@media(min-width:450px) and (max-width:1280px)": {
            width: "fit-content",
        },
    },
    "& .react-tel-input": {
        width: 'inherit',
        "@media (min-width:360px) and (max-width:449px)": {
            width: "inherit"
        },
        "@media(min-width:450px) and (max-width:1280px)": {
            width: "74vw"
        },
    },
    "& .MuiInputBase-fullWidth": {
        width: "26.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: "fit-content"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw"
        },
    },
    "& .form-control PhoneInputPackgeClass": {
        "@media (min-width:360px) and (max-width:449px)": {
            paddingRight: "15vw"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            paddingRight: "15vw",
            width: "74vw"
        },
    },
    "& .FogotandSignup": {
        fontWeight: "700",
        fontSize: "14px"
    },
    "& .alreadyTxt": {
        fontSize: "14px",
        fontWeight: "400"
    },
    "& .passwordBoxContainer": {
        width: "34.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'max-content',
            height: "7vh"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'max-content',
            height: "7vh"
        },
    },
    "& .PhoneInputBox": {
        width: "26.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: '74vw',
        },
    },
    "& .MuiButton-fullWidth": {
        width: '26vw',
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw",
        }
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-31": {
        width: '26vw',
        display: 'flex',
        justifyContent: "flex-end !important",
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw"
        },
    },
    "& .Styled\(MuiBox\)-root-13": {
        fontSize: "14px",
        "@media(min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .MuiTypography-alignCenter": {
        fontSize: "14px",
        width: '26.05vw',
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw",
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .MuiInputLabel-root": {
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'max-content'
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'max-content'
        },
    },
})

// Customizable Area End