// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import { setStorageData, getStorageData } from "../../../framework/src/Utilities";
export interface Props extends RouteComponentProps {
  id?: string;
}


export const configJSON = require("./config");

interface S {
  token: string;
  isLoading: boolean;
  termsCondsId: string;
  Role: string;
  ButtonActive: boolean
}

interface SS {
  id: string;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    setStorageData("Role", "");
    this.state = {
      isLoading: false,
      token: "",
      termsCondsId: "",
      Role: "",
      ButtonActive: false
    };
  }


  SetRole = (role: string) => {
    setStorageData("Role", role)
    this.setState({
      Role: role,
      ButtonActive: true
    })  
    setTimeout(() => {
      this.NavigateToLogin()
    }, 800);
  }

  NavigateToLogin = () => {
    this.props.history.replace({
      pathname: `/LandingPage`
    });
  }
}
// Customizable Area End