import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
import { Message } from "../../framework/src/Message";
export const universalApiCall = async (
  data: any,
  token: any
) => {
  const { contentType, method, endPoint, body } = data;

  const header = {
    "Content-Type": contentType,
    token: token,
  };
 

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
      body
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
};
