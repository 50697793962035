// Customizable Area Start
import React from "react";
import OtpController from "./OtpController.web";
import { Box, Grid } from "@material-ui/core";
import { ContainedButton, Outlined } from '../../../components/src/GlobalButton'
import ProjectText from "../../../components/src/ProjectText";
import LoginLayout from "../../../components/src/LoginLayout";
import 'react-phone-input-2/lib/style.css'
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';

class OtpWeb extends OtpController {
  LoginSchema = () => {
    return Yup.object().shape({
      otp: Yup.string().required("Enter valid Otp").min(6).nullable(),
    })
  };
  render() {
    return (
      <OtpLayoutStyle>
      <LoginLayout BackButton loader={this.state.isLoading} BackButtonOnClick={() => {
        this.props.history.goBack()
      }}>
        <Formik
          initialValues={{
            otp: ""
          }}
          validationSchema={() => this.LoginSchema()}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            this.VerifyCode(values)
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off" className="otpContainer">
              <Grid container spacing={2} className="otpBox">
                <Grid item xs={12}>
                  <ProjectText variant="h4" className="LoginText txtTransf" fontfamily="Montserrat-Bold" textcolor="#031417" gutterBottom>
                    Two-Factor Authentication
                  </ProjectText>
                  <ProjectText variant="subtitle1" className="DescriptionText" TextSize="14px" fontweight="400" textcolor="#333" gutterBottom>
                    A 6-digit verification code was just sent to your Phone via SMS
                  </ProjectText>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ display: "flex"}} className="OtpConatiner">
                    <OtpInput
                      value={this.state.otp.toString()}
                      onChange={(e: string) => {
                        this.SetOtp(e, setFieldValue)
                      }}
                      shouldAutoFocus
                      numInputs={6}
                      inputStyle={`OtpStyle ${this.state.OtpError ? "OtpError" : ""}`}
                      renderSeparator={<span>{'  '}</span>}
                      renderInput={(props) => <input {...props} />}
                    />
                  </Box>
                  <Box className="retryContainer">
                    <ProjectText textcolor={this.state.OtpError ? "red" : "#666666"} TextSize="12px" fontweight="400" variant="h6" >
                      {this.state.OtpErrorText}
                    </ProjectText>
                    <Outlined variant="text" className="txtTransf" size="medium">
                      <ProjectText variant="subtitle1" fontweight="700" fontfamily="Montserrat-semibold" textcolor="#1A1A1A" TextSize="12px" onClick={this.ResendOtpAPiCallFuncation}>
                        Retry
                      </ProjectText>
                    </Outlined>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <ContainedButton type="submit" bgcolor={this.state.otp.length !== 6 ? "outlined" : "success"} disabled={this.state.otp.length !== 6} variant="contained" fullWidth size="medium" className="otpSubmitButton" style={{backgroundColor:this.state.otp.length !== 6 ? "#CCCCCC" : "#3FB98C"}}>
                     <ProjectText fontweight="600" fontfamily="Montserrat-semibold" textcolor="#FFF" TextSize="16px">{this.state.otp.length !== 6 ? "Enter Otp" : "Verify and Proceed"}</ProjectText>
                    </ContainedButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </LoginLayout>
    </OtpLayoutStyle>
    )
  }
}
export default withRouter(OtpWeb)
const OtpLayoutStyle = styled(Box)({
  "& .otpContainer":{
    width:"21.83vw",
     height:"28.56vh",
     top:"386px",
     left:"329px",
     position:"fixed",
    "@media (max-width: 414px)": {
      left:"none",
      width:"75.40vw", 
      height:"23.99vh",
      position:"initial",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      left:"none",
      width:"fit-content", 
      height:"23.99vh",
      position:"initial",
    }, 
  },
  "& .LoginText":{
    "@media (max-width: 414px)": {
      width:"75.40vw", 
      textAlign:"center",
      fontSize:"20px"
    }  
  },
  "& .DescriptionText":{
    width:"22.72vw",
    "@media (max-width: 414px)": {
      width:"75.40vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"75.40vw", 
    },   
  },
  "& .otpBox":{
    width:"21.83vw",
    "@media (max-width: 414px)": {
      width:"75.40vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"fit-content", 
    },   
  },
  "& .retryContainer":{
    display: "flex", 
    justifyContent: "space-between",
    alignItems: "center",
    margin: "10px 0px" ,
    width:"22vw",
    "@media (max-width: 414px)": {
      width:"75.40vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"75.40vw", 
    },  
  },
  "& .otpSubmitButton":{
    width:"21.83vw",
    "@media (max-width: 414px)": {
      width:"75.40vw", 
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width:"75.40vw", 
    }, 
  },
  "& .contentContainer":{
    "@media (min-width:360px) and (max-width: 414px)": {
     alignItems:"flex-start",
      justifyContent:"center",
      marginTop:"15%"
    } ,
    "@media (min-width:450px) and (max-width:1280px)": {
      alignItems: "flex-start",
      marginTop:"25%"
    }, 
  },
  "& .logoContainer": {
    '& img': {
      "@media (max-width: 414px)": {
        display:"none"
      } ,
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  '& .boyImage': {
    '& img': {
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  })
// Customizable Area End