// Customizable Area Start
import React from "react";
import AccountCreateController from "./AccountCreateController.web";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { Box, Grid, InputLabel, Grow, TextField, TextFieldProps } from "@material-ui/core";
import PhoneInput from 'react-phone-input-2'
import { ContainedButton } from '../../../components/src/GlobalButton'
import { Formik } from "formik";
import { Country } from 'country-state-city';
import { withRouter } from "react-router-dom";
import moment from "moment";
import { styled } from "@material-ui/styles";
import * as Yup from 'yup';
import ProjectTextField from "../../../components/src/ProjectTextField";
import ProjectText from "../../../components/src/ProjectText";
import { imgSuccessSMS } from "./assets";
import { MobileNoGrid } from "../../../components/src/AppHeader.web";
export class AccountCreate extends AccountCreateController {
  async componentDidMount(){
     this.getToken();
  }
  NewPasswordSchema = () => {
    return Yup.object().shape({
      Fullname: Yup.string()
      .matches(/^[A-Za-z ]+$/, "Enter a valid full name")
      .max(30, 'Full name is too long, maximum length 30 characters')
      .required("Full name is required"),
      Nationality: Yup.string().required("Nationality is required")
      .notOneOf(["Nationality"], "Please select a valid nationality"),
      DateofBirth: Yup.string()
      .required("Date of birth is required")
      .test("DOB", "You must be between 18 and 120 years old", (value) => {
        const minAge = 18;
        const maxAge = 120;
        const age = moment().diff(moment(value), "years");
        return age >= minAge && age <= maxAge;
      })
      .nullable(),
      UserID: Yup.string()
      .matches(/^\d+$/, "Enter a valid User ID (numbers only)")
      .required("User ID is required"),
      MobileNo: Yup.string().required("Phone number is required").min(12, "The phone number you have entered is invalid").max(15, "The phone number you have entered is invalid").nullable(),
    })
  };

  FullNameGrid = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { Fullname?: string }, touched: { Fullname?: string | boolean }, values: { Fullname: string }) => {
    return (
      <Grid item xs={12}>
        <InputLabel
          htmlFor="Fullname">
          <ProjectText variant="subtitle1" style={{
            fontWeight: 600, color: "black"
          }}>
            Full name
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }}>
          <ProjectTextField
            type='text'
            fontweight={400}
            fullWidth
            value={values.Fullname}
            placeholder="Your full name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.SetFullName(e, setFieldValue)
            }}
            error={errors.Fullname !== undefined && touched.Fullname !== undefined}
            id="Fullname"
            name="Fullname"
            inputProps={{
              "data-test-id": "Fullname"
            }}
          />
          {(touched.Fullname && errors.Fullname) &&
            <Grow
              in={errors.Fullname !== undefined}
              style={{ transformOrigin: '0 0 0' }}
              {...(!errors.Fullname ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.Fullname}</ProjectText>
            </Grow>
          }
        </Box>
      </Grid>)
  }

  DateofBirthGrid = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { DateofBirth?: string }, touched: { DateofBirth?: string | boolean }, values: { DateofBirth: string }) => {
    return (
      <Grid item xs={6}>
        <InputLabel
          htmlFor="Password">
          <ProjectText variant="subtitle1" style={{
            fontWeight: 600, color: "black"
          }}>
            Date of Birth
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }}>
          <ProjectTextField
            type='date'
            textColor={values.DateofBirth ? "#000" : "#666"}
            fontweight={400}
            fullWidth
            error={errors.DateofBirth !== undefined && touched.DateofBirth !== undefined}
            inputProps={{
              "data-test-id": "DateofBirth", max: moment().format("DD-MM-YYYY")
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.SetDateofBirth(e, setFieldValue)
            }}
            value={values.DateofBirth}
            id="Password"
            name="password"
          />
          {(touched.DateofBirth && errors.DateofBirth) &&
            <Grow
              in={errors.DateofBirth !== undefined}
              style={{ transformOrigin: '0 0 0' }}
              {...(!errors.DateofBirth ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.DateofBirth}</ProjectText>
            </Grow>
          }
        </Box>
      </Grid>
    )
  }

  UserIDGrid = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { UserID?: string }, touched: { UserID?: string | boolean }, values: { UserID: string }) => {
    return (
      <Grid item xs={12}>
        <InputLabel htmlFor="UserID">
          <ProjectText variant="subtitle1" style={{ fontWeight: 600, color: "black" }}>
            User ID
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative", display: "flex", alignItems: "center" }}>
          <Box style={{ width: "100%" }}>
            <ProjectTextField
              type='text'
              fontweight={400}
              fullWidth
              placeholder="Enter ID"
              inputProps={{ maxLength: 12, "data-test-id": "UserID" }}
              error={errors.UserID !== undefined && touched.UserID !== undefined}
              id="UserID"
              name="UserID"
              value={values.UserID}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.SetUserId(e, setFieldValue)}
            />
          </Box>
        </Box>
        {(touched.UserID && errors.UserID) &&
          <Grow
            in={errors.UserID !== undefined}
            style={{ transformOrigin: '0 0 0' }}
            {...(!errors.UserID ? { timeout: 800 } : {})}
          >
            <ProjectText type="error">{errors.UserID}</ProjectText>
          </Grow>
        }
      </Grid>
    )
  }



  render() {
    return (
      <ProjectSideBar pageThisVar={this}>
       {
        this.state.smsSuccess===true?
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",marginTop:120,alignItems:"center"}}>
          <img src={imgSuccessSMS} alt="Success" style={{width:560,height:470}}/>
        </div> : 
        <CreateProfileStyle>
        <Formik
          validationSchema={() => this.NewPasswordSchema()}
          initialValues={{
            Fullname: "",
            Nationality: "" || "Nationality",
            DateofBirth: "",
            UserID: "",
            MobileNo: "",
          }}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => 
            this.ConfirmPasscode(values)
          }
        >
          {({ errors, touched, setFieldValue, handleSubmit, values, isValid }) => (
            <form className="CreateForm" data-test-id="AccountCreate" onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2} >
                {this.FullNameGrid(setFieldValue, errors, touched, values)}
                <Grid item xs={6}>
                  <InputLabel htmlFor="Nationality">
                    <ProjectText variant="subtitle1" style={{ fontWeight: 600, color: "black" }}>
                      Nationality
                    </ProjectText>
                  </InputLabel>
                  <Box style={{ position: "relative" }}>
                    <CustomTextField
                      id="Nationality"
                      error={errors.Nationality !== undefined && touched.Nationality !== undefined}
                      value={values.Nationality === "" ? "Nationality" : values.Nationality}
                      select
                      inputProps={{
                        "data-test-id": "Nationality"
                      }}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                      fullWidth
                      defaultValue={false}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.SetNationality(e, setFieldValue)}
                    >
                       <option value="">Nationality</option>
                      {Country.getAllCountries().map((option: { name: string }) => (
                        <option key={option.name} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </CustomTextField>
                    {(touched.Nationality && errors.Nationality) &&
                      <Grow
                        {...(!errors.Nationality ? { timeout: 800 } : {})}
                        in={errors.Nationality !== undefined}
                        style={{ transformOrigin: '0 0 0' }}
                      ><ProjectText type="error">{errors.Nationality}</ProjectText></Grow>
                    }
                  </Box>
                </Grid>
                {this.DateofBirthGrid(setFieldValue, errors, touched, values)}
                {this.UserIDGrid(setFieldValue, errors, touched, values)}
                {
                  MobileNoGrid(setFieldValue, errors, touched, values,this.SetPhoneNumber,this.state.phonenoError)
                }
                <Grid item xs={12}>
                  <Box>
                    <ContainedButton type="submit" bgcolor="success" fullWidth size="medium" data-test-id="SubmitBtn">
                      SUBMIT
                    </ContainedButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </CreateProfileStyle>
       }
      </ProjectSideBar>
    )
  }
}

export default withRouter(AccountCreate)

const CreateProfileStyle = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  '& .CreateForm': {
    width: "60%",
    height: "100%",
    marginTop: "10%",
    '@media (max-width: 1000px)': {
      width: "70%"
    },
    '@media (max-width: 700px)': {
      width: "90%"
    }
  },
  "& .PhoneInputPackgeClass:focus":{
    border: "1px solid #3952b5",
    boxShadow: "1px 1px 0px rgb(78,98,182) inset, -1px -1px 0px rgb(78,98,182) inset"
  },
  "& .PhoneInputPackgeClass:hover":{
    borderColor: "#000"
  },
  "& .PhoneInputPackgeClass:focus:hover":{
    borderColor: "#3952b5",
  },
  "& .PhoneInputPackgeClassErr":{
    borderColor: "red"
  },
  "& .PhoneInputPackgeClassErr:focus":{
    borderColor: "red",
    boxShadow: "1px 1px 0px red inset, -1px -1px 0px red inset"
  },
  "& .PhoneInputPackgeClassErr:hover":{
    borderColor: "red"
  },
  "& .PhoneInputPackgeClassErr:focus:hover":{
    borderColor: "red"
  }

  
})

const CustomTextField = styled(TextField)((props: TextFieldProps) => ({
  borderColor: "#B6CEE2",
  borderRadius: "8px",
  ...(props.error && {
    borderColor: "red"
  }),
  '& .Mui-focused': {
    outline: "none"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "#B6CEE2",
    height: "59px"
  },
  "& .MuiOutlinedInput-root": {
    position: "relative",
    borderRadius: "8px",
}
}))

// Customizable Area End