import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { universalApiCall } from "../../../components/src/universalAPICallAndMore";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pendingAccountList:any,
  token:any,
  isModalVisible: boolean,
  selectedWorkerName:string,
  selectedEmployeeId:any,
  showDetails:boolean,
  phonenoError:string,
  userId:number
  initialState:{
      Fullname:string,
      Nationality: string,
      DateofBirth: string
      UserID:string
      MobileNo:string
  }
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountPendingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPendingAccountsList:string = "";
  postUpdateTemporaryAccountApiCall: string = "";
  _isMounted = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pendingAccountList:null,
      token:"",
      isModalVisible: false,
      selectedWorkerName: "",
      selectedEmployeeId:null,
      showDetails:false,
      phonenoError:"",
      userId:0,
      initialState:{
        Fullname: "",
        Nationality: "" || "Nationality",
        DateofBirth: "",
        UserID: "",
        MobileNo: "",
      }
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    
    // Customizable Area Start
    if(getName(MessageEnum.SessionResponseMessage) === message.id){
      let token=message.getData(getName(MessageEnum.SessionResponseToken));

      if (this._isMounted) {
      this.setState({token:token},async ()=>{         
        if (this.state.token!=="") {
              
            await this.getPendingAccount();
            
            }
      });
    }
    
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    await this.handleDifferentAPISessions(apiRequestCallId,responseJson);  
  
    // Customizable Area End
  }


  // Customizable Area Start

  SetPhoneNumber = (value: string, data: any, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    this.setState({phonenoError:''})
    setFieldValue("MobileNo", value);
  }
  
  SetFullName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Fullname", e.target.value);
  }


  SetUserId = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("UserID", e.target.value);
  }

  SetNationality = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Nationality", e.target.value);
  }

  SetDateofBirth = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("DateofBirth", e.target.value);
  }
 


  editPendingAccountData = async (values: {
    Fullname: string,
    Nationality: string,
    DateofBirth: string,
    UserID: string,
    MobileNo:string,
  }) => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.token,
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
   

    this.postUpdateTemporaryAccountApiCall = requestMessage.messageId;

    let Body = {  
      "account": {
        "full_name": values.Fullname,
        "nationality": values.Nationality,
        "dob": moment(values.DateofBirth).format("YYYY/MM/DD"),
        "user_name": "",
        "user_id": values.UserID,
        "mobile_number":values.MobileNo
    }
      
    }
   
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `account_block/accounts/update_temporary_account?id=${this.state.userId}`
    
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  async toggleModal (workerName:string,employeeId:any) {
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
      selectedWorkerName: workerName,
      selectedEmployeeId: employeeId,
 
    }));
 
  }
 async componentDidMount() {
    this._isMounted = true;
  
 
  }


 async componentWillUnmount() {
    this._isMounted = false;
  }
  async getPendingAccount(){
    this.getPendingAccountsList = await universalApiCall({
      method: "GET",
      endPoint: "account_block/accounts/get_temporary_account",
  
    },this.state.token);
  }
  getToken = () => {
    this._isMounted=true;
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  handleShowDetails = (item:any)=>{
   this.setState({initialState :{ Fullname:item.full_name,
   Nationality: item.nationality,
   DateofBirth: moment(item.dob).format("YYYY-MM-DD"),
   UserID: item.user_id,
   MobileNo: item.mobile_number},userId:item.id,showDetails:true})
  }
  handleBackButton = ()=>{
    this.setState({showDetails:false})
  }
  handleResponseErrors = async(responseJson:any, setStateCallback:any) => {
    if (responseJson && responseJson.errors) {
      if (typeof responseJson.errors === 'string') {
        setStateCallback({ phonenoError: responseJson.errors, isLoading: false });
      } else if (Array.isArray(responseJson.errors) && responseJson.errors.length > 0) {
        const errorData = responseJson.errors[0];
        const errorMessage = errorData.mobile_number || 'Unknown error';
        setStateCallback({ phonenoError: errorMessage, isLoading: false });
      } 
      else {
        await this.getPendingAccount();
        setStateCallback({ showDetails:false });
      }
    }
     else {
      await this.getPendingAccount();
      setStateCallback({ showDetails:false });
    }
  };
  
  async handleDifferentAPISessions(apiRequestCallId: string, responseJson: any){
    if (apiRequestCallId === this.getPendingAccountsList) {
      this.setState({ pendingAccountList: responseJson });
    }else if(apiRequestCallId === this.postUpdateTemporaryAccountApiCall ){
        this.handleResponseErrors(responseJson, this.setState.bind(this));
    }
  }
  formatMembersCount(membersArray: any[]): string {
    const count = membersArray.length;
   
    if (count === 0) {
      return "0 MEMBERS";
    } else if (count === 1) {
      return "1 MEMBER";
    } else {
      return `${count} MEMBERS`;
    }
  }
  // Customizable Area End
}
