
// Customizable Area Start
import React from 'react'

export const HelpcenterIcon = () => {
  return (
    <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.5 0 0 3.6 0 8C0 10.2 1.1 12.2 2.8 13.5C2.8 14.1 2.4 15.7 0 18C2.4 17.9 4.6 17 6.5 15.5C7.6 15.8 8.8 16 10 16C15.5 16 20 12.4 20 8C20 3.6 15.5 0 10 0ZM10 14C5.6 14 2 11.3 2 8C2 4.7 5.6 2 10 2C14.4 2 18 4.7 18 8C18 11.3 14.4 14 10 14ZM10.2 3.5C9.3 3.5 8.6 3.7 8.1 4C7.5 4.4 7.2 5 7.3 5.7H9.3C9.3 5.4 9.4 5.2 9.6 5.1C9.8 5 10 4.9 10.3 4.9C10.6 4.9 10.9 5 11.1 5.2C11.3 5.4 11.4 5.6 11.4 5.9C11.4 6.2 11.3 6.4 11.2 6.6C11 6.8 10.8 7 10.6 7.1C10.1 7.4 9.7 7.7 9.5 7.9C9.1 8.2 9 8.5 9 9H11C11 8.7 11.1 8.5 11.1 8.3C11.2 8.1 11.4 8 11.6 7.8C12.1 7.6 12.4 7.3 12.7 6.9C13 6.5 13.1 6.1 13.1 5.7C13.1 5 12.8 4.4 12.3 4C11.9 3.7 11.1 3.5 10.2 3.5ZM9 10V12H11V10H9Z" fill="#999999" />
    </svg>
  )
}

// Customizable Area End
