// Customizable Area Start
import * as React from "react";
const FristSqure = require("./LoginTop.png");
const SecondSqure = require("./LoginBottom.png");
const AlphaLogo = require("./AppLogo.png");
const rightSideWithPhone = require("./rightSideWithPhone.png");
import { Box, Button, CircularProgress } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const drawerWidth = 240;

interface PermanentDrawerLeftProps {
  children: React.ReactNode;
  BackButton?: boolean;
  BackButtonOnClick?: () => void;
  loader?: boolean;
  SecondSide?: boolean
}

export default function LoginLayout({
  children,
  BackButton,
  BackButtonOnClick,
  loader = false,
  SecondSide = true
}: PermanentDrawerLeftProps) {
  return (
    <LoginPageStyle>
      <Box className="MainContainer">
        <div className="firstSqureDiv">
          <img src={FristSqure} alt="FristSqure" />
        </div>
        <Box className="LoginFormContainer">
          <Box className="logoContainer">
            {BackButton && <Button onClick={BackButtonOnClick} className="BackButton" startIcon={<ArrowBackIcon />}>Back</Button>}
            <img src={AlphaLogo} />
          </Box>
          <Box className="contentContainer">
            <Box className="ChilderContainer">
              {loader === true ?
                <Box className="LoaderContainer">
                  <CircularProgress className="Loader" />
                </Box>
                :
                children
              }
            </Box>
            {SecondSide &&
              <Box className="boyImage">
                <img src={rightSideWithPhone} />
              </Box>
            }
          </Box>
        </Box>
        <div className="SceondSqureDiv">
          <img src={SecondSqure} alt="FristSqure" />
        </div>
      </Box>
    </LoginPageStyle>
  );
}
const LoginPageStyle = styled(Box)({
  height: "100%",
  width: "100%",
  position: "relative",
  '& .MainContainer': {
    display: "flex", justifyContent: "center", height: "100%", width: "100%", flexDirection: "column"
  },
  "& .LoginFormContainer": {
    width: "100%",
    height: "80%",

  },
  '& .LoaderContainer': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    "& .Loader": {
      color: "#3FB98C"
    }
  },
  '& .ChilderContainer': {
    display: "flex", alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "90%",
    "@media(max-width:1280px)": {
      width: "100%",
      height: "100%",
    }
  },
  "& .contentContainer": {
    display: "flex",
    alignItems: "center",
    width: '80%',
    height: "85%",
    margin: "auto",
    "@media(min-width:360px) and (max-width:449px)": {
      left: "0px",
      position: "inherit",
      alignItems: "flex-start",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      left: "0px",
      position: "inherit",
      alignItems: "flex-start",
      height: "100%",
    },
  },
  "& .firstSqureDiv": {
    position: "fixed",
    top: '0',
    right: "0",
    width: "262px",
    height: "10%",
    '& img': {
      width: "100%",
      height: "100%",
      objectPosition: "center",
    },
  },
  "& .SceondSqureDiv": {
    position: "fixed",
    bottom: '0',
    left: "0",
    width: "262px",
    height: "10%",
    '& img': {
      objectPosition: "center",
      width: "100%",
      height: "100%",
      verticalAlign: "middle",
    },
  },
  '& .boyImage': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
    height: "100%",
    "@media (max-width: 1450px)": {
      margin: "unset"
    },
    '& img': {
      height: "100%",
      width: "100%",
    },
    "@media (max-width: 1280px)": {
      display: "none",
    },
  },
  "& .logoContainer": {
    display: "flex",
    justifyContent: "center",
    height: "15%",
    marginBottom: "20px",
    '& img': {
      objectFit: "cover",
      height: "100%",
    },
    "@media  (max-width:1280px)": {
      display: "none"
    },
    "& .BackButton": {
      position: "absolute",
      top: "24px",
      left: "36px",
      color: "black",
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "unset"
    }
  },
  '& .OtpConatiner': {
    '& div': {
      display: 'flex',
      width: '100%',
      justifyContent: "space-between",
      "@media (min-width:360px) and (max-width:449px)": {
        justifyContent: "space-between",
        gap: "none"
      },
      "@media (min-width:450px) and (max-width:1280px)": {
        justifyContent: "space-between",
        gap: "none"
      },
    },
    "@media (min-width:360px) and (max-width:449px)": {
      width: "75.40vw",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "75.40vw",
    },
  },
  "& .OtpStyle": {
    width: "2.14vw !important",
    height: "50px",
    borderRadius: '7px',
    border: '1px',
    borderColor: "#B6CEE2",
    borderStyle: "solid",
    background: '#fff',
    fontSize: '20px',
    outline: "none",
    fontWeight: 700,
    "@media (min-width:360px) and (max-width:449px)": {
      width: "9.92vw !important",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "9.92vw !important",
    },
  },
  '& .OtpError': {
    borderColor: "red"
  },
  '& .ErrorBoxContainer': {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    padding: "0px",
    "& button": {
      position: "absolute",
      top: "70px",
      fontFamily: "Montserrat-bold",
      textTransform: "unset",
      fontWeight: "700"
    }
  },
  "& .PasscodeSet": {
    width: "10px !important",
    height: "10px",
    borderRadius: '5px',
    border: '1px',
    borderColor: "#B6CEE2",
    backgroundColor: "#DBE7F0",
    borderStyle: "solid",
    margin: "0px 15px",
    background: '#fff',
    fontSize: '20px',
    outline: "none"
  },
  "& .FormContainer": {
    display: "flex",
    justifyContent: "space-between",
    "& .FormTextFileContainer": {
      minWidth: "400px",
      backgroundColor: "lightgrey"
    }
  },
  "& .LoginText": {
    textTransform: "uppercase",
    fontSize: "24px",
    marginBottom: "16px",
    "@media (min-width:360px) and (max-width:449px)": {
      textAlign: "start",
      fontSize: "20px",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      textAlign: "start"
    }
  },
  "& .txtTransf": { textTransform: "unset" },
  '& .DescriptionText': {
    marginBottom: "24px",
    width: '27vw',
    "@media (min-width:360px) and (max-width:449px)": {
      width: "73.90vw",
      textAlign: "start"
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "74vw",
      textAlign: "start"
    }
  },
  "& .mb-16": {
    marginBottom: "16px"
  },
  "& .mb-8": {
    marginBottom: "8px"
  },
  "& .mt-10": {
    marginTop: "10px"
  },
  "& .inputName": {
    marginBottom: "8px",
    lineHeight: "24px"
  },
  '& .SecondContainer': {
  },
  '& .CheckIcon': {
    color: "#5DBB65",
    height: '40px',
    right: '10px',
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
    top: "50%",
    transform: "translateY(-50%)",
    alignItems: 'center'
  },
  "& .ColorSuccess": {
    color: "#5DBB65",
    borderColor: "#5DBB65",
  },
  '& .FogotandSignup': {
    fontSize: "16px",
    color: "#118799",
    fontFamily: "Montserrat-Bold",
    fontWeight: 700,
    textTransform: "unset",
    cursor: "pointer",
    lineHeight: "normal",
    "@media (max-width: 800px)": {
      fontSize: "14px",
    }
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",
    height: "56px",
    borderRadius: "8px",
    fontSize: "14px",
    paddingLeft: "35px",
    fontWeight: 700,
    borderColor: "rgb(182, 206, 226)"
  },
  "& .PhoneInputPackgeClass:focus": {
    border: "1px solid #3952b5",
    boxShadow: "1px 1px 0px rgb(78,98,182) inset, -1px -1px 0px rgb(78,98,182) inset"
  },
  "& .PhoneInputPackgeClass:hover": {
    borderColor: "#000"
  },
  "& .PhoneInputPackgeClass:focus:hover": {
    borderColor: "#3952b5",
  },
  "& .PhoneInputPackgeClassErr": {
    borderColor: "red"
  },
  "& .PhoneInputPackgeClassErr:focus": {
    borderColor: "red",
    boxShadow: "1px 1px 0px red inset, -1px -1px 0px red inset"
  },
  "& .PhoneInputPackgeClassErr:hover": {
    borderColor: "red"
  },
  "& .PhoneInputPackgeClassErr:focus:hover": {
    borderColor: "red"
  },
  '& .flag-dropdown ': {
    cursor: "unset",
    border: 'none',
    background: 'transparent',
  },
  "& .selected-flag": {
    "&:hover": {
      backgroundColor: "unset"
    },
    '&:focus': {
      backgroundColor: "unset"
    }
  },
  "& .forgotPasswordText": {
    textTransform: "unset",
  },
  "& .forgotPasswordTextTitle": {
    textTransform: "uppercase",
    fontSize: "24px",
    marginBottom: "16px",
    "@media(min-width:360px) and (max-width:449px)": {
      textAlign: "center",
      fontSize: "20px",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      textAlign: "center"
    }
  },
  "& .alreadyTxt": {
    color: "#1A1A1A",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .errorText": {
    marginTop: "8px",
    lineHeight: "18px"
  }
})
// Customizable Area End