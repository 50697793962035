import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Grow, Box, Grid, InputLabel } from "@material-ui/core";
import { ContainedButton} from '../../../components/src/GlobalButton';
import LoginLayout from "../../../components/src/LoginLayout";
import { Formik } from "formik";
import ForgotPasscodeController, {
    Props,
    configJSON,
  }  from "./ForgotPasscodeController.web";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PhoneInput from 'react-phone-input-2'
import ProjectText from "../../../components/src/ProjectText";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {},
});
// Customizable Area End


export default class PasscodeLock extends ForgotPasscodeController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <LoginLayout BackButton BackButtonOnClick={() => {
          this.props.navigation.goBack()
        }}>
         <Formik
                  initialValues={{
                    phone: "",
                  }}
                  validationSchema={() => this.SignUpSchema()}
                  validateOnBlur={true}
                  onSubmit={(values) => {
                    this.apiCall(values.phone)
                  }}
                  isInitialValid
                  validateOnChange={true}
                  data-test-id='formikId'
                >
                  {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ProjectText variant="h4" className="LoginText" fontfamily="Montserrat-Bold" textcolor="#031417" gutterBottom>
                            {configJSON.passcodeResetTxt}
                          </ProjectText>
                          <ProjectText
                            variant="h6"
                            className="DescriptionText"
                            TextSize="14px" fontweight="400" textcolor="#333"
                            gutterBottom
                          >
                            {configJSON.validatePhoneTxt}
                          </ProjectText>
                        </Grid>
                        <Grid item xs={12} className="mb-16">
                          <InputLabel htmlFor="PhoneNumber">
                            <ProjectText
                              variant="subtitle1"
                              fontweight="700" fontfamily="Montserrat-Bold"  textcolor="#031417"
                              className="inputName"
                            >
                              {configJSON.phoneNumberTxt}
                            </ProjectText>
                          </InputLabel>
                          <Box style={{ position: "relative" }}>
                            {errors.phone === undefined && values.phone !== "" &&
                              <div className="CheckIcon">
                                <DoneAllIcon />
                              </div>
                            }
                            <PhoneInput
                              country={"sa"}
                              showDropdown={false}
                              data-test-id="phone_number"
                              placeholder={"Enter phone Number"}
                              value={this.state.phone?.toString()}
                              onChange={(number: string) => {
                                this.setPhoneNumber(number, setFieldValue);
                              }}
                              inputProps={{
                                required: true,
                                id: "PhoneNumber",
                              }}
                              enableSearch={true}
          
                              disableSearchIcon={true}
                              jumpCursorToEnd={true}
                              countryCodeEditable={false}
                             inputClass={`PhoneInputPackgeClass ${(!values.phone && !touched.phone) ? "" : (errors.phone && touched.phone) ? "PhoneInputPackgeClassErr" : !errors.phone && "ColorSuccess"}`}
                            />
                          </Box>
                            {(touched.phone && errors.phone) && (
                              <Grow
                                in={errors.phone !== undefined}
                                style={{ transformOrigin: "0 0 0" }}
                                {...(!errors.phone ? { timeout: 800 } : {})}
                              >
                                <ProjectText
                                  type="error"
                                  className="errorText"
                                  fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                                >
                                  {errors.phone}
                                </ProjectText>
                              </Grow>
                            )}
                          {
                             !errors.phone && this.state.isLinkSent.message && this.state.isLinkSent.status && <ProjectText
                              type="success"
                              className="errorText"
                              fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                            >
                              {this.state.isLinkSent.message}
                            </ProjectText>
                            }
                             {
                             !errors.phone && this.state.isLinkSent.message && !this.state.isLinkSent.status && <ProjectText
                              type="error"
                              className="errorText"
                              fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                            >
                              {this.state.isLinkSent.message}
                            </ProjectText>
                            }
                        </Grid>
                        <Grid item xs={12}>
                          <Box className="mt-10">
                            <ContainedButton
                              type="submit"
                              bgcolor="success"
                              fullWidth
                              size="medium"
                              data-testid="loginbutoon"
                            >
                             {configJSON.proceedTxt}
                            </ContainedButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Formik>

        </LoginLayout>

      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End
