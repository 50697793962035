// Customizable Area Start
import React from "react";
import CreateProfileController from "./CreateProfileController.web";
import {
  Box,
  Grid,
  InputLabel,
  Grow,
  TextField,
  TextFieldProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  IconButton,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import PhoneInput from "react-phone-input-2";
import { ContainedButton } from "../../../components/src/GlobalButton";
import ProjectText from "../../../components/src/ProjectText";
import ProjectTextField from "../../../components/src/ProjectTextField";
import LoginLayout from "../../../components/src/LoginLayout";
import "react-phone-input-2/lib/style.css";
import { Formik } from "formik";
import { Country } from "country-state-city";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { styled } from "@material-ui/styles";
import * as Yup from "yup";
import CloseIcon from "@material-ui/icons/Close";
import { configJSON } from "./Customisableuserprofiles2Controller";

export class CreateProfile extends CreateProfileController {
  NewPasswordSchema = () => {
    return Yup.object().shape({
      Fullname: Yup.string()
        .matches(/^[A-Za-z ]+$/, "Enter a valid full name")
        .required("Full name is required"),
      DateofBirth: Yup.string()
        .required("Date of birth is required")
        .test("DateofBirth", "Date must be above 18+ year", (value) => {
          return moment().diff(moment(value), "years") >= 18;
        })
        .nullable(),
      Username: Yup.string()
        .matches(/^[A-Za-z]+$/, "Enter a valid username")
        .required("Username is required"),
      UserID: Yup.string()
        .matches(/^\d+$/, "Enter a valid User ID (numbers only)")
        .required("User ID is required"),
      Nationality: Yup.string().required("Nationality is required"),
      termsAndConditions: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      ),
    });
  };

  FullNameGrid = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    errors: { Fullname?: string },
    touched: { Fullname?: string | boolean },
    values: { Fullname: string }
  ) => {
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor="Fullname">
          <ProjectText
            variant="subtitle1"
            fontfamily="Montserrat-Bold"
            TextSize="16px"
            fontweight="700"
            textcolor="#031417"
            className="inputName"
          >
            Full name
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }} className="smallInput">
          <ProjectTextField
            type="text"
            error={
              errors.Fullname !== undefined && touched.Fullname !== undefined
            }
            id="Fullname"
            inputProps={{
              "data-testid": "Fullname",
            }}
            value={values.Fullname}
            fullWidth
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.SetFullName(e, setFieldValue);
            }}
            name="Fullname"
            placeholder="Enter Name"
            disabled={this.state.isEmployee ? true :false}
          />
          {touched.Fullname && errors.Fullname && (
            <Grow
              in={errors.Fullname !== undefined}
              style={{ transformOrigin: "0 0 0" }}
              {...(!errors.Fullname ? { timeout: 800 } : {})}
            >
              <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.Fullname}</ProjectText>
            </Grow>
          )}
        </Box>
      </Grid>
    );
  };

  DateofBirthGrid = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    errors: { DateofBirth?: string },
    touched: { DateofBirth?: string | boolean },
    values: { DateofBirth: string }
  ) => {
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor="DateofBirth">
          <ProjectText
            variant="subtitle1"
            fontfamily="Montserrat-Bold"
            TextSize="16px"
            fontweight="700"
            textcolor="#031417"
            className="inputName"
          >
            Date of Birth
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }} className="smallInput">
          <ProjectTextField
          textColor={values.DateofBirth ? "#000" : "#949895"}
            type="date"
            fullWidth
            error={
              errors.DateofBirth !== undefined &&
              touched.DateofBirth !== undefined
            }
            id="DateofBirthD"
            name="DateofBirth"
            inputProps={{
              maxLength: 12,
              "data-test-id": "DateofBirth",
              max: moment().format("DD-MM-YYYY"),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              this.SetDateofBirth(e, setFieldValue);
            }}
            placeholder="DOB"
            value={values.DateofBirth}
            disabled={this.state.isEmployee ? true :false}
          />
          {touched.DateofBirth && errors.DateofBirth && (
            <Grow
              in={errors.DateofBirth !== undefined}
              style={{ transformOrigin: "0 0 0" }}
              {...(!errors.DateofBirth ? { timeout: 800 } : {})}
            >
              <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.DateofBirth}</ProjectText>
            </Grow>
          )}
        </Box>
      </Grid>
    );
  };

  UserNameGrid = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    errors: { Username?: string },
    touched: { Username?: string | boolean },
    values: { Username: string }
  ) => {
    return (
      <Grid item xs={6}>
        <InputLabel htmlFor="Username">
          <ProjectText
            variant="subtitle1"
            fontfamily="Montserrat-Bold"
            TextSize="16px"
            fontweight="700"
            textcolor="#031417"
            className="inputName"
          >
            Username
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }} className="smallInput">
          <ProjectTextField
            type="text"
            fullWidth
            inputProps={{ "data-testid": "Username" }}
            error={
              errors.Username !== undefined && touched.Username !== undefined
            }
            id="Username"
            name="Username"
            placeholder="Enter Username"
            value={values.Username}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              this.SetUserName(e, setFieldValue)
            }
          />
          {touched.Username && errors.Username && (
            <Grow
              in={errors.Username !== undefined}
              style={{ transformOrigin: "0 0 0" }}
              {...(!errors.Username ? { timeout: 800 } : {})}
            >
              <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.Username}</ProjectText>
            </Grow>
          )}
        </Box>
      </Grid>
    );
  };

  UserIdGrid = (
    setFieldValue: (
      field: string,
      value: string,
      shouldValidate?: boolean
    ) => void,
    errors: { UserID?: string },
    touched: { UserID?: string | boolean },
    values: { UserID: string }
  ) => {
    return (
      <Grid item xs={12}>
        <InputLabel htmlFor="UserID">
          <ProjectText
            variant="subtitle1"
            fontfamily="Montserrat-Bold"
            TextSize="16px"
            fontweight="700"
            textcolor="#031417"
            className="inputName"
          >
            User ID
          </ProjectText>
        </InputLabel>
        <Box
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box style={{ width: "100%" }} className="longInputBox">
            <ProjectTextField
              type="text"
              fullWidth
              placeholder="Enter ID"
              inputProps={{ "data-test-id": "UserID" }}
              value={values.UserID}
              error={
                errors.UserID !== undefined && touched.UserID !== undefined
              }
              id="UserID"
              name="UserID"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                this.SetUserId(e, setFieldValue)
              }
              disabled={this.state.isEmployee ? true :false}
            />
          </Box>
        </Box>
        {touched.UserID && errors.UserID && (
          <Grow
            in={errors.UserID !== undefined}
            style={{ transformOrigin: "0 0 0" }}
            {...(!errors.UserID ? { timeout: 800 } : {})}
          >
            <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.UserID}</ProjectText>
          </Grow>
        )}
      </Grid>
    );
  };

  MobileNoGrid = (
  ) => {
    return (
      <Grid item xs={12}>
        <InputLabel htmlFor="PhoneNumber">
          <ProjectText
            variant="subtitle1"
            fontfamily="Montserrat-Bold"
            TextSize="16px"
            fontweight="700"
            textcolor="#031417"
            className="inputName"
          >
            Mobile No.
          </ProjectText>
        </InputLabel>
        <Box style={{ position: "relative" }} className="longInputBox">
          <PhoneInput
            country={this.state.CountryCode}
            placeholder={"Enter Mobile No."}
            value={this.getPhoneFromLocalStorage()}
            inputProps={{
              id: "PhoneNumber",
            }}
            disabled
            disableSearchIcon={true}
            jumpCursorToEnd={true}
            countryCodeEditable={false}
            inputClass={`PhoneInputPackgeClass`}
          />
          {
            this.state.apiError && <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{configJSON.numberRegisteredTxt}</ProjectText>
          }
        </Box>
      </Grid>
    );
  };

  render() {
    return (
      <CreateProfileLayout data-testid="layout">
      <LoginLayout
        BackButton
        loader={this.state.isLoading}
        BackButtonOnClick={this.GoBackFuncation}
      >
        <CreateProfileStyle>
         {!this.state.isLoadingData && <Formik
         
            validationSchema={() => this.NewPasswordSchema()}
            validateOnChange={true}
            initialValues={this.state.initialValues}
            validateOnBlur={false}
            onSubmit={(values) => {
              this.ChangePassword(values);
            }}
            data-test-id="formikId"
          >
            {({ errors, touched, setFieldValue, handleSubmit, values }) => (
              <form
                className="CreateForm"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} data-test-id="CreateProfile">
                    <ProjectText
                      variant="h5"
                      TextSize="20px"
                      fontweight="600"
                      fontfamily="Montserrat-SemiBold"
                      className="LoginText CreateProfileHeading"
                      gutterBottom
                    >
                      Create Profile
                    </ProjectText>
                  </Grid>
                  {this.FullNameGrid(setFieldValue, errors, touched, values)}
                  <Grid item xs={6}>
                    <InputLabel htmlFor="Nationality">
                      <ProjectText
                        variant="subtitle1"
                        fontfamily="Montserrat-Bold"
                        TextSize="16px"
                        fontweight="700"
                        textcolor="#031417"
                        className="inputName"
                      >
                        Nationality
                      </ProjectText>
                    </InputLabel>
                    <Box style={{ position: "relative" }} className="smallInput">
                      <CustomTextField
                        id="Nationality"
                        select
                        variant="outlined"
                        fullWidth
                        placeholder="Enter nationality"
                        error={
                          errors.Nationality !== undefined &&
                          touched.Nationality !== undefined
                        }
                        value={
                          values.Nationality === ""
                            ? "Saudi Arabia"
                            : values.Nationality
                        }
                        defaultValue={false}
                        inputProps={{
                          "data-test-id": "Nationality",
                        }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.SetNationality(e, setFieldValue)
                        }
                        SelectProps={{
                          native: true,
                        }}
                        name="nationality"
                        disabled={this.state.isEmployee ? true :false}
                      >
                        {Country.getAllCountries().map(
                          (option: { name: string }) => (
                            <option key={option.name} value={option.name}>
                              {option.name}
                            </option>
                          )
                        )}
                      </CustomTextField>
                      {touched.Nationality && errors.Nationality && (
                        <Grow
                          in={errors.Nationality !== undefined}
                          style={{ transformOrigin: "0 0 0" }}
                          {...(!errors.Nationality ? { timeout: 800 } : {})}
                        >
                          <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText" >
                            {errors.Nationality}
                          </ProjectText>
                        </Grow>
                      )}
                    </Box>
                  </Grid>
                  {this.DateofBirthGrid(setFieldValue, errors, touched, values)}
                  {this.UserNameGrid(setFieldValue, errors, touched, values)}
                  {this.UserIdGrid(setFieldValue, errors, touched, values)}
                  {this.MobileNoGrid()}
                  <Grid item xs={12}>
                    <Box className="TermsAndConditionsConainer">
                      <Checkbox
                        checked={values.termsAndConditions}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          this.SetCheckBox(e, setFieldValue)
                        }
                        className="ProjectCheckBox"
                        data-test-id="TermsCheckBox"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        style={{ color: errors.termsAndConditions && touched.termsAndConditions ? "red" : "#118799" }}
                        name="termsConditions"
                      />
                      <ProjectText variant="subtitle2" fontweight="400" TextSize="14px">
                        <Box>
                          <span>
                            I hereby confirm that I have reviewed all the above
                            information and agree to the&nbsp;
                          </span>
                          <a
                            href="/TermsConditions"
                            target="_blank"
                            className="termsLink"
                          >
                            Terms and Conditions
                          </a>
                        </Box>
                      </ProjectText>
                    </Box>
                    {errors.termsAndConditions && touched.termsAndConditions &&(
                      <Grow
                        in={errors.termsAndConditions !== undefined}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(!errors.termsAndConditions
                          ? { timeout: 800 }
                          : {})}
                      >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">
                          {errors.termsAndConditions}
                        </ProjectText>
                      </Grow>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <ContainedButton
                        type="submit"
                        bgcolor="success"
                        fullWidth
                        size="medium"
                        data-test-id="CreateProfileBtn"
                      >
                        Save
                      </ContainedButton>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>}
          <DilogStyle
            aria-labelledby="customized-dialog-title"
            open={this.state.DilogRender}
            fullWidth
            onClose={this.NavigateCreatePassword}
          >
            <DialogTitle id="customized-dialog-title">
              <IconButton
                aria-label="close"
                className="CloseIcon"
                onClick={this.NavigateCreatePassword}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <ProjectText
                variant="subtitle1"
                fontfamily="Montserrat-bold"
                textcolor="#000"
                fontweight="700"
                TextSize="20px"
                className="txtCenter"
              >
                Profile created successfully
              </ProjectText>
            </DialogContent>
            <Divider />
            <DialogActions
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ContainedButton
                type="submit"
                bgcolor="success"
                style={{ borderRadius: "0px", padding: "10px 24px" }}
                size="medium"
                data-test-id="CreateProfileBtnDilog"
                onClick={this.NavigateCreatePassword}
              >
                Proceed
              </ContainedButton>
            </DialogActions>
          </DilogStyle>
        </CreateProfileStyle>
      </LoginLayout>
      </CreateProfileLayout>
    );
  }
}
const CreateProfileLayout = styled(Box)({
  "& .LoginFormContainer": {
    "@media (min-width:360px) and (max-width: 449px)": {       
     display:"flex"
    },
  },
  "& .smallInput":{
    width:"14.95vw",
    "@media (min-width:360px) and (max-width:449px)":{
      width:"auto"
  },
  "@media(min-width:450px) and (max-width:1280px)":{
      width:"auto"
  },
  },
  "& .longInputBox":{
    width:"30.73vw",
    "@media (min-width:360px) and (max-width:449px)":{
      width:"auto"
  },
  "@media(min-width:450px) and (max-width:1280px)":{
      width:"auto"
  },
  },
  " & .MuiBox-root-43": {
    fontSize: "12px"
  }
})
const CreateProfileStyle = styled("div")({
  "& .CreateForm": {
    marginBottom: "100px",
  },
  "& .ProjectCheckBox": {
    color: "#118799",
    marginRight: "5px",
    padding: "0px",
  },
  "& .TermsAndConditionsConainer": {
    display: "flex",
    alignItems: "flex-start",
  },
  "& .CreateProfileHeading": {
    lineHeight: "28px",
    textTransform: "capitalize",
    fontSize: "20px",
    marginBottom: "24px"
  },
  "& input[type='date']::placeholder": {
    color: "#666"
},
"& .termsLink":{
  color: "#000",
  fontFamily: "Montserrat-bold"
},
"& .MuiGrid-grid-xs-6": {
  "@media (min-width:360px) and (max-width: 449px)": {       
    maxWidth: "100%",
    flexBasis: "100%"
  },
}
});

const DilogStyle = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    borderRadius: " 8px 8px 32px 8px",
    width: "362px",
    boxShadow: "none"
  },
  "& .MuiDialogActions-root": {
    padding: "24px 0 0 0"
},
"& .txtCenter": {
  textAlign: "center"
},
  "& .CloseIcon": {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});

const CustomTextField = styled(TextField)((props: TextFieldProps) => ({
  borderColor: "#B6CEE2",
  borderRadius: "8px",
  ...(props.error && {
    borderColor: "red"
  }),
  '& .Mui-focused': {
    outline: "none"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: "#B6CEE2",
    height: "59px"
  },
  "& .MuiOutlinedInput-root": {
    position: "relative",
    borderRadius: "8px",
    fontWeight: 700
}
}))
export default withRouter(CreateProfile)
// Customizable Area End
