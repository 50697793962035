// Customizable Area Start
import React from "react";

import { 
  Box, 
  Paper,
  Table, TableBody, TableCell, TableHead, TableRow,
  styled,
  MenuItem,
  Menu,
  Button
 
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
actionIcon,
viewInvoiceIcon,
DetailsIcon,
refundIcon,
shareIcon,

} from "./assets";


import ProjectSideBar from "../../../components/src/ProjectSideBar";


import CollectTransactionFeesController, {
  Props, Transaction,
} from "./CollectTransactionFeesController";
import TransactionsDetails from "./TransactionsDetails.web";


export default class CollectTransactionFees extends CollectTransactionFeesController {
  constructor(props: Props) {
    super(props);
  }
  renderSelect = () => {
    return <StyledSelect
      id="simple-menu"
      aria-haspopup="true"
      elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
      anchorEl={this.state.anchorEl}
      keepMounted
      open={Boolean(this.state.anchorEl)}
      onClose={this.handleClose}
    >
      <StyledMenuItem onClick={this.handleClose} data-testid="close_button" >
    <img src={viewInvoiceIcon} style={{ marginRight: '8px', width: '19px', height: '21px' }} />
    View Invoice
  </StyledMenuItem>
      <StyledMenuItem data-testid="nav_details" onClick={this.handleNavigateToDetails}>  
       <img src={DetailsIcon} style={{ marginRight: '8px', width: '19px', height: '21px' }} />
    Details</StyledMenuItem>
      <StyledMenuItem onClick={this.handleClose} data-testid="close_button"> <img src={refundIcon} style={{ marginRight: '8px', width: '19px', height: '21px' }} />
    Refund</StyledMenuItem>
      <StyledMenuItem onClick={this.handleClose} data-testid="close_button"> <img src={shareIcon} style={{ marginRight: '8px', width: '19px', height: '21px' }} />
    Share invoice to merchant</StyledMenuItem>
    </StyledSelect>
  };


  renderTableHead = () => {
    return (
        <TableHead>
            <TableRow style={{borderBottom:'1px solid black'}}>
                {
                    this.state.tablehead &&this.state.tablehead.map((item: string) => < StyledTableCell key={item}>{item}</ StyledTableCell>)
                }
            </TableRow>
        </TableHead>
    )
};



renderTableBody = () => {
    return (
        <TableBody>
         
            {
              this.state.transactionsList.length > 0 && this.state.transactionsList.map((item: Transaction, index: number) => {
                    return <TableRow key={index}>
                      <StyledBodyTableCell  >{item.merchant_name}</StyledBodyTableCell>
                      <StyledBodyTableCell >{item.date && this.formatDate(item.date)}</StyledBodyTableCell>
                      <StyledBodyTableCell >SAR {item.amount}</StyledBodyTableCell>
                      <StyledBodyTableCell style={{ color: this.getStatusColor(item.status) }} >{item.status}</ StyledBodyTableCell>
                        <StyledBodyTableCell ><button style={{
                          border: "none",
                          backgroundColor: "transparent",
                        }} onClick={this.handleClick} data-testid="details_button" ><img src={actionIcon}/></button> {this.renderSelect}</StyledBodyTableCell>
                    </TableRow>
                })
            }
        </TableBody>
    ) 
};
  renderTransactions = () => {
    return <>
      <StyledPaper>
        <ScrollContainer>
          <BigTableWrapper data-test-id="collectionAnalyticsTableID">
            <StyledTable>
          
                {this.renderTableHead()}
                {this.renderTableBody()}
                {this.renderSelect()}
            </StyledTable>
          </BigTableWrapper>
        </ScrollContainer>
      </StyledPaper>
    </>
  };


  render() {
    return (
      <ProjectSideBar pageThisVar={this} childComponentTitle={this.state.showDetails ? "Details" : "Transactions" }>
        {this.state.showDetails === true ? (
          <>
          <StyledBox >
           <Button onClick={this.BackButtonOnClick} className="BackButton" startIcon={<ArrowBackIcon />}>Back</Button>
          </StyledBox>  
          <TransactionsDetails/></>
        ):
      <StyledContainer>
     
        {this.renderTransactions()}
    </StyledContainer>
  }
    </ProjectSideBar>
    );
  }
}
const StyledTable = styled(Table)({
  "& .MuiTableBody-root": {
      textTransform: "capitalize"
  },

});
const StyledBox = styled(Box)({
  display:'flex',
  backgroundColor:'red',
  "& .BackButton": {
    position: "absolute",
    top: "24px",
    left: "36px",
    color: "black",
    fontWeight: 600,
    fontSize: "16px",
    textTransform: "unset"
  }
})
const StyledTableCell = styled(TableCell)({
  borderBottom:'1px solid #DBE7F0',
fontFamily:"Montserrat-semibold",
fontWeight:600,
fontSize:14,
color:"#1A1A1A",
padding:"24px"

})
const StyledBodyTableCell = styled(TableCell)({
  border:'none',
  fontFamily:"Montserrat-regular",
fontWeight:400,
fontSize:16,
color:"#4D4D4D",
padding:"24px"
})

const StyledMenuItem = styled(MenuItem)({
  padding:8,
  fontFamily:"Montserrat-regular",
  fontWeight:400,
fontSize:14,
color:"#1A1A1A",
})

const StyledSelect = styled(Menu)({
  padding:16,
  zIndex:1000,
  "& .MUISelect-select:focus": {
    backgroundColor: "transparent"
  },
  "&:focus": {
    outline: "none",
  },
  "&&::before": {
    content: "none",
  },
  "&&::after": {
    content: "none",
  },
});
const StyledContainer = styled(Box)({
  margin: "40px 20px",
  display: "flex",
  flexDirection: "column",
  gap: "20px"
});

const StyledPaper = styled(Paper)({
  boxShadow: "none",
});

const ScrollContainer = styled(Box)({
  overflowX: "auto",
  paddingBottom: "20px",
  marginTop: "10px"
});
const BigTableWrapper = styled(Box)({
  minWidth: "900px"
});



// Customizable Area End
