// Customizable Area Start
import React from 'react'

export const AccountcreationIcon = () => {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4C4 5.06087 4.42143 6.07828 5.17157 6.82843C5.92172 7.57857 6.93913 8 8 8C9.06087 8 10.0783 7.57857 10.8284 6.82843C11.5786 6.07828 12 5.06087 12 4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0ZM8 2C8.53043 2 9.03914 2.21071 9.41421 2.58579C9.78929 2.96086 10 3.46957 10 4C10 4.53043 9.78929 5.03914 9.41421 5.41421C9.03914 5.78929 8.53043 6 8 6C7.46957 6 6.96086 5.78929 6.58579 5.41421C6.21071 5.03914 6 4.53043 6 4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2ZM15 8C14.84 8 14.76 8.08 14.76 8.24L14.5 9.5C14.28 9.68 13.96 9.84 13.72 10L12.44 9.5C12.36 9.5 12.2 9.5 12.12 9.6L11.16 11.36C11.08 11.44 11.08 11.6 11.24 11.68L12.28 12.5V13.5L11.24 14.32C11.16 14.4 11.08 14.56 11.16 14.64L12.12 16.4C12.2 16.5 12.36 16.5 12.44 16.5L13.72 16C13.96 16.16 14.28 16.32 14.5 16.5L14.76 17.76C14.76 17.92 14.84 18 15 18H17C17.08 18 17.24 17.92 17.24 17.76L17.4 16.5C17.72 16.32 18.04 16.16 18.28 16L19.5 16.5C19.64 16.5 19.8 16.5 19.8 16.4L20.84 14.64C20.92 14.56 20.84 14.4 20.76 14.32L19.72 13.5V12.5L20.76 11.68C20.84 11.6 20.92 11.44 20.84 11.36L19.8 9.6C19.8 9.5 19.64 9.5 19.5 9.5L18.28 10C18.04 9.84 17.72 9.68 17.4 9.5L17.24 8.24C17.24 8.08 17.08 8 17 8H15ZM8 9C5.33 9 0 10.33 0 13V16H9.67C9.39 15.41 9.19 14.77 9.09 14.1H1.9V13C1.9 12.36 5.03 10.9 8 10.9C8.43 10.9 8.87 10.94 9.3 11C9.5 10.36 9.77 9.76 10.12 9.21C9.34 9.08 8.6 9 8 9ZM16.04 11.5C16.84 11.5 17.5 12.16 17.5 13.04C17.5 13.84 16.84 14.5 16.04 14.5C15.16 14.5 14.5 13.84 14.5 13.04C14.5 12.16 15.16 11.5 16.04 11.5Z" fill="#118799" />
    </svg>
  )
}
// Customizable Area End