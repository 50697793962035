// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { RouteComponentProps } from "react-router";
export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextFiledTypeForConfirm: boolean,
  otp: string | number,
  Sendotp: boolean,
  VerifyCode: Array<string> | string
}

interface SS {
  id: string;
}

export default class PasswordCreatedController extends BlockComponent<Props, S, SS> {


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      TextFiledTypeForConfirm: false,
      otp: "",
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "",
      PhoneInputBorder: false,
      Password: "",
      TextFiledType: false,
      Sendotp: false,
      VerifyCode: ""
    };
  }

  NavigateToLogin = () => {
    const LocationData = this.props.location.state === "CreateProfile"

    if (LocationData) {
      this.props.history.replace({
        pathname: `/AccountCreate`,
      });
    } else {
      this.props.history.replace({
        pathname: `/CreateProfile`,
      });
    }
  }
}
// Customizable Area End