// Customizable Area Start
import React from "react";

import { 
 
  Box, 
  Paper,
 
  Typography, 
  
  Grid,
  Divider,
  styled,
} from '@material-ui/core';

import {

successIcon
} from "./assets";




import CollectTransactionFeesController, {
  Props,

} from "./CollectTransactionFeesController";


export default class TransactionsDetails extends CollectTransactionFeesController {
  constructor(props: Props) {
    super(props);
  }

  
  render() {
    return (
   <>
       

      <Box data-test-id="container" style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:"5em",backgroundColor:'white'}}>
                <StyledPaper elevation={3} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{marginBottom:16}}>
                            <Typography variant="h6" style={{fontWeight:600,textAlign:'center',fontSize:18,color:"#06130E",fontFamily:"Montserrat-semibold"}}>
                                From Merchant Name 1
                            </Typography>
                            <Typography variant="body2" style={{textAlign:'center',color:'#666666',fontSize:16,fontWeight:600,fontFamily:"Montserrat-semibold"}}>+49 202 450 452</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: "#06130E", fontSize: 18, fontWeight: 600, marginTop: 16 }}>
                            <Typography variant="h4" style={{color:"#333333",fontWeight:400,fontSize:48,fontFamily:"Montserrat-regular"}}><span style={{fontWeight:600,fontSize:36,color:'black'}}>SAR</span>50,000</Typography>
                            <Typography variant="body2" style={{ display: 'flex', alignItems: 'center',fontFamily:"Montserrat-semibold", color: "#06130E", fontSize: 18, fontWeight: 600, marginTop: 16 }}>
                               <img src={successIcon} style={{ marginRight: 8 }} alt="Success Icon" />Paid
                            </Typography>
                          
                        </Grid>
                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', color: "#06130E", fontSize: 18, fontWeight: 600, marginTop: 16 }}>
                        <Divider style={{width:"50%",border:'1px solid #DBE7F0',marginTop:10}}/>
                        </Grid>
                        
                        <Grid item xs={12} style={{ textAlign: 'center', marginTop: 20,marginBottom:10 }}>
                            <Typography variant="body2" style={{color:"#333333",fontSize:14,fontWeight:400,fontFamily:"Montserrat-regular"}}>Aug 16, 2023 at 11:36 PM</Typography>
                        </Grid>

                        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column',marginBottom:20, color: "#06130E", fontSize: 18, fontWeight: 600, marginTop: 16 }}>
                        <Divider style={{width:"100%",border:'1px solid #DBE7F0',marginTop:10}}/>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="body2"  style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Reference number</Typography>
                            <Typography variant="body1" style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>302547845</Typography>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'right'}}>
                            <Typography variant="body2" style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Location</Typography>
                            <Typography variant="body1"  style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>Sample Location</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="body2"  style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Wholesaler name</Typography>
                            <Typography variant="body1"  style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>Sample Name</Typography>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'right'}}>
                            <Typography variant="body2"  style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Wholesaler employee</Typography>
                            <Typography variant="body1"  style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>Sample Employee</Typography>
                        </Grid>
                        <Grid item xs={6} >
                            <Typography variant="body2"  style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Merchant Name 1</Typography>
                            <Typography variant="body1"  style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>Sample Name</Typography>
                        </Grid>
                        <Grid item xs={6} style={{textAlign:'right'}}>
                            <Typography variant="body2"  style={{fontFamily:"Montserrat-semibold",color:"#333333",fontSize:16,fontWeight:600}}>Merchant employee name</Typography>
                            <Typography variant="body1"  style={{fontFamily:"Montserrat-regular",color:"#666666",fontSize:14,fontWeight:400}}>Sample Employee</Typography>
                        </Grid>
                    </Grid>
                </StyledPaper>
        
      </Box>
      </>

    );
  }
}
const StyledPaper = styled(Paper)({
  padding: 25, 
  borderRadius: 10,
  background:'none', 
  width:565,
  backgroundColor:'white',
  position:'relative',
  zIndex:1,
  "@media (max-width:865px)":{
    width:"90%"
  }
})


// Customizable Area End
