// Customizable Area Start
import React from "react";
import ForgotPasswordController from "./ForgotPasswordController.web";
import { Box, Grid, InputLabel, Button, Grow } from "@material-ui/core";
import { ContainedButton } from "../../../components/src/GlobalButton";
import ProjectText from "../../../components/src/ProjectText";
import LoginLayout from "../../../components/src/LoginLayout";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

class ForgotPassword extends ForgotPasswordController {
  ForgotPasswordSchema = () => {
    return Yup.object().shape({
      Phone: Yup.string().required("Phone number is required").min(12, "The phone number you have entered is invalid").max(15, "The phone number you have entered is invalid")
        .nullable(),
    });
  };

  render() {
    return (
      <ForgotPasswordStyle>
        <LoginLayout
          BackButton
          BackButtonOnClick={() => {
            this.props.history.goBack()
          }}
        >
          <Formik
            validationSchema={() => this.ForgotPasswordSchema()}
            isInitialValid
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{
              Phone: "",
            }}
            onSubmit={(values) => {
              this.FormSubmit(values);
            }}
          >
            {({ touched, errors, setFieldValue, handleSubmit, values }) => (
              <form onSubmit={handleSubmit} autoComplete="off" data-test-id="ForgotPassword" className="forgotPasswordContainer">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ProjectText variant="h4" className="LoginText" fontfamily="Montserrat-Bold" textcolor="#031417" fontweight="700" gutterBottom>
                      Password Reset
                    </ProjectText>
                    <ProjectText
                      variant="subtitle1"
                      className="DescriptionText"
                      TextSize="14px" fontweight="400" textcolor="#333"
                      gutterBottom
                    >
                      Please validate your phone number to reset the password.
                    </ProjectText>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="PhoneNumber">
                      <ProjectText variant="subtitle1"
                        fontweight="700" TextSize="16px" fontfamily="Montserrat-Bold" textcolor="#031417"
                        className="inputName"
                      >
                        Phone number
                      </ProjectText>
                    </InputLabel>
                    <Box style={{ position: "relative" }}>
                      {errors.Phone === undefined && values?.Phone !== "" &&
                        <div className="CheckIcon">
                          <DoneAllIcon />
                        </div>
                      }
                      <PhoneInput
                        country={this.state.CountryCode}
                        placeholder={"Enter Phone Number"}
                        value={this.state.Phone?.toString()}
                        enableSearch={true}
                        disableSearchIcon={true}
                        inputStyle={{
                          borderColor: errors.Phone ? "red" : "",
                        }}
                        inputClass={this.phoneInputClassHandler(values, touched, errors)}
                        dropdownStyle={{
                          cursor: 'none'
                        }}
                        jumpCursorToEnd={true}
                        onChange={(number: string | number) => {
                          this.SetPhoneNumber(number, setFieldValue);
                        }}
                        inputProps={{
                          required: true,
                          id: "PhoneNumber",
                          "data-test-id": "PhoneNumber"
                        }}
                        countryCodeEditable={false}
                      />
                    </Box>
                    {(touched.Phone || errors.Phone) && (
                      <Grow
                        in={errors.Phone !== undefined}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(!errors.Phone ? { timeout: 800 } : {})}
                      >
                        <ProjectText
                          type="error"
                          fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                          className="errorText"
                        >
                          {errors.Phone}
                        </ProjectText>
                      </Grow>
                    )}
                    {
                      !errors.Phone && this.state.apiError && <ProjectText
                        type="error"
                        fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                        className="errorText"
                      >
                        {this.state.apiError}
                      </ProjectText>
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <Box>
                      <ContainedButton
                        size="medium"
                        data-testid="loginbutoon"
                        type="submit"
                        bgcolor="success"
                        variant="contained"
                        className="proceedButton"
                        // style={{width:'26.05vw',marginTop:"2%"}}
                        fullWidth
                      >
                        Proceed
                      </ContainedButton>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                        minHeight: "50px",
                        height: "50px",
                      }}
                    >
                      {this.state.Sendotp && (
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: "white",
                            color: "black",
                            textTransform: "unset",
                            fontWeight: 600
                          }}
                          startIcon={
                            <CheckCircleIcon style={{ color: "#5DBB65" }} />
                          }
                        >
                          OTP sent
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </LoginLayout>
      </ForgotPasswordStyle>
    );
  }
}

export default withRouter(ForgotPassword);
const ForgotPasswordStyle = styled(Box)({
  "& .forgotPasswordContainer": {
    width: "26.05vw",
    height: "26.15vh",
    top: "408px",
    left: "289px",
    position: "fixed",
    "@media (max-width: 414px)": {
      top: "none",
      left: "none",
      width: "75.40vw",
      height: "23.99vh",
      position: "initial",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      top: "none",
      left: "none",
      width: "75.40vw",
      height: "23.99vh",
      position: "initial",
    },
  },
  "& .react-tel-input": {
    width: "26.05vw",
    "@media (max-width: 414px)": {
      width: "75.40vw",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "75.40vw",
    },
  },
  "& .proceedButton": {
    width: '26.05vw', marginTop: "2%",
    "@media (min-width:360px) and (max-width: 449px)": {
      width: "75.30vw",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "75.30vw",
    },
  },
  "& .logoContainer": {
    '& img': {
      "@media (min-width:360px) and (max-width: 449px)": {
        display: "none"
      },
      "@media (min-width:450px) and (max-width:1280px)": {
        display: "none"
      },
    }
  },
  "& .LoginText": {
    "@media (max-width: 414px)": {
      textAlign: "start",
    }
  },
  "& .contentContainer": {
    // "@media (min-width:450px) and (max-width:1280px)": {
    //   position:"inherit", 
    //   alignItems:"flex-start",
    //   marginTop:"15%"
    // },
    overflow: "hidden",
    "@media (min-width:360px) and (max-width: 600px)": {
      position: "inherit",
      alignItems: "flex-start",
      marginTop: "25%"
    },
    "@media (min-width:600px) and (max-width:962px)": {
      marginTop: "20%",
      position: "inherit"
    },
    "@media (min-width:962px) and (max-width:1280px)": {
      marginTop: "15%",
      position: "inherit"
    },
  },
  '& .boyImage': {
    '& img': {
      "@media (min-width:450px) and (max-width:1280px)": {
        display: "none"
      },
    }
  },
  "& .DescriptionText": {
    width: "22.72vw",
    "@media (max-width: 414px)": {
      width: "75.40vw",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      width: "75.40vw",
    },
  },
})
// Customizable Area End
