import React from "react";
 // Customizable Area Start
import HelpCentreController,{Props}  from "./HelpCentreController";
import { Box, Typography } from "@material-ui/core";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { callLogo, webLogo } from "./assets";

// Customizable Area End

 // Customizable Area Start
 // Customizable Area End
export default class ContactUs extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 
  render() {
    // Customizable Area Start
    console.log("help center from faq",this.state.helpCentreNewQA)
    return (
       <>
       <ProjectSideBar pageThisVar={this}>
            <Box sx={{marginBottom:'5%'}}>
            {this.state.helpCentreNewQA.map((item:any,i:any) =>(
              <>
                <Box sx={webStyle.topBartext}>

                  {item.attributes.question}
                </Box>

                <Box sx={webStyle.textContainer}>
                          
              
                         {item.attributes.answer.split('\r\n').map((step:any, index:any) => (
                         <>
                    { step!=="" && 
                 
                   (
                    <>
                          
                           <Typography style={webStyle.contentText}>
                          {step}
                    
                          </Typography>
                          <br />
                       
                        </>
                      )
                         }
                              </>
                      ))}
                </Box>
                </>
            ))}
            </Box>
        </ProjectSideBar>
       </>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {

  
  titleText2:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
    marginLeft:'1%',
  },
  topBartext:{  
    fontSize: 24,
    color: "#1A1A1A",
    userSelect:'none',
    margin:'4%',
    fontFamily:'Montserrat-Bold'
  },
  titleText1:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
  },
  textContainer:{
    display:'flex',
    flexDirection:'column',
    marginLeft:'6%',
    paddingLeft:'1%',
    marginRight: '6%'
  },
  subcontentText:{
    color:'#4D4D4D',
    fontSize:'16px',
    fontFamily:'Montserrat-SemiBold',
    marginBottom:'1%',
    marginTop:'1%', 
  },
  titleTextContainer:{
    display:'flex',
    flexDirection:'row',
    marginLeft:'4%',
    marginTop:'2%', 
    marginBottom:'1%'
  },
  contentText:{
    color:'#333333',
    fontSize:'16px',
    fontFamily:'Montserrat-Regular',
    marginBottom:'1%',
    marginTop:'1%', 
  },

}
// Customizable Area End




