import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
// Customizable Area Start
    passCode: Array<{ value: string }>,
    matchPasscode: Array<{ value: string }>,
    passCodeFalse: boolean,
    errorText: boolean,
    seconds: number,
    passcodeAttempet: number,
    forogotButtonActive: boolean,
    resetStep: number,
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PasscodeLockController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiResetPasscodeID = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            // Customizable Area Start
            seconds: 30,
            passCodeFalse: false,
            passCode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
            matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
            errorText: false,
            passcodeAttempet: 0,
            forogotButtonActive: false,
            resetStep: 1,
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.message) {
            
                
                this.setState({
                    resetStep: 3
                });
            } 
           
        }

        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (values: string) => {
        let token = await getStorageData("authToken");
        let header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.resetPasscodeApiEndPoint}?id=${id}`
            // configJSON.resetPasscodeApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "new_passcode": values
            })
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.apiResetPasscodeID =  requestMessage.messageId;
    }

    setPasscode = (val: string) => {
        let passCode = [...this.state.passCode]
        let findIndex: number = this.state.passCode.findIndex((i: { value: string }) => i.value === "")
        passCode[findIndex === -1 ? 5 : findIndex].value = val
        this.setState({
            passCode,
        }, () => {
            let AllAnwserOrNot = this.state.passCode.filter((item: { value: string }) => item.value === "").length === 0
            if (AllAnwserOrNot) {
                this.setState({ resetStep: 2 })
            }
        })
    };

    reEnterPasscode = (val: string) => {
        let PassCode = [...this.state.matchPasscode]
        let findIndex: number = this.state.matchPasscode.findIndex((i: { value: string }) => i.value === "")
        PassCode[findIndex === -1 ? 5 : findIndex].value = val
        this.setState({
            matchPasscode: PassCode
        }, () => {
            let AllAnwserOrNot = this.state.matchPasscode.filter((item: { value: string }) => item.value === "").length === 0

            if (AllAnwserOrNot) {
                const EnteredPasscode = this.state.passCode.map((passcode: { value: string }) => passcode.value).join("");
                const ReEnteredPasscode = this.state.matchPasscode.map((passcode: { value: string }) => passcode.value).join("");
                if (EnteredPasscode === ReEnteredPasscode) {
                    this.apiCall(ReEnteredPasscode)
                } else {
                    this.setState({
                        errorText: true,
                        matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]
                    });
                }
            }
        })
    };

    clearLastDigit = () => {
        let passCode = [...this.state.passCode]
        let findIndex = this.state.passCode.findIndex((i: { value: string }) => i.value === "")
        if (findIndex === -1) {
            findIndex = 5
        } else if (findIndex === 0) {
            findIndex = 0
        } else {
            findIndex = findIndex - 1
        }
        passCode[findIndex].value = ""
        this.setState({
            passCode
        })
    }
    clearReLastDigit = () => {
        let passCode = [...this.state.matchPasscode]
        let findIndex = this.state.matchPasscode.findIndex((i: { value: string }) => i.value === "")
        if (findIndex === -1) {
            findIndex = 5
        } else if (findIndex === 0) {
            findIndex = 0
        } else {
            findIndex = findIndex - 1
        }
        passCode[findIndex].value = ""
        this.setState({
            matchPasscode: passCode
        });
    };

    goBackHandler = () => {
        this.setState({
            resetStep: this.state.resetStep - 1,
            passCode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
            matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
        })
    }

    goToLoginScreen = () => {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
        to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(to);
      }
    // Customizable Area End
}
