// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { getStorageData } from "../../../framework/src/Utilities";
import { configJSON } from "./PasscodeLockController";
export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  PassCode: Array<{ value: string }>,
  matchPasscode: Array<{ value: string }>,
  isLoading: boolean,
  PassCodeFalse: boolean,
  ErrorText: boolean,
  Seconds: number,
  PasscodeAttempet: number,
  ForogotButtonActive: boolean,
  resetStep: number,
  phone: string | number,
  countryCode: string,
  sendotp: boolean,
  passcodeType: string | unknown,
  showPasscodePhoneNo: boolean,
  apiError: boolean
  passCodeErrorText: string
}

interface SS {
  id: string;
}

export default class SettingsResetPasscodeController extends BlockComponent<
  Props, S, SS
> {
  apiUpdatePasscodeID: string | undefined;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage), 
      getName(MessageEnum.CountryCodeMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      isLoading: false,
      Seconds: 30,
      PassCodeFalse: false,
      PassCode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      ErrorText: false,
      PasscodeAttempet: 0,
      ForogotButtonActive: false,
      resetStep: 1,
      phone: "",
      sendotp: false,
      countryCode: "sa",
      passcodeType: "",
      showPasscodePhoneNo: false,
      apiError: false,
      passCodeErrorText: ""
    };
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
      if (responseJson.errors || responseJson.error) {
        if(apiRequestCallId === this.ConfiormPasscodeId){
           this.ConfiormPasscodeApiCallResponse()
        } else{
          this.setState({
            passCodeErrorText: responseJson.errors,
            apiError: true,
            matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
          })
        }
      } else {
        if (apiRequestCallId === this.ConfiormPasscodeId) {
          this.setState({
            resetStep: 2,
            PassCode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }]
          })
        } else{
          this.apiSuccess(apiRequestCallId)
        }
    }
  }

  ConfiormPasscodeApiCallResponse = () => {
    this.setState({
      Seconds: 30,
      PasscodeAttempet: this.state.PasscodeAttempet + 1,
      ErrorText: true,
      PassCode: this.state.PassCode.map((item: { value: string }) => {
        return { ...item, value: "" }
      })
    }, () => {
      this.AfterStateSet()
    })
  }

  AfterStateSet = () => {
    if (this.state.PasscodeAttempet > 2) {
      this.TimeFor30Sec()
      this.setState({
        ErrorText: false,
        ForogotButtonActive: true
      })
    }
  }

  TimeFor30Sec = () => {
    this.setState({
      PassCodeFalse: true,
    })
    const Timer = setInterval(() => {
      if (this.state.Seconds !== 0) {
        this.setState({
          Seconds: this.state.Seconds - 1
        })
      }
      if (this.state.Seconds === 0) {
        clearInterval(Timer)
        this.setState({
          Seconds: 30,
          PassCodeFalse: false,
          PasscodeAttempet: 0
        })
      }
    }, 1000)
  }

  SetPasscode = (val: string) => {
    let PassCode = [...this.state.PassCode]
    let findIndex: number = this.state.PassCode.findIndex((i: { value: string }) => i.value === "")
    PassCode[findIndex === -1 ? 5 : findIndex].value = val
    this.setState({
      PassCode,
      ErrorText:false
    }, () => {
      let AllAnwserOrNot = this.state.PassCode.filter((item: { value: string }) => item.value === "").length === 0
      if (AllAnwserOrNot) {
        this.state.resetStep === 1 && this.ConfirmPasscode();
        this.state.resetStep === 2 && this.setState({ resetStep: 3 })
      }
    })
  };

  reEnterPasscode = (val: string) => {
    let PassCode = [...this.state.matchPasscode]
    let findIndex: number = this.state.matchPasscode.findIndex((i: { value: string }) => i.value === "")
    PassCode[findIndex === -1 ? 5 : findIndex].value = val
    this.setState({
      matchPasscode: PassCode,
      apiError: false,
      ErrorText: false
    }, () => {
      let AllAnwserOrNot = this.state.matchPasscode.filter((item: { value: string }) => item.value === "").length === 0

      if (AllAnwserOrNot) {
        const EnteredPasscode = this.state.PassCode.map((passcode: { value: string }) => passcode.value).join("");
        const ReEnteredPasscode = this.state.matchPasscode.map((passcode: { value: string }) => passcode.value).join("");
        if (EnteredPasscode === ReEnteredPasscode) {
          this.updatePasscodeHandler(ReEnteredPasscode)
        } else {
          this.setState({
            ErrorText: true,
            matchPasscode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
          });
        }
      }
    })
  };

  ClearLastDigit = () => {
    let PassCode = [...this.state.PassCode]
    let findIndex = this.state.PassCode.findIndex((i: { value: string }) => i.value === "")
    if (findIndex === -1) {
      findIndex = 5
    } else if (findIndex === 0) {
      findIndex = 0
    } else {
      findIndex = findIndex - 1
    }
    PassCode[findIndex].value = ""
    this.setState({
      PassCode
    })
  }
  ClearReLastDigit = () => {
    let PassCode = [...this.state.matchPasscode]
    let findIndex = this.state.matchPasscode.findIndex((i: { value: string }) => i.value === "")
    if (findIndex === -1) {
      findIndex = 5
    } else if (findIndex === 0) {
      findIndex = 0
    } else {
      findIndex = findIndex - 1
    }
    PassCode[findIndex].value = ""
    this.setState({
      matchPasscode: PassCode
    });
  };

  ConfiormPasscodeId: string = "";

  ConfirmPasscode = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": await getStorageData("otpScreen")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const Passcode = this.state.PassCode.map((item: { value: string }) => item.value).join("").toString()

    this.ConfiormPasscodeId = requestMessage.messageId;
    let Body = {
      "passcode": Number(Passcode)
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.confirmPasscodeApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  apiCall = async (data: {[key:string]: string | {[key:string]: string | number}}) => {
    let { contentType, method, endPoint, body } = data;
    let token = await getStorageData("authToken");
    let header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  passcodeResetStepHandler = () => {
    this.setState({
      resetStep: this.state.resetStep + 1,
    });
  };

  async componentDidMount(): Promise<void> {
  };

  updatePasscodeHandler = async (values: string) => {
    this.apiUpdatePasscodeID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMethod,
      endPoint: configJSON.resetPasscodeApiEndPoint,
      body: {
        "new_passcode": +values
      }
    })
  };

  apiSuccess = (apiCallID: string) => {
    if (apiCallID === this.apiUpdatePasscodeID) {
      this.setState({
        resetStep: 4
      })
    }
  }

  goToLoginScreen = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };
}
// Customizable Area End