import React from "react";
// Customizable Area Start
import { Box, InputLabel, styled, Grid, Grow,Button } from "@material-ui/core";
import QrCodesController, { Props, configJSON } from "./QrCodesController.web";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { ContainedButton } from "../../../components/src/GlobalButton";
import { Formik } from "formik";
import ProjectText from "../../../components/src/ProjectText";
import ProjectTextField from "../../../components/src/ProjectTextField";
import DatePicker from "react-datepicker";
// Customizable Area End
 
// Customizable Area Start
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Customizable Area End

export default class Qrcodes extends QrCodesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderWholesalerName = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { wholesaler_name?: string }, touched: { wholesaler_name?: string | boolean }, values: { wholesaler_name: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="wholesaler_name">{configJSON.inputLabelOne}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.wholesaler_name !== undefined && touched.wholesaler_name !== undefined}
            id="wholesaler_name"
            inputProps={{
              "data-test-id": "wholesaler_name"
            }}
            value={values.wholesaler_name}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setWholeSalerName(event, setFieldValue)
            }}
            name="wholesaler_name"
            placeholder={configJSON.inputPlaceholderOne}
            screenTypeField="qr"
          />
          {(touched.wholesaler_name && errors.wholesaler_name) &&
            <Grow
              in={errors.wholesaler_name !== undefined}
              {...(!errors.wholesaler_name ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.wholesaler_name}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };

  renderMerchantName = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { merchant_name?: string }, touched: { merchant_name?: string | boolean }, values: { merchant_name: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="merchant_name">{configJSON.inputLabelTwo}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.merchant_name !== undefined && touched.merchant_name !== undefined}
            id="merchant_name"
            inputProps={{
              "data-test-id": "merchant_name"
            }}
            value={values.merchant_name}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setMerchantName(event, setFieldValue)
            }}
            name="merchant_name"
            placeholder={configJSON.inputPlaceholderTwo}
            screenTypeField="qr"
          />
          {(touched.merchant_name && errors.merchant_name) &&
            <Grow
              in={errors.merchant_name !== undefined}
              {...(!errors.merchant_name ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.merchant_name}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };

  renderAmount = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { amount?: string }, touched: { amount?: string | boolean }, values: { amount: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="amount">{configJSON.inputLabelThree}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.amount !== undefined && touched.amount !== undefined}
            id="amount"
            inputProps={{
              "data-test-id": "amount"
            }}
            value={values.amount}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setAmount(event, setFieldValue)
            }}
            name="amount"
            placeholder={configJSON.inputPlaceholderThree}
            screenTypeField="qr"
          />
          {(touched.amount && errors.amount) &&
            <Grow
              in={errors.amount !== undefined}
              {...(!errors.amount ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.amount}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };

  renderDateAndTime = () => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="dateAndTime">{configJSON.inputLabelFour}</StyledInputLabel>
        <StyledFormControl className={this.state.showDateError ? "dateAndTime" : ""}>
          <DatePicker
            showIcon
            icon={<StyledIcon htmlFor="dateAndTime"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3647 3.81818H19.2738C20.2738 3.81818 21.092 4.63636 21.092 5.63636V20.1818C21.092 21.1818 20.2738 22 19.2738 22H4.72834C3.72834 22 2.91016 21.1818 2.91016 20.1818V5.63636C2.91016 4.63636 3.72834 3.81818 4.72834 3.81818H5.63743V2.90909C5.63743 2.40909 6.04652 2 6.54652 2C7.04652 2 7.45561 2.40909 7.45561 2.90909V3.81818H16.5465V2.90909C16.5465 2.40909 16.9556 2 17.4556 2C17.9556 2 18.3647 2.40909 18.3647 2.90909V3.81818ZM5.63743 20.1818H18.3647C18.8647 20.1818 19.2738 19.7727 19.2738 19.2727V8.36364H4.72834V19.2727C4.72834 19.7727 5.13743 20.1818 5.63743 20.1818Z" fill="#999999" />
            </svg></StyledIcon>}
            selected={this.state.dateValue}
            onChange={(date: Date) => this.onDateChange(date)}
            showTimeInput
            dateFormat="dd/MM/yyyy hh:mm"
            name="dateAndTime"
            id="dateAndTime"
            placeholderText={configJSON.inputPlaceholderFour}
            minDate={new Date()}
          />
        </StyledFormControl>
        {
          this.state.showDateError && <ProjectText type="error">Date and time are required.</ProjectText>
        }
      </Grid>
    )
  };

  renderMerchantEmployeeName = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { merchant_employee_name?: string }, touched: { merchant_employee_name?: string | boolean }, values: { merchant_employee_name: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="merchant_employee_name">{configJSON.inputLabelFive}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.merchant_employee_name !== undefined && touched.merchant_employee_name !== undefined}
            id="merchant_employee_name"
            inputProps={{
              "data-test-id": "merchant_employee_name"
            }}
            value={values.merchant_employee_name}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setMerchantEmployeeName(event, setFieldValue)
            }}
            name="merchant_employee_name"
            placeholder={configJSON.inputPlaceholderFive}
            screenTypeField="qr"
          />
          {(touched.merchant_employee_name && errors.merchant_employee_name) &&
            <Grow
              in={errors.merchant_employee_name !== undefined}
              {...(!errors.merchant_employee_name ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.merchant_employee_name}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };

  renderWholesalerEmployeeName = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { wholesaler_employee_name?: string }, touched: { wholesaler_employee_name?: string | boolean }, values: { wholesaler_employee_name: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="wholesaler_employee_name">{configJSON.inputLabelSix}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.wholesaler_employee_name !== undefined && touched.wholesaler_employee_name !== undefined}
            id="wholesaler_employee_name"
            inputProps={{
              "data-test-id": "wholesaler_employee_name"
            }}
            value={values.wholesaler_employee_name}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setWholesalerEmployeeName(event, setFieldValue)
            }}
            name="wholesaler_employee_name"
            placeholder={configJSON.inputPlaceholderSix}
            screenTypeField="qr"
          />
          {(touched.wholesaler_employee_name && errors.wholesaler_employee_name) &&
            <Grow
              in={errors.wholesaler_employee_name !== undefined}
              {...(!errors.wholesaler_employee_name ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.wholesaler_employee_name}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };

  renderLocation = (setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void, errors: { location?: string }, touched: { location?: string | boolean }, values: { location: string }) => {
    return (
      <Grid item xs={12}>
        <StyledInputLabel htmlFor="location">{configJSON.inputLabelSeven}</StyledInputLabel>
        <StyledFormControl>
          <ProjectTextField
            type="text"
            error={errors.location !== undefined && touched.location !== undefined}
            id="location"
            inputProps={{
              "data-test-id": "location"
            }}
            value={values.location}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setLocation(event, setFieldValue)
            }}
            name="location"
            placeholder={configJSON.inputPlaceholderSeven}
            screenTypeField="qr"
          />
          {(touched.location && errors.location) &&
            <Grow
              in={errors.location !== undefined}
              {...(!errors.location ? { timeout: 800 } : {})}
            >
              <ProjectText type="error">{errors.location}</ProjectText>
            </Grow>
          }
        </StyledFormControl>
      </Grid>
    )
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ProjectSideBar pageThisVar={this}>
          {
            this.state.showQrCode &&
            (
            <StyledBox >
           <Button onClick={this.BackButtonOnClick} className="BackButton" startIcon={<ArrowBackIcon />}>Back</Button>
          </StyledBox>  
            )
          }
        <StyledContainer>
        
          {
            this.state.showQrCode ?
            (

               <QRCodeContainer data-test-id='qr-code'>
              <ProjectText textcolor="#118799" TextSize="24px" fontweight="700">Reference No.: {this.state.qrRefId}</ProjectText>
              <img src={this.state.qrCodeImg} alt="qrcode" />
              <ProjectText textcolor="#666" TextSize="16px" fontweight="600">Scan the QR code to get payment details</ProjectText>
            </QRCodeContainer>

            )
              :
              <Formik
                validationSchema={() => this.qrCodeSchema()}
                validateOnChange={true}
                initialValues={{
                  wholesaler_name: "",
                  merchant_name: "",
                  amount: "",
                  merchant_employee_name: "",
                  wholesaler_employee_name: "",
                  location: ""
                }}
                validateOnBlur={true}
                onSubmit={(values) => {
                  this.generateQrCode(values)
                }}
                data-test-id="formikId"
              >
                {({ errors, touched, setFieldValue, handleSubmit, values }) =>{
                  return(
                  <form onSubmit={handleSubmit}>
                    <Grid container className="formContainer">
                      {this.renderWholesalerName(setFieldValue, errors, touched, values)}
                      {this.renderMerchantName(setFieldValue, errors, touched, values)}
                      {this.renderAmount(setFieldValue, errors, touched, values)}
                      {this.renderDateAndTime()}
                      {this.renderMerchantEmployeeName(setFieldValue, errors, touched, values)}
                      {this.renderWholesalerEmployeeName(setFieldValue, errors, touched, values)}
                      {this.renderLocation(setFieldValue, errors, touched, values)}
                      <ContainedButton bgcolor="success" className="successBtn" type="submit" onClick={this.handleDate} data-test-id="submit_btn_id">{configJSON.buttonTxt}</ContainedButton>
                    </Grid>
                  </form>
                )
                }
              }
              </Formik>
          }
        </StyledContainer>
      </ProjectSideBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledContainer = styled(Box)({
  marginTop: "40px",
  margin: "auto",
  maxWidth: "620px",
  position: "relative",
  zIndex: 1,
  "& .formContainer": {
    gap: "24px"
  },
  "& form": {
    margin: "0 20px"
  },
  "& .successBtn": {
    width: "100%",
    height: "44px",
    fontWeight: 600,
    fontSize: "16px"
  },
  "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
    right: 0
  },
  "& .react-datepicker__input-container": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    position: "relative",
    background: "transparent"
  },
  "& .react-datepicker__view-calendar-icon input": {
    flex: 1,
    fontSize: "14px",
    borderRadius: "8px",
    height: "100%",
    border: "1px solid #B6CEE2",
    paddingLeft: "14px",
    paddingRight: "14px",
    outline: "none",
    background: "transparent"
  },
  "& .react-datepicker__view-calendar-icon input:hover": {
    borderColor: "#000",
  },
  "& .react-datepicker__view-calendar-icon input:focus": {
    borderColor: "rgb(78,98,182)",
    boxShadow: "1px 1px 0px rgb(78,98,182) inset, -1px -1px 0px rgb(78,98,182) inset ",
  },
  "& .react-datepicker__view-calendar-icon input::placeholder": {
    color: "#666"
  },
  "& .react-datepicker-wrapper": {
    height: "56px",
    width: "100%"
  },
  "& .react-datepicker-popper .react-datepicker__triangle":{
    left: "-21px !important",
  },
  "& .dateAndTime .react-datepicker__view-calendar-icon input": {
    borderColor: "red"
  },
  "& .dateAndTime .react-datepicker__view-calendar-icon input:focus": {
    borderColor: "red",
    boxShadow: "1px 1px 0px red inset, -1px -1px 0px red inset ",
  },
});

const StyledBox = styled(Box)({
  display:'flex',

  marginTop:"30px",
  "& .BackButton": {

    width:"70px",
    height:"20px",

    left: "48px",
    color: "black",
    fontWeight: 600,
    fontFamily:"Montserrat-semibold",
    fontSize: "16px",
    lineHeight:"19.5px",
    textTransform: "unset"
  }
})

const StyledFormControl = styled(Box)({
  position: "relative"
});

const StyledInputLabel = styled(InputLabel)({
  marginBottom: "20px",
  fontSize: "16px",
  fontWeight: 700,
  color: "#031417",
  width: "max-content"
});

const StyledIcon = styled("label")({
  width: "1.5rem",
  height: "1.5rem",
  "& svg": {
    width: "100%",
    height: "100%"
  }
});

const QRCodeContainer = styled(Box)({
  margin: "auto",
  marginTop: "20px",
  maxWidth: "400px",
  "& p": {
    textAlign: "center"
  },
  "& img": {
    maxWidth: "414px",
    height: "412px",
    margin: "30px 0 20px 0",
    display: "inline-block"
  }
});
// Customizable Area End
