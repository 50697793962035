// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import 'react-toastify/dist/ReactToastify.css';
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import Portal from '@material-ui/core/Portal';
import { ToastContainer } from 'react-toastify';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Cfqrgenerator from "../../blocks/cfqrgenerator/src/Cfqrgenerator";
import Customisableuserprofiles2 from "../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre.web";
import FAQ from "../../blocks/helpcentre/src/FAQ.web";
import ContactUs from "../../blocks/helpcentre/src/ContactUs.web";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Location from "../../blocks/location/src/Location";
import Dataencryption from "../../blocks/dataencryption/src/Dataencryption";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import QrCodes from "../../blocks/qrcodes/src/QrCodes";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Invoicebilling3 from "../../blocks/invoicebilling3/src/Invoicebilling3";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import ContactList from "../../blocks/contactlist/src/ContactList";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import Chat from "../../blocks/chat/src/Chat";
import ChatView from "../../blocks/chat/src/ChatView";
import Timetrackingbilling from "../../blocks/timetrackingbilling/src/Timetrackingbilling";
import TermsConditionsWrapper from "../../blocks/termsconditions/src/TermsConditionsWrapper.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
// Web Routes Start From Here
import HomePage from "../../blocks/email-account-login/src/HomePage.web";
import LoginPage from "../../blocks/email-account-login/src/LoginPage.web";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import ForgotPasswordWeb from "../../blocks/forgot-password/src/ForgotPassword.web";
import VerifyingCode from "../../blocks/forgot-password/src/VerifyingCode.web";
import Otp from "../../blocks/otp-input-confirmation/src/Otp.web";
import NewPasswordWeb from "../../blocks/forgot-password/src/NewPassword.web";
import PasswordChanged from "../../blocks/forgot-password/src/PasswordChanged.web";
import Passcode from "../../blocks/passcodelock/src/PasscodeLock.web";
import SignUp from "../../blocks/email-account-registration/src/SignUp.web";
import PasswordCreated from "../../blocks/email-account-registration/src/PasswordCreated.web";
import CreatePasscode from "../../blocks/passcodelock/src/CreatePasscode.web";
import AccountCreate from "../../blocks/rolesandpermissions/src/AccountCreate.web";
import AccountPending from "../../blocks/rolesandpermissions/src/AccountPending.web";
import AccountReactivation from "../../blocks/rolesandpermissions/src/AccountReactivation.web";
import AccountDeactivation from "../../blocks/rolesandpermissions/src/AccountDeactivation.web";
import CreateProfile from "../../blocks/customisableuserprofiles2/src/CreateProfile.web";
import Profile from "../../blocks/customisableuserprofiles2/src/Profile.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web"
import LogoutAlert from "../../components/src/LogoutAlert.web";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { UserContext } from "../../components/src/UserContext";
import QrcodesWeb from "../../blocks/qrcodes/src/QrCodes.web";
import CollectTransactionFees from "../../blocks/collecttransactionfees/src/CollectTransactionFees.web"
import TransactionsDetails from "../../blocks/collecttransactionfees/src/TransactionsDetails.web"
import ForgotPasscode from "../../blocks/passcodelock/src/ForgotPasscode.web";
import ResetPasscode from "../../blocks/passcodelock/src/ResetPasscode.web";
import SettingsResetPasscode from "../../blocks/passcodelock/src/SettingsResetPasscode.web";
import "react-datepicker/dist/react-datepicker.css";


const routeMap = {
  Home: {
    component: HomePage,
    path: '/',
    safe: true,
    exact: true
  },
  Cfqrgenerator: {
    component: Cfqrgenerator,
    path: "/Cfqrgenerator"
  },
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: "/Customisableuserprofiles2"
  },
  Splashscreen: {
    component: Splashscreen,
    path: "/Splashscreen"
  },
  Onboardingguide: {
    component: Onboardingguide,
    path: "/Onboardingguide"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  HelpCentre: {
    component: HelpCentre,
    path: "/HelpCentre"
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: "/HelpCentreQA"
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: "/HelpCentreSub"
  },
  FAQ: {
    component: FAQ,
    path: "/FAQ"
  },
  ContactUs: {
    component: ContactUs,
    path: "/ContactUs"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: "/Contentmanagement3"
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Adminconsole2: {
  component: Adminconsole2,
  path: "/Adminconsole2"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  Location: {
    component: Location,
    path: "/Location"
  },
  Dataencryption: {
    component: Dataencryption,
    path: "/Dataencryption"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  QrCodes: {
    component: QrCodes,
    path: "/QrCodes"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  Invoicebilling3: {
    component: Invoicebilling3,
    path: "/Invoicebilling3"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2"
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  ForgotPassword: {
    component: ForgotPassword,
    safe: true,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    safe: true,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  ContactList: {
    component: ContactList,
    path: "/ContactList"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
    hideForUser: true
  },
  Chat: {
    component: Chat,
    path: "/Chat"
  },
  ChatView: {
    component: ChatView,
    path: "/ChatView"
  },
  Timetrackingbilling: {
    component: Timetrackingbilling,
    path: "/Timetrackingbilling"
  },
  TermsConditions: {
    safe: true,
    component: TermsConditionsWrapper,
    path: "/TermsConditions"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },

 
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },
  Login: {
    component: LoginPage,
    safe: true,
    path: "/Login",
  },
  LandingPage: {
    component: LandingPage,
    safe: true,
    path: "/LandingPage",
  },
  Otp: {
    component: Otp,
    safe: true,
    path: "/Otp",
  },
  ForgotPasswordWeb: {
    component: ForgotPasswordWeb,
    safe: true,
    path: "/ForgotPasswordWeb",
  },
  ForgotPasscode: {
    component: ForgotPasscode,
    path: "/ForgotPasscode",
  },
  ResetPasscode: {
    component: ResetPasscode,
    safe:true,
    path: "/ResetPasscode",
  },
  ConfirmPasscode: {
    component: Passcode,
    path: "/ConfirmPasscode",
  }, 
  SettingsResetPasscode: {
    component: SettingsResetPasscode,
    path: "/SettingsResetPasscode",
  },
  VerifyingCode: {
    component: VerifyingCode,
    safe: true,
    path: "/VerifyingCode",
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    safe: true,
    path: "/NewPasswordWeb",
  },
  PasswordChanged: {
    component: PasswordChanged,
    safe: true,
    path: "/PasswordChanged",
  },
  SignUp: {
    component: SignUp,
    safe: true,
    path: "/SignUp",
  },
  PasswordCreated: {
    component: PasswordCreated,
    safe: true,
    path: "/PasswordCreated",
  },
  CreatePasscode: {
    component: CreatePasscode,
    safe:true,
    path: "/CreatePasscode",
  },
  AccountCreate: {
      component: AccountCreate,
      path:"/AccountCreate",
      hideForUser: true
  },
  AccountPending:{
    component:AccountPending,
    path:"/AccountPending",
    hideForUser: true
  },
  AccountReactivation: {
    component: AccountDeactivation,
    path:"/AccountReactivation",
    hideForUser: true
  },
  AccountDeactivation: {
    component: AccountReactivation,
    path:"/AccountDeactivation",
    hideForUser: true
  },
  CreateProfile: {
    component: CreateProfile,
    safe: true,
    path: "/CreateProfile",
  },
  Profile: {
    component: Profile,
    path: "/Profile",
  },
  ResetPassword:{
    component: ResetPassword,
    path: "/ResetPassword",
  },
  GenerateQrCode: {
    component: QrcodesWeb,
    path: "/GenerateQrCode",
  },
  Transactions:{
    component:CollectTransactionFees,
    path:"/Transactions"
  },
  TransactionsDetails:{
    component:TransactionsDetails,
    path:"/TransactionsDetails"
  },
  Logout: {
    component: LogoutAlert,
    path: "/Logout",
  },

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  constructor() {
    super();
    this.state = { isAuthenticated: null,userType:"" }
  }

  async componentDidMount() {
    window.addEventListener("popstate", async()=> {
    let isAuthenticated = await getStorageData('isAuthenticated');
    let type = await getStorageData("userType")
   
    
   
    if(isAuthenticated) {
      this.setState({ isAuthenticated,userType:type });
    } else {
      this.setState({isAuthenticated: 'authFailed'})
    }
    });

   
    let type = await getStorageData("userType")
  
    let isAuthenticated = await getStorageData('isAuthenticated');
    if(isAuthenticated) {
      this.setState({ isAuthenticated ,userType:type});
    } else {
      this.setState({isAuthenticated: 'authFailed'})
    }
    
     window.addEventListener('keydown', this.handleActivity);
     window.addEventListener('mousemove', this.handleActivity);
     
  }
  async componentDidUpdate(prevProps, prevState) {
    const prevUserType = prevState.userType;
    const currentUserType = await getStorageData("userType");
    if (currentUserType !== prevUserType) {
      this.setState({ userType: currentUserType });
    }
  }
  handleActivity = () => {
    this.resetSessionTimeout();
  };
  resetSessionTimeout = () => {
    clearTimeout(this.logoutTimer);
    this.logoutTimer = setTimeout(() => {
      this.handleSessionExpired();
    }, 1800000); // 30 minutes 
  };

  clearLogoutTimer = () => {
    clearTimeout(this.logoutTimer);
  };

  handleSessionExpired = async () => {
    this.setState({ isAuthenticated: null,userType:"" });
    localStorage.removeItem('authToken');
    localStorage.removeItem('isAuthenticated');
    await removeStorageData("userType")
  };
  cleanupLocalStorage = async () => {
    this.setState({isAuthenticated:null,userType:""})
    localStorage.removeItem('authToken');
    localStorage.removeItem('isAuthenticated')
    await removeStorageData("userType")
  };

  setIsAuth(isAuthenticated) {
    this.setState(isAuthenticated)
  }
  handleData =async () =>{
    let type = await getStorageData("userType")
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100%', backgroundColor:"#fff", overflow: 'auto'}}>
        <Portal>
          <ToastContainer
            autoClose={3000}
            position="top-right"
            className="toast-container"
            toastClassName="dark-toast"
          />
        </Portal>
        <UserContext.Provider value={{isAuth: this.state.isAuthenticated, setIsAuth: this.setIsAuth.bind(this)}}>{this.state.isAuthenticated && WebRoutesGenerator({ routeMap, isAuthenticated: this.state.isAuthenticated,type:this.state.userType })}</UserContext.Provider>
      </View>
    );
  }
}

export default App;
