
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End
 
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  helpCentreQA: any;
  helpCentreNewQA: any;
  token: String;
  queue_type: any;
  dataQA: []
  dataSub: []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HelpCentreController extends BlockComponent<
  Props,
  S,
  SS
> {
  getHelpCentreApiCallId: any
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)];
    this.state = {
      helpCentreQA: [],
      token: "",
      queue_type: "",
      dataQA: [],
      dataSub: [],
      helpCentreNewQA: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recieved", JSON.stringify(message));
    
    switch (message.id) {
      case getName(MessageEnum.SessionResponseMessage):
        await this.handleSessionResponse(message);
        break;
      case getName(MessageEnum.NavigationPayLoadMessage):
        this.handleNavigationPayload(message);
        break;
      case getName(MessageEnum.RestAPIResponceMessage):
        this.handleRestAPIResponse(message);
        break;
      default:
        break;
    }
  
    // Customizable Area End
  }

  // Customizable Area Start

  async handleSessionResponse(message: Message) {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token }, () => {
      this.getHelpCentreQA(token);
      this.getHelpCenterQAs(token);
    });
  }
  
  handleNavigationPayload(message: Message) {
    const subData = message.getData(getName(MessageEnum.HelpCentreMessageData));
    const isQueTitle = subData?.que_title;
    const newState: any = { [isQueTitle ? 'dataSub' : 'dataQA']: subData?.que_array ?? [] };
    this.setState(newState);
  }
  
  handleRestAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorResponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
    switch (apiRequestCallId) {
      case this.getHelpCentreApiCallId:
        this.handleHelpCentreResponse(responseJson, errorResponse);
        break;
      case this.getHelpCentreQAApiCallId:
        this.handleHelpCentreQAResponse(responseJson, errorResponse);
        break;
      default:
        break;
    }
  }
  
  handleHelpCentreResponse(responseJson: any, errorResponse: any) {
    if (responseJson != null) {
      if (!responseJson.errors) {
        console.log(responseJson, 'HelpCentre responseJson');
        this.setState({ helpCentreQA: responseJson.data });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
    this.parseApiCatchErrorResponse(errorResponse);
  }
  
  handleHelpCentreQAResponse(responseJson: any, errorResponse: any) {
    if (responseJson != null) {
      if (!responseJson.errors) {
        this.setState({ helpCentreNewQA: responseJson.data });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    }
  }
  
  getHelpCentreQAApiCallId:any

  getHelpCentreQA = (token: String) => {

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getHelpCentreApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpcentreAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getHelpCenterQAs = async (token:string) => {
    let filter="";
    let navText:string=await storage.get('navText') ?? "FAQ"
    if(navText === "Help Centre Questions"){
      filter = "Help_Center"
    }
    else if(navText === "Contact Us"){
      filter = "Contact_Us"
    }else if(navText === "FAQ"){
      filter = "FAQ"
    }else{
      filter = "Help_Center"
    }
   
   
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHelpCentreQAApiCallId = requestMessage.messageId;
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.helpcenterQAAPIEndPoint + filter
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType 
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
      }

  gotoSubScreen(item:any) {

    const que_type = item?.attributes?.que_type
    const data = item?.attributes?.question_sub_types?.data
  
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'HelpCentreSub');

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    const helpcentreSubInfo = {
      que_title: que_type,
      que_array: data
    }

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.HelpCentreMessageData), helpcentreSubInfo);

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg)

  
  
  }


  gotoHelpCentreQA(sub_type:any, data:[]) {

    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), 'HelpCentreQA');

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );

    const helpcentreSubInfo = {
      sub_type: sub_type,
      que_array: data
    }

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.HelpCentreMessageData), helpcentreSubInfo);

    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(msg);
    }

  // Customizable Area End
}
