// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router";
import { setStorageData } from "../../../framework/src/Utilities";
import { UserContext } from "../../../components/src/UserContext";
export interface Props extends RouteComponentProps {
  id?: string;
}


export const configJSON = require("./config");

interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextBoxColorChange: boolean,
  PasswordError: boolean,
  PassWordErrorText: string
}

interface SS {
  id: string;
}

export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  LoginApi: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "in",
      PhoneInputBorder: false,
      Password: "",
      TextFiledType: false,
      TextBoxColorChange: false,
      PasswordError: false,
      PassWordErrorText: ""
    };
  }


  SetPhoneNumber = (number: string | number, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ Phone: number.toString(), PassWordErrorText: "" });
    setFieldValue("Phone", number.toString());
  }

  SetTextFiledType = () => {
    this.setState({
      TextFiledType: !this.state.TextFiledType
    })
  }

  NavigateToSignUp = () => {
    this.props.history.push({
      pathname: `/SignUp`
    });
  }

  SetPassword = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ Password: e.target.value, PasswordError: false, PassWordErrorText: "" })
    setFieldValue("password", e.target.value);
  }

  NavigateToForgotPassword = () => {
    this.props.history.push({
      pathname: `/ForgotPasswordWeb`
    });
  }



  LoginApiFuncation = async (values: { Phone: string | number, password: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginApi = requestMessage.messageId;
    let Body = {
      "data": {
        "device":"web",
        "attributes": {
          "full_phone_number": `+${values.Phone}`,
          "password": values.password
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_login/logins"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.LoginApi) {
      if (responseJson.errors || responseJson.error) {
        this.LoginResponseHandel(responseJson)
      } else {
        await setStorageData('isAuthenticated', 'authSuccessful');
        await setStorageData("UserData",this.state.Password)
        await setStorageData("userType",responseJson.meta.type)
        this.context.setIsAuth({ isAuthenticated: 'authSuccessful' })
        this.props.history.push({
          pathname: "/ConfirmPasscode"
        })

        this.saveDataInSessionResponse(responseJson);
        await setStorageData("otpScreen", responseJson.meta.refresh_token)
      }
    }
  }

  LoginResponseHandel = (responseJson: { errors: object, error: object, meta: { token: string, pin: string | number } }) => {
    let resposnedata: object = responseJson.errors ?? responseJson.error
    for (const obj of Object.values(resposnedata)) {
      for (const key in obj) {
        if (obj[key] === "Your Password is Incorrect") {
          this.setState({
            PasswordError: true,
            PassWordErrorText: "Your Password is Incorrect"
          })
        } else {
          this.setState({
            PassWordErrorText: obj[key]
          })
        }
      }
    }
  }
  saveDataInSessionResponse(responseJson: any) {

    if (responseJson && responseJson.meta && responseJson.meta.refresh_token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.refresh_token
      );

      this.send(msg);
    }
  }

  phoneInputClassHandler = (values: { Phone: string }, touched: { Phone?: string | boolean }, errors: { Phone?: string }) => {
    const baseClass = 'PhoneInputPackgeClass';

    let className = baseClass;

    if (!values.Phone && !touched.Phone) {
      className="PhoneInputPackgeClass"
    } else if (errors.Phone && touched.Phone) {
      className = 'PhoneInputPackgeClass PhoneInputPackgeClassErr';
    } else if (!errors.Phone) {
      className = 'PhoneInputPackgeClass ColorSuccess';
    }
    return className
  }

}

HomePageController.contextType = UserContext;
// Customizable Area End