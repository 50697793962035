import React from "react";
import { accountsUniversalStyles } from "./consts";
import {
  InputLabel,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

interface Props {
  toDeactivatedName: string;
  isActive: boolean;
  onPress: (employeeName: string) => void;
  buttonName: string;
  disabled?: boolean; 
}
const DivChildStyle=styled('div')({
    width:"338px",
    height: 96,
    borderRadius: 8,
    borderWidth: 1,
    backgroundColor:"#FFFFFF",
    // zIndex:1,
    border: "1px solid #DBE7F0", 
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
    paddingLeft:20,
    paddingRight:20,
  '@media(max-width: 700px)': {
    width:'90%'
  }


})
const EmployeeDisplay: React.FC<Props> = ({
  toDeactivatedName,
  onPress,
  buttonName,
  disabled=false
}) => {
  return (
    <DivChildStyle>
      <div style={accountsUniversalStyles.employeeName}>
        {toDeactivatedName}
      </div>
      <InputLabel  onClick={!disabled ? onPress.bind(this, toDeactivatedName) : undefined}>
        <div style={{
            ...accountsUniversalStyles.employeeButton,
            ...(disabled && { pointerEvents: "none", opacity: 0.5 }), // Apply disabled styles
          }}>
          <span style={accountsUniversalStyles.employeeButtonText}>
            {buttonName}
          </span>
        </div>
      </InputLabel>
    </DivChildStyle>
  );
};

export default EmployeeDisplay;
