// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { RouteComponentProps } from "react-router";
import { removeStorageData } from "../../framework/src/Utilities";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";

export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  isLoading: boolean,
  ErrorText: boolean,
  PasscodeAttempet: number,
  PassCodeFalse: boolean,
}

interface SS {
  id: string;
}

export default class LogoutAlertController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
  }

  logoutHandler() {
    localStorage.clear();
    // this.props.history.push({
    //   pathname: `/Login`
    // })
    // window.location.href='/Login'
    const navigateToLogin = new Message(getName(MessageEnum.NavigationMessage));
    navigateToLogin.addData(getName(MessageEnum.NavigationTargetMessage), "Login");
    navigateToLogin.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToLogin);
  }

  cancelHandler() {
    this.props.history.goBack();
  }

}
// Customizable Area End