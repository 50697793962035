import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    phone: string | number,
    isLinkSent: { [key: string]: string | boolean }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PasscodeLockController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiSendResetPasscodeLinkID = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            phone: "",
            isLinkSent: {},
            // Customizable Area End
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && responseJson.message === "Link successfully send to reset passcode") {
                this.setState({
                    isLinkSent: { message: "Reset passcode link successfully sent to registered pohne number", status: true }
                });
            } else if (responseJson && responseJson.message === "Mobile number is not registered") {
                this.setState({
                    isLinkSent: { message: responseJson.message, status: false },
                });
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (values: string) => {
        let token = await getStorageData("authToken");
        let header = {
            "Content-Type": configJSON.validationApiContentType,
            token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sendResetPasscodeLinkApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "full_phone_number": "+" + values
            })
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.apiSendResetPasscodeLinkID = requestMessage.messageId;
    }

    SignUpSchema = () => {
        return Yup.object().shape({
            phone: Yup.string().required("Phone number is required").min(12, "The phone number you have entered is invalid").max(15, "The phone number you have entered is invalid").nullable(),
        });
    };

    setPhoneNumber = (number: string | number, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        this.setState({
            phone: number.toString(),
            isLinkSent: {}
        });
        setFieldValue("phone", number.toString());
    };
    // Customizable Area End
}
