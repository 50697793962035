// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { RouteComponentProps } from "react-router";
import { toast } from "react-toastify";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";
export interface Props extends RouteComponentProps {
  id?: string;
}


export const configJSON = require("./config");
interface InitialStateValueType{
  Phone:string
}

interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: Array<string> | string,
  CountryCode: string,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  Sendotp: boolean,
  errorText: string,
  isLoadingData:boolean,
  initialStateValue:InitialStateValueType,
  isPhoneThere:boolean
}

interface SS {
  id: string;
}


export default class HomePageController extends BlockComponent<
  Props,
  S,
  SS
> {
  LoginApi: string = "";
  DecodeApiID:string="";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "in",
      PhoneInputBorder: false,
      Password: "",
      TextFiledType: false,
      Sendotp: false,
      errorText: "",
      isLoadingData:true,
      initialStateValue:{
        Phone: "",
      },
      isPhoneThere:false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async componentDidMount(){
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      await this.decodeTokenAPIFunction(token);
    } else {
      this.setState({isLoadingData:false})
    }
  }

  NavigateToForgotPassword = () => {
    this.props.history.push({ pathname: `/ForgotPasswordWeb` });
  }
  NavigateToLogin = () => {
    this.props.history.push({ pathname: `/Login` });
  }

  SetTextFiledType = () => {
    this.setState({ TextFiledType: !this.state.TextFiledType })
  }

  SetPhoneNumber = (number: string | number, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Phone", number.toString());
    this.setState({ Phone: number.toString(), errorText: "" });
  }

  SetPassword = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("password", e.target.value);
    this.setState({ Password: e.target.value })
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  FormSubmit = async (values: { Phone: string }) => {
    this.LoginApiFuncation(values)

  }


  LoginApiFuncation = async (values: { Phone: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.LoginApi = requestMessage.messageId;
    let Body = {
      "data": {
        "attributes": {
          "full_phone_number": values.Phone,
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/send_otps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  decodeTokenAPIFunction = async (UrlToken:string) => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.DecodeApiID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/decode_invite_token?token=${UrlToken}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
 

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.LoginApi) {
      this.LoginApiResponse(responseJson)
    }
    if(apiRequestCallId === this.DecodeApiID){
      this.DecodeApiResponse(responseJson)
    }
  }

  LoginApiResponse = (responseJson: { errors: Array<{[key:string]:string}>, error: object, meta: { token: string, pin: string | number } }) => {
    if (responseJson.errors || responseJson.error) {
      this.setState({
        errorText: responseJson?.errors[0]?.account
      })
      
    } else {
      this.setState({
        Sendotp: true
      })
      setTimeout(() => {
        this.props.history.push({
          pathname: "/Otp",
          state: responseJson.meta.pin
        })
      }, 1500);
      setStorageData("otpScreen", responseJson.meta.token);
      setStorageData("PhoneNumber", this.state.Phone.toString());
      setStorageData("LastPath", JSON.stringify(this.props.location.pathname.toString().toLowerCase()));
    }
  }
  DecodeApiResponse = (responseJson: {
    errors?: Array<{ [key: string]: string }>;
    error?: object;
    meta?: { token: string; pin: string | number };
    temporary_account?: number;
  }) => {

  if (responseJson.errors && responseJson.errors.length > 0) {
    const errorMessage = responseJson.errors.map(err => Object.values(err).join(", ")).join("; ");
    this.setState({
      errorText:errorMessage,
      isLoadingData: false,
      isPhoneThere: true,
    });
  } else if (responseJson.temporary_account) {
    const phoneNumber = responseJson.temporary_account.toString();
    this.setState({
      initialStateValue: {
        Phone: phoneNumber,
      },
      Phone: phoneNumber,
      isPhoneThere: true,
      isLoadingData: false,
      errorText: "",
    });
  } else {
    this.setState({
      errorText: "Invalid response format",
      isLoadingData: false,
      isPhoneThere: false,
    });
  }
}


  
  
  phoneInputClassHandler = (values: { Phone: string }, touched: { Phone?: string | boolean }, errors: { Phone?: string }) => {
    const baseClass = 'PhoneInputPackgeClass';

    let className = baseClass;

    if (!values.Phone && !touched.Phone) {
      className = "PhoneInputPackgeClass"
    } else if (errors.Phone && touched.Phone) {
      className = 'PhoneInputPackgeClass PhoneInputPackgeClassErr';
    } else if (!errors.Phone) {
      className = 'PhoneInputPackgeClass ColorSuccess';
    }
    return className
  }
}
// Customizable Area End