import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
export const configJSON = require("./config");
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End


export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  dateValue: Date | null,
  showQrCode: boolean,
  showDateError: boolean,
  token: string,
  userID: number | null,
  qrCodeImg: string,
  qrRefId: number | null,
  isDateChange: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class QrCodesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getQRCodeApiCallId: string | undefined;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      dateValue: null,
      showQrCode: false,
      showDateError: false,
      token: "",
      userID: null,
      qrCodeImg: "",
      qrRefId: null,
      isDateChange: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();
    const token = await getStorageData("otpScreen");
    const userID = await getStorageData("userID")
    this.setState({
      token,
      userID
    })
    // Customizable Area End
  }

  // Customizable Area Start

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  generateQRCodeApi = (data: any) => {
    const httpBody = {
      data: data
    };

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    this.getQRCodeApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.genrateQRCodeEndPoint}?id=${this.state.userID}&type=AccountBlock::Account`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getQRCodeApiCallId != null &&
      this.getQRCodeApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if ((responseJson && !responseJson.errors && responseJson.data) || (responseJson && responseJson.qr_code)) {
        this.setState({
          qrCodeImg: responseJson.qr_code.data.attributes.qr_code,
          qrRefId: responseJson.reference_number,
          showQrCode: true
        })
      } else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  generateQrCode = (values: object) => {
    const modifiedDate = this.state.dateValue && new Date(this.state.dateValue).toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "UTC"
    }).split("/").join("-");
    const finalObj = { ...values, date_time: modifiedDate }
    this.generateQRCodeApi(finalObj)
  };

  BackButtonOnClick =()=>{
    this.setState({
      showQrCode:false
    })
  }

  handleDate = () => {
    !this.state.isDateChange && this.setState({
      showDateError: true
    })
  }

  onDateChange = (value: Date) => {
    const currentDate = new Date();
    const currentHr = currentDate.getHours();
    const currentMin = currentDate.getMinutes();
    const currentSec = currentDate.getSeconds();
    const selectedDate = new Date(value);

    const modifiedDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), currentHr, currentMin, currentSec);
    this.setState({
      dateValue: modifiedDate,
      showDateError: false,
      isDateChange: true
    })
  };

  qrCodeSchema = () => {
    return Yup.object().shape({
      wholesaler_name: Yup.string().matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter a valid wholesaler name.").min(4, "Please enter minimum 4 letters.").max(30, "Maximum 30 letters are allowed.").required("Wholesaler name is required."),
      merchant_name: Yup.string().matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter a valid merchant name.").min(4, "Please enter minimum 4 letters.").max(30, "Maximum 30 letters are allowed.").required("Merchant name is required."),
      amount: Yup.string().matches(/^\d+(?:\.\d+)?$/, "Enter a valid amount.").required("Amount is required."),
      merchant_employee_name: Yup.string().matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter a valid merchant employee name.").min(4, "Please enter minimum 4 letters.").max(30, "Maximum 30 letters are allowed.").required("Merchant employee name is required."),
      wholesaler_employee_name: Yup.string().matches(/^[A-Za-z]+(?: [A-Za-z]+)*$/, "Enter a valid wholesaler employee name.").min(4, "Please enter minimum 4 letters.").max(30, "Maximum 30 letters are allowed.").required("Wholesaler employee name is required."),
      location: Yup.string().required("Location is required.")
    })
  };
  setWholeSalerName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("wholesaler_name", e.target.value);
  };
  setMerchantName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("merchant_name", e.target.value);
  };

  setAmount = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("amount", e.target.value);
  };

  setMerchantEmployeeName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("merchant_employee_name", e.target.value);
  };

  setWholesalerEmployeeName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("wholesaler_employee_name", e.target.value);
  };

  setLocation = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("location", e.target.value);
  };
  // Customizable Area End
}
