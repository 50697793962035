import React from "react";
import {
    Box,
    Grid,
    InputLabel,
    TextField,
    TextFieldProps,
    Grow,
  } from "@material-ui/core";
  import ProjectText from "./ProjectText";
  import { Country } from "country-state-city";
  import { styled } from "@material-ui/styles";
  interface Props{
      nationality:string;
      onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    errors:any;
  }
const CustomNationalityGrid:React.FC<Props> = ({nationality,onChange,errors}) => {
    return (
      <Grid item xs={12} style={{padding:"0.5px"}}>
        <InputLabel htmlFor="Nationality" style={{fontFamily:'Montserrat-bold' ,fontWeight: 700, color: "black",fontSize:16,marginTop:10,marginBottom:10 }}>
       
            Nationality
      
        </InputLabel>
        <Box style={{ position: "relative" }}>
          <CustomTextField
            id="Nationality"
            size="small"
            error={nationality !== undefined}
            value={
              nationality === ""
                ? "Saudi Arabia"
                : nationality
            }
            select
            inputProps={{
              "data-test-id": "Nationality",
            }}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
            fullWidth
            defaultValue={false}
            onChange={onChange}
         
          >
             <option value="">Nationality</option>
            {Country.getAllCountries().map((option: { name: string ,isoCode:string}) => (
              <option  key={option.name} value={option.isoCode}>
                {option.name}
              </option>
            ))}
          </CustomTextField>
          {errors.nationality && (
            <Grow
              {...(!errors.nationality ? { timeout: 800 } : {})}
              in={errors.nationality !== undefined}
              style={{ transformOrigin: "0 0 0" }}
            >
              <ProjectText type="error">
                {errors.nationality}
              </ProjectText>
            </Grow>
          )}
        </Box>
      </Grid>
    );
  };
  const CustomTextField = styled(TextField)((props: TextFieldProps) => ({
    borderColor: "#B6CEE2",//border: "1px solid #B6CEE2",
    marginBottom: "10px",

    ...(props.error && {
      //borderColor: "red",
      borderColor: "#B6CEE2",
    }),
    "& .Mui-focused": {
      outline: "none",
    },
    "& .MuiInputBase-root":{
      borderRadius: "8px",
      height:"56px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B6CEE2",
      fontFamily:'Montserrat-bold',
      fontSize:'14px',
      fontWeight:700,
   
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "#B6CEE2",
      fontFamily:'Montserrat-bold',
      fontSize:'14px',
      fontWeight:700,
 
    },
    "& .MuiSelect-outlined.MuiSelect-outlined":{
      fontFamily:'Montserrat-bold',
      fontSize:'14px',
      fontWeight:700,

    }
  }));
  export default CustomNationalityGrid;