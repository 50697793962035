// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface ITermsConds {
  data: any;
  id : string;
  attributes:{};
  history:any;
}

export interface Props extends RouteComponentProps {
  id?: string;
}

export interface Data {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  description: string;
  created_at: string;
}

export interface Props {
  navigation: any;
  id?: string;
}

interface S {
  termsConds: ITermsConds | null;
}

interface SS {
  id: string;
}

export default class TermsConditionsWrapperController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTermsCondsCallId: string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      termsConds: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.callTermsConditionsAPI();
  }
  GoBackFunction = () => {
    this.props.history.goBack()
  }
  callTermsConditionsAPI = async () => {
    const authentice = await getStorageData("otpScreen");

    const header = {
      "Content-Type": "application/json",
      token: authentice,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTermsCondsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_terms_and_conditions/terms_and_conditions"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      responseJson &&
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTermsCondsCallId.length > 0 &&
      apiRequestCallId === this.getTermsCondsCallId
    ) {
      this.getTermsCondsCallId = "";
      this.setState({
        termsConds: responseJson.data.attributes.description,
      });
    }
  }
}
// Customizable Area End
