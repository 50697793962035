// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.textApiContentType = "text/plain";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CollectTransactionFees";
exports.labelBodyText = "CollectTransactionFees Body";
exports.collectTransactionFeesEndPoint = "/bx_block_collecttransactionfees/transaction_fees";
exports.transactioListEndPoint ="bx_block_payment_admin/list_transaction_record"
exports.transactioListContentType = "application/json";
exports.transactioListApiMethodType = "GET";
exports.transactionFeesEndPoint = "/bx_block_collecttransactionfees/percentage_index";
exports.actualAmountEndPoint = "/account_block/calulate_transaction_fees";
exports.formDataMethod = "multipart/form-data";
exports.btnExampleTitle = "CLICK ME";

// Customizable Area End
