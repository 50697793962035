import React from "react";
import AccountCreateController from "./AccountCreateController.web";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { withRouter } from "react-router-dom";
import { accountsUniversalStyles } from "./consts";
import ModalCustom from "../../../components/src/ModalCustom";
import EmployeeDisplay from "./AccountEmployeeView";
import { styled } from "@material-ui/core/styles";
export class AccountDeactivation extends AccountCreateController {
  async componentDidMount() {
    this.getToken();
    
  }

  render() {
    if (!this.state.deactivationAccountsList || !this.state.deactivationAccountsList.data) {
      return (
        <ProjectSideBar pageThisVar={this}>
           <div  style={accountsUniversalStyles.container}
          data-test-id="AccountDeactivation">
            <div style={accountsUniversalStyles.topBarText}>
            Deactivated accounts
            </div>           
            <div style={accountsUniversalStyles.topBarTextMembers} id={this.state.deactivationAccountsList}>
            0 MEMBERS
          
            </div>
            <div data-test-id="AccountDeactivation" style={{ width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: 20, marginTop: 25 }}>
            No Deactivated Accounts found
          </div>
          </div>
         
        </ProjectSideBar>
      );
    }
  
    return (
      <ProjectSideBar pageThisVar={this}>
        <div
          style={accountsUniversalStyles.container}
          data-test-id="AccountDeactivation"
        >
          <div>
            <div style={accountsUniversalStyles.topBarText}>
              Deactivated accounts
            </div>

           
            <div style={accountsUniversalStyles.topBarTextMembers} id={this.state.deactivationAccountsList}>
            {this.formatMembersCount(this.state.deactivationAccountsList.data)}
          
            </div>
          </div>
          <DivStyle >
          {this.state.deactivationAccountsList.data.length>0 && this.state.deactivationAccountsList.data.map((item: any, index: any) => (
              <React.Fragment key={index}>
                <EmployeeDisplay
                   toDeactivatedName={item.attributes.full_name || "N/A"}
                   isActive={item.attributes.activated}
                   onPress={this.toggleModal.bind(
                     this,
                     item.attributes.full_name,
                     item.id
                   )}
                   buttonName="Reactivation"
                 
                />
              </React.Fragment>
            )
            )}
          </DivStyle>

         
        </div>
        <ModalCustom
            visible={this.state.isModalVisible}
            onClose={this.toggleModal.bind(this, "", null)}
            onConfirm={this.reactivateEmployeeAccount.bind(
              this,
              this.state.selectedEmployeeId
            )}
            workerName={this.state.selectedWorkerName}
            confirmText="REACTIVATE ACCOUNT"
            confirmed={this.state.changeModalViewReactivation}
            charRole="r"
           
           
          />
      </ProjectSideBar>
    );
  }
}

export default withRouter(AccountDeactivation);

const DivStyle=styled('div')({
  display: "flex",
  flexWrap:"wrap",
  marginTop:16,
  marginBottom:30,
  gap:"25px",
  '@media(max-width: 750px)': {
    marginBottom: 100,
    flexDirection:'column'
  }
})
