// Customizable Area Start
import * as React from "react";
const FristSqure = require("./LoginTop.png");
const SecondSqure = require("./LoginBottom.png");
const AlphaLogo = require("./AppLogo.png");
const rightSideWithPhone = require("./center.png");
const PasswordLayoutleftDown = require("./PasswordLayoutleftDown.png");
const PasswordLayoutleftTop = require("./PasswordLayoutleftTop.png");
const PasswordLayoutCenter = require("./PasswordLayoutCenter.png");
import { groupImagePasswordSuccess } from "../../blocks/forgot-password/src/assets";
const PasswordLayoutRightTop = require("./PasswordLayoutRightTop.png");
const PasswordLayoutRing = require("./PasswordLayoutRing.png");
const PasswordLayoutRightCenter = require("./PasswordLayoutRightCenter.png");
import { Box, Grid, Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ContainedButton } from "./GlobalButton";
import ProjectText from "./ProjectText";

const drawerWidth = 240;
interface PermanentDrawerLeftProps {
  children?: React.ReactNode;
  BackButton?: boolean;
  BackButtonOnClick?: () => void;
  loader?: boolean;
  Buttontext?: string
  ButtonFuncation?: () => void;
  Descripetion?: string
}

export default function PassWordResetLayout({
  children,
  BackButton,
  BackButtonOnClick,
  Buttontext,
  ButtonFuncation,
  Descripetion
}: PermanentDrawerLeftProps) {
  return (
    <LoginPageStyle>
      <Box className="MainContainer">
        <div className="firstSqureDiv">
          <img src={FristSqure} alt="FristSqure" />
        </div>
        <Box className="LoginFormContainer">
          <Box>
            <Grid container>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Box className="logoContainer">
                  {BackButton && <Button onClick={BackButtonOnClick} className="BackButton" startIcon={<ArrowBackIcon />}>Back</Button>}
                  <img src={AlphaLogo} />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Box className="boyImage">
                  <div className="PasswordLayoutleftTop">
                    <img src={PasswordLayoutleftTop} alt="PasswordLayoutleftTop" />
                  </div>
                  <img src={groupImagePasswordSuccess} />
                  <div className="PasswordLayoutRightTop">
                    <img src={PasswordLayoutRightTop} alt="PasswordLayoutRightTop" />
                  </div>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Box>
                  <ProjectText variant="h4" className="DescripionText" gutterBottom align="center" fontfamily="Montserrat-Bold" textcolor="#031417" fontweight="700">
                    {Descripetion}
                  </ProjectText>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
                <Box className="BtnContainer">
                  <div className="PasswordLayoutCenter">
                    <img src={PasswordLayoutCenter} alt="PasswordLayoutCenter" />
                  </div>
                  <ContainedButton bgcolor="success" style={{
                    fontSize: "16px", borderRadius: "8px", width: '220px',
                    height: '56px',
                    padding: '16px'
                  }} size="medium" onClick={ButtonFuncation} data-testid="loginbutoon">
                    {Buttontext}
                  </ContainedButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
          <div className="PasswordLayoutleftDown">
            <img alt="PasswordLayoutleftDown" src={PasswordLayoutleftDown} />
          </div>
        <div className="SceondSqureDiv">
          <img src={SecondSqure} alt="FristSqure" />
        </div>
        <div className="PasswordLayoutRightCenter">
          <img src={PasswordLayoutRightCenter} alt="PasswordLayoutRightCenter" />
        </div>
        <div className="BottomConainer">
          <div className="PasswordLayoutRing">
            <img src={PasswordLayoutRing} alt="PasswordLayoutRing" />
          </div>
        </div>
      </Box>
    </LoginPageStyle>
  );
}
const LoginPageStyle = styled(Box)({
  height: "100%",
  position: "relative",
  "@media (max-width: 1450px)": {
    height: "inherit",
  },
'& .MainContainer': {
    display: "flex", justifyContent: "center", alignItems: "center",
    "@media (max-width: 550px)": {
      padding: "0px"
    },
    "@media (max-width: 800px)": {
      padding: "0px"
    },
  },
  "& .LoginFormContainer": {
    display: "flex", justifyContent: "center", alignItems: "center", height: '100%',
    paddingTop: "48px",
    boxSizing: "border-box"
  },
  '& .DescripionText': {
    fontSize: "24px",
    fontFamily:"Montserrat-Semibold",
    lineHeight: "32px",
    margin: "48px 0 40px 0"
  },
  '& .ImgContainer': {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px",
    marginBottom: "10px"
  },
  '& .BtnContainer': {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    "& .PasswordLayoutCenter": {
      left: "480px",
    bottom: "-2px",
    position: "fixed",
      '& img': {
        width: "unset",
        height: "unset",
        margin: "unset",
      }
    }
  },
  "& .firstSqureDiv": {
    position: "fixed",
    top: '0',
    right: "0",
    '& img': {
      objectPosition: "center",
      objectFit: "contain",
      height: "104px",
      "@media (max-width: 770px)": {
        display: "none"
      }
    },
  },
  "& .SceondSqureDiv": {
    position: "fixed",
    bottom: '0',
    left: "0",
    '& img': {
      width: "100%",
      height: "104px",
      verticalAlign: "middle",
      "@media (max-width: 800px)": {
        display: "none"
      }
    },
  },
  '& .boyImage': {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    position: "relative",
    "@media (max-width: 1450px)": {
      margin: "unset"
    },
    '& img': {
      margin: "auto",
      width: '513px',
      height: '382px',
      objectFit: "contain",
      "@media (max-width: 1200px)": {
        maxWidth: "80%"
      }
    },
    "& .PasswordLayoutleftTop": {
      position: "fixed",
      left: "80px",
      bottom: "106px",
      '& img': {
        width: "unset",
        height: "unset",
        margin: "unset",
      }
    },
    "& .PasswordLayoutRightTop": {
      position: "fixed",
      right: "340px",
      top: "340px",
      '& img': {
        width: "unset",
        height: "unset",
        margin: "unset",
      }
    },
  },
  "& .logoContainer": {
    display: "flex",
    justifyContent: "center",
    '& img': {
      height: "100%",
      "@media (min-width:360px) and (max-width: 449px)": {
        display:"none"
      },
      "@media (min-width:450px) and (max-width: 1280px)": {
        display:"none"
      },
    },
    "@media (max-width: 500px)": {
      textAlign: "center",
      marginBottom: "10px"
    },
    "@media (max-width: 800px)": {
      position: "unset",
      marginBottom: "10px"
    },
    "& .BackButton": {
      position: "absolute",
      top: "24px",
      left: "36px",
      color: "black",
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "unset"
    }
  },
  "& .PasswordLayoutleftDown": {
    position: "fixed",
    top: "290px",
    left: "170px",
  },
  "& .PasswordLayoutRing": {
    position: "fixed",
    right: "0px",
    bottom: "0px",
    "& img":{
      height: "240px"
    },
    "@media (max-width: 800px)": {
        display: "none"
      }
  },
  "& .PasswordLayoutRightCenter": {
    position: "fixed",
    right: "10px"
  }
})
// Customizable Area End