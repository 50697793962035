import React, { useEffect, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import { IconButton, useMediaQuery } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import { styled } from "@material-ui/styles";
import ProjectText from './ProjectText';
import { RouteComponentProps } from "react-router-dom";
// Icons Import
import HomeIcon from './HomeIcon';
import QRIcon from "./QRIcon";
const SideBarLogo = require("./SidebarLogo.png");
import SettingIcon from "./SettingIcon";
import TransactionsIcon from "./TransactionsIcon";
import WalletIcon from "./WalletIcon";
import { AccountcreationIcon } from './AccountcreationIcon';
import { ProfileIcon } from './ProfileIcon';
import { PasscodeIcon } from './PasscodeIcon';
import { PasswordIcon } from './PasswordIcon';
import { TermsconditionsIcon } from './Terms&conditionsIcon';
import { HelpcenterIcon } from './HelpcenterIcon';
import { LogoutIcon } from './LogoutIcon';
import { CreateAccountIcon } from './CreateAccountIcon';
import { DeactivationIcon } from './DeactivationIcon';
import { ReactivationIcon } from './ReactivationIcon';
import { HelpCentreQuestion } from './HelpCentreQuestion';
const RectangleTop = require("./RectangleTop.png");
const RectangleBottom = require("./RectangleBottom.png");
const SideBarbottomRightIcon = require("./SideBarbottomRightIcon.png");
const drawersqure = require("./drawersqure.png");
const formleftsidesqure = require("./formleftsidesqure.png");
const ringinnerSqeure = require("./ringinnerSqeure.png");
const formtopsqure = require("./formtopsqure.png");
const formrightsidesqure = require("./formrightsidesqure.png");
const formbottomsqure = require("./formbottomsqure.png");
import { withRouter } from "react-router-dom";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import storage from '../../framework/src/StorageProvider';
import { ClippingRectangle } from 'react-native';
import { getStorageData } from '../../framework/src/Utilities';
import _ from "lodash";
import MessageEnum, { getName } from '../../framework/src/Messages/MessageEnum';
import { Message } from '../../framework/src/Message';

const drawerWidth = 240;



interface PageThisVar {
  props: object;
  send: (message: Message) => void;
}
interface ProjectSideBarProps extends RouteComponentProps {

  children: React.ReactNode;
  props?: RouteComponentProps | undefined;
  pageThisVar?:PageThisVar;
  extraContent?: { tabs: string[]; activeTab: number; tabHandler: (activeTab: number) => void }
  childComponentTitle?: string
}
const pendingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#999999">
    <path d="M10 4C11.0609 4 12.0783 4.42143 12.8284 5.17157C13.5786 5.92172 14 6.93913 14 8C14 9.06087 13.5786 10.0783 12.8284 10.8284C12.0783 11.5786 11.0609 12 10 12C8.93913 12 7.92172 11.5786 7.17157 10.8284C6.42143 10.0783 6 9.06087 6 8C6 6.93913 6.42143 5.92172 7.17157 5.17157C7.92172 4.42143 8.93913 4 10 4ZM10 14C14.42 14 18 15.79 18 18V20H2V18C2 15.79 5.58 14 10 14ZM20 12V7H22V13H20M20 17V15H22V17H20Z" fill="#999999" />
  </svg>
)
const AppSideBarFirst: MenuItem[] = [
  { text: 'Dashboard', icon: <HomeIcon />, width: 1, path: "/Dashboard" },
  { text: 'QR Code Generator', icon: <QRIcon />, width: 1, path: "/GenerateQrCode" },
  { text: 'Transactions', icon: <TransactionsIcon />, width: 1, path: "/Transactions" },
  { text: 'Wallet', icon: <WalletIcon />, width: 1 },
  {
    text: "Settings", icon: <SettingIcon />, SubMenu:
      [
        {
          text: "Account Creation",
          icon: <AccountcreationIcon />,
          width: 2,
          ThirdSide: [
            {
              text: "Create Account",
              icon: <CreateAccountIcon />,
              Path: "/AccountCreate",
              width: 3
            },
            {
              text: "Deactivation",
              icon: <DeactivationIcon />,
              Path: "/AccountDeactivation",
              width: 3
            },
            {
              text: "Reactivation",
              icon: <ReactivationIcon />,
              Path: "/AccountReactivation",
              width: 3
            },
            {
              text: "Pending Account",
              icon: pendingIcon(),
              Path: "/AccountPending",
              width: 3
            },

          ]
        },
        {
          text: "Profile",
          icon: <ProfileIcon />,
          width: 2,
          Path: "/Profile",
        },
        {
          text: "Reset Passcode",
          icon: <PasscodeIcon />,
          Path: "/SettingsResetPasscode",
          width: 2
        },
        {
          text: "Reset Password",
          icon: <PasswordIcon />,
          Path: "/ResetPassword",
          width: 2
        },
        {
          text: "Terms & Conditions",
          icon: <TermsconditionsIcon />,
          width: 2,
          Path: "/TermsConditions"
        },
        {
          text: "Need Help?",
          icon: <HelpcenterIcon />,
          width: 2,
          ThirdSide: [
            {
              text: "Help Centre Questions",
              icon: <HelpCentreQuestion />,
              Path: "/HelpCentre",
              width: 3
            },
            {
              text: "FAQ",
              icon: <HelpCentreQuestion />,
              Path: "/FAQ",
              width: 3
            },
            {
              text: "Contact Us",
              icon: <ReactivationIcon />,
              Path: "/ContactUs",
              width: 3
            },
          ]
        },
        {
          text: "Logout",
          icon: <LogoutIcon />,
          width: 2,
          Path: "/Logout"
        },
      ],
    width: 1
  },
  {
    text: "Notifications", icon: <NotificationsOutlinedIcon />, width: 1,path:"/Notifications"
  }
]




interface MenuItem {
  text: string;
  icon: JSX.Element;
  SubMenu?: SubMenuItem[];
  path?: string,
  width?: number,
  ThirdSide?: SubMenuItem
}
interface SubMenuItem {
  text: string;
  icon?: JSX.Element;
  ThirdSide?: SubMenuItem[];
  Path?: string,
  width?: number,
  SubMenu?: SubMenuItem
}

function ProjectSideBar({
  children,
  location,
  history,
  match,
  extraContent,
  childComponentTitle,
  pageThisVar,

}: ProjectSideBarProps) {
  
  const [Width, setWidth] = useState(1)
  const [MenuOpenText, setMenuOpenText] = useState('')
  const [SecondDrawer, setSecondDrawer] = useState(false)
  const initialSubMenu: SubMenuItem[] = [];
  const [SecondDrawerData, setSecondDrawerData] = useState<SubMenuItem[] | undefined>(initialSubMenu);
  const [ThirdDrawer, setThirdDrawer] = useState(false)
  const [ThirdDrawerText, setThirdDrawerText] = useState("")
  const [ThirdDrawerData, setThirdDrawerData] = useState<SubMenuItem[] | undefined>(initialSubMenu)
  const [activeSecondDrawerText, setActiveSecondDrawerText] = useState("");
  const [activeThirdDrawerText, setActiveThirdDrawerText] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [toggleFirstDropdown, setToggleFirstDropdown] = useState(false);
  const [toggleSecondDropdown, setToggleSecondDropdown] = useState(false);
  const [secondMenuOpenText, setSecondMenuOpenText] = useState("");
  const [isAccountCreationOpen, setIsAccountCreationOpen] = useState(false);
  const [isNeedHelpOpen, setIsNeedHelpOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [AppSideBarFirstFilter,setAppSideBarFirstFilter] = useState<MenuItem[]>(AppSideBarFirst)
  const isMobile = useMediaQuery('(max-width: 700px');

  const getUserType= async () =>{
    const userType = await getStorageData("userType");
    return userType
  }
  

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  }


  const handleAccountCreationClick = () => {
    setIsNeedHelpOpen(false);
    setIsAccountCreationOpen((true));  
  }

  const handleNeedHelpClick = () => {
    setIsAccountCreationOpen(false); 
    setIsNeedHelpOpen(true);
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        position: "relative"
      },
      appBar: {
        width: `calc(100% - ${drawerWidth * Width}px)`,
        marginLeft: drawerWidth,
        transition: 'width 0.99s ease',
        backgroundColor: "#fff",
        boxShadow: "none",
        borderBottom: "1px solid #DBE7F0",
        zIndex: 2,
        color: "black",
        "@media(max-width: 1200px)": {
          width: `${SecondDrawer && "calc(100% - 480px)"}`
        },
   
        "@media(max-width: 700px)": {
          width: "100%",
         
        }
      },
      drawer: {
        width: drawerWidth,
        '@media (max-width: 700px)': {
          width: '100%',
          overflowX: 'hidden',
        },
        flexShrink: 0,
      },
      drawerPaper: {
        width: drawerWidth,
        '@media (max-width: 700px)': {
          width: '100%',
        }
      },

      SidebarList: {
        '@media (max-width: 414px)': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '50%'
        }
      },
     // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      customToolbar: {
        display: "flex",
        '@media (max-width: 950px)': {
        
        },
        '@media (max-width: 700px)': {
          marginLeft: '0px'
        },
        '@media (max-width: 500px)': {
         
          margin: '15px 0'
        }
      },
      customExtraContentToolbar:{
        
        display: "flex",
        width:"100%",
       
        '@media (max-width: 700px)': {
         
          paddingLeft:"30px",
        },
        '@media (max-width: 500px)': {
          flexDirection: 'column',
          alignItems: 'flex-start',
        
        }
      },
      secondDrawerTitle: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "12px",
        fontWeight: 700,
        fontSize: "24px",
        color: "#666",
        fontFamily: "Montserrat-Bold",
        lineHeight: "32px"
      },
      content: {
        flexGrow: 1,
        backgroundColor: "transparent",
        height: "calc(100vh - 64px)",
        marginTop: "64px",
       
      },
      drawerButton:{
        
        zIndex: 5,
       
       
      }
    }),
  );
  useEffect(() => {
    const filterMenuItems = async () => {
      const userType = await getUserType();
      const deep = _.cloneDeep(AppSideBarFirst)
      const items:MenuItem[] = deep.filter((item:MenuItem )=> {
        if (userType === 'EmployeeAccount' && item.text === 'Dashboard') {
          return false;
        }
        if (userType === 'EmployeeAccount' && item.text === 'Settings' && item.SubMenu) {
          item.SubMenu = item.SubMenu.filter((subItem) => subItem.text !== 'Account Creation');
          if (item.SubMenu.length === 0) {
            return false;
          }
        }
        return true;
      });
      setAppSideBarFirstFilter(items);
    };
    filterMenuItems();
  }, []);

  type ExtractedInfo = { width?: number; path?: string, text?: string, SubMenu?: SubMenuItem[], thirdMenu?: SubMenuItem[], pratentmenuText?: string, MainMenu?: string };

  function extractWidthAndPath(arr: MenuItem[]): ExtractedInfo[] {
    const extractedInfo: ExtractedInfo[] = [];

    for (const item of arr) {
      if (item.width !== undefined) {
        extractedInfo.push({ width: item.width, text: item.text, path: item.path, MainMenu: item.text });
      }
      if (item.path !== undefined) {
        extractedInfo.push({ width: item.width, text: item.text, path: item.path, MainMenu: item.text });
      }
      if (item.SubMenu !== undefined) {
        extractedInfo.push({ width: item.width, text: item.text, path: item.path, SubMenu: item.SubMenu, MainMenu: item.text });
      }
      if (item.SubMenu) {
        for (const Submenu of item.SubMenu) {
          if (Submenu.width !== undefined) {
            extractedInfo.push({ width: Submenu.width, text: Submenu.text, path: Submenu.Path, SubMenu: item.SubMenu, thirdMenu: Submenu.ThirdSide, pratentmenuText: item.text, MainMenu: item.text });
          }
          if (Submenu.ThirdSide) {
            for (const ThirdMenu of Submenu.ThirdSide) {
              if (ThirdMenu.text !== undefined && (ThirdMenu.text === 'Help Centre Questions' || ThirdMenu.text === 'FAQ' || ThirdMenu.text === 'Contact Us')) {
                extractedInfo.push({ width: ThirdMenu.width, text: 'Need Help?', path: ThirdMenu.Path, SubMenu: item.SubMenu, thirdMenu: Submenu.ThirdSide, pratentmenuText: Submenu.text, MainMenu: item.text });
              }
              else if (ThirdMenu.text !== undefined) {
                extractedInfo.push({ width: ThirdMenu.width, text: ThirdMenu.text, path: ThirdMenu.Path, SubMenu: item.SubMenu, thirdMenu: Submenu.ThirdSide, pratentmenuText: Submenu.text, MainMenu: item.text });
              }
            }
          }
        }
      }
    }

    return extractedInfo;
  }

  const extractedInfo: ExtractedInfo[] = extractWidthAndPath(AppSideBarFirstFilter);
  const classes = useStyles();

  


  useEffect(() => {

    const data = extractedInfo.filter((item: ExtractedInfo) => {
      if (item.path?.includes(`${location.pathname}`)) {
        return item
      }
    })
    if (data.length > 0) {
      if (data[0].text) {
        setThirdDrawerText(`${data[0].text}`)
        setMenuOpenText(`${data[0].MainMenu}`)
        setSecondMenuOpenText(`${data[0].MainMenu}`)
      }
      if (data[0].SubMenu) {
        setSecondDrawerData(data[0].SubMenu);
        setSecondDrawer(true);
        setToggleFirstDropdown(true);
        setActiveSecondDrawerText(`${data[0].pratentmenuText}`)
      }
      if (data[0].thirdMenu) {
        setThirdDrawerData(data[0].thirdMenu)
        setThirdDrawer(true)
        // setToggleSecondDropdown(true)
        setThirdDrawerText(`${data[0].pratentmenuText}`)
        setActiveThirdDrawerText(`${data[0].text}`)
      }
      setPageTitle(`${data[0].text}`)
      setWidth(Number(data[0].width))
    }
  }, [AppSideBarFirstFilter])
  useEffect(() => {
    setWidth((SecondDrawer && ThirdDrawer) ? 3 : SecondDrawer ? 2 : 1);
    const data = extractedInfo.filter((item: ExtractedInfo) => {
      if (item.path?.includes(`${location.pathname}`)) {
        return item
      }
    })
    if (!SecondDrawer) {
      if (data.length > 0) {
        if (data[0].text) {
          setMenuOpenText(`${data[0].MainMenu}`)
        }
      }
    }
    if (!ThirdDrawer && !data[0].thirdMenu) {
      if (data.length > 0) {
        if (data[0].text) {
          setThirdDrawerText(`${data[0].text}`);
          setActiveSecondDrawerText(`${data[0].text}`)
        }
      }
    }
  }, [SecondDrawer, ThirdDrawer,AppSideBarFirstFilter])


  useEffect(() => {
    setThirdDrawer((SecondDrawer && activeThirdDrawerText && toggleSecondDropdown) ? true : false)
  }, [SecondDrawer])
  const SetSecondDrawerSet = (item: MenuItem) => {
    setMenuOpenText(item.text);
    const data = extractedInfo.filter((item: ExtractedInfo) => {
      if (item.path?.includes(`${location.pathname}`)) {
        return item
      }
    })
    if (data.length > 0) {
      if (data[0].text) {
        setThirdDrawerText(`${data[0].pratentmenuText}`);
        setActiveSecondDrawerText(`${data[0].pratentmenuText}`)
      }
    }
    if (item.SubMenu) {
      setSecondDrawerData(item.SubMenu)
      setSecondDrawer(!SecondDrawer);
      setToggleFirstDropdown(!toggleFirstDropdown);
      setSecondMenuOpenText(item.text);
    } else {
      setSecondDrawer(false);
      setThirdDrawer(false);
      setToggleFirstDropdown(false);
      setToggleSecondDropdown(false);
      setActiveSecondDrawerText("");
      setThirdDrawerText("");
      setActiveThirdDrawerText("")
    }
    DrawerActions(item.text);
  }

  function navigtionToRoute(navigate: string): void {
    if (pageThisVar) {
    const navigateToPage = new Message(getName(MessageEnum.NavigationMessage));
    navigateToPage.addData(getName(MessageEnum.NavigationTargetMessage), navigate);
    navigateToPage.addData(getName(MessageEnum.NavigationPropsMessage), pageThisVar.props);
    pageThisVar.send(navigateToPage);
    }
    else{
      alert("something wrong")
    }
  }
 


  const DrawerActions = (selectedValue: string) => {
    switch (selectedValue) {
      case "Logout":
        navigtionToRoute(`Logout`)
        break;
      case "Terms & Conditions":
        navigtionToRoute(`TermsConditions`)
        break;
      case "Dashboard":
        navigtionToRoute(`Dashboard`)
      
        break;
        case "QR Code Generator":
        navigtionToRoute(`GenerateQrCode`)
      
        break;
      case "Transactions":
        navigtionToRoute(`Transactions`)
        break;
      case "Wallet":
        navigtionToRoute(`Transactions`)
        break;
      case "Profile":
        navigtionToRoute(`Profile`)
        break;
      case "Notifications":
        navigtionToRoute(`Notifications`)
        break;
      case "Reset Passcode":
        navigtionToRoute(`SettingsResetPasscode`)
        break;
      case "Reset Password":
        navigtionToRoute(`ResetPassword`)
        break;
      // case "Help Center":
      //   history.push({
      //     pathname: `/HelpCenter`
      //   });
      // break;

    }
  }
  const SetThirdDrawerSet = (item: SubMenuItem) => {

    if (item.text) {
      setThirdDrawerText(item.text)
      setThirdDrawer(false)
      setToggleSecondDropdown(false)
      setActiveSecondDrawerText(item.text)
    }
    if (item.ThirdSide) {
      setThirdDrawerData(item.ThirdSide)
      setThirdDrawer(!ThirdDrawer)
      setThirdDrawerText(item.text)
    }
    DrawerActions(item.text);
  }

  const ThirdDrawerSet = (item: SubMenuItem) => {
    storage.set('navText', item.text)
    if (ThirdDrawerText === item.text) {
      setWidth(3)
 
    } else {
      setThirdDrawer(true)
      let newString = (item.Path?.replace("/", "")) ?? "";
      navigtionToRoute(newString);
      
      
    }
  }
  const handleSecondDrawerDropdown = (dropdown: string) => {
    setActiveSecondDrawerText(dropdown);
    setToggleSecondDropdown(!toggleSecondDropdown)
  }
  const renderThirdDropdown = () => {
    return ThirdDrawerData?.map((item: SubMenuItem) => (
      <ListItem button key={item.text} style={{ paddingLeft: "8px", paddingRight: "8px", paddingTop: "15px", paddingBottom: "15px", }} onClick={(event) => {
        event.stopPropagation();
        ThirdDrawerSet(item);
        handleAccountCreationClick();
      }}>
        <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
          <div style={{ minWidth: "30px", display: "flex", justifyContent: "center", alignItems: "center" }} className={activeThirdDrawerText === item.text ? "DrawerIconActive" : "SecondDrawerIcon"}>
            {item.icon}
          </div>
          <ProjectText variant='subtitle2' TextSize="16px" fontfamily={activeThirdDrawerText === item.text ? "Montserrat-Bold" : "Montserrat-SemiBold"} textcolor={activeThirdDrawerText === item.text ? "#118799" : "#999"} fontweight={ThirdDrawerText === item.text ? "700" : "600"}>
            {item.text}
          </ProjectText>
        </div>
      </ListItem>
    ))
  }
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetElement = event.target as Element | null;
      if (targetElement && !targetElement.closest(".secondDrawerDropdwon") && targetElement && !targetElement.closest(".firstDrawerDropdwon")) {
        if (document.getElementsByClassName("secondDrawerDropdwon")[0]) {
          const secondDrawer = window.getComputedStyle(document.getElementsByClassName("secondDrawerDropdwon")[0]);
          const secondDrawerValue = secondDrawer.getPropertyValue('display');
          if (secondDrawerValue === "block") {
            setToggleSecondDropdown(false);
            setThirdDrawer(false)
          }
        }
      }
      if (targetElement && !targetElement.closest(".firstDrawerDropdwon")) {
        if (document.getElementsByClassName("firstDrawerDropdwon")[0]) {
          const firstDrawer = window.getComputedStyle(document.getElementsByClassName("firstDrawerDropdwon")[0]);
          const firstDrawerValue = firstDrawer.getPropertyValue('display');
          if (firstDrawerValue === "block") {
            const data = extractedInfo.filter((item: ExtractedInfo) => {
              if (item.path?.includes(`${location.pathname}`)) {
                return item
              }
            });
            setMenuOpenText(`${data[0].MainMenu}`);
            setSecondDrawer(false);
            setToggleFirstDropdown(false);
            setActiveSecondDrawerText("");
            setThirdDrawerText("")
          }
        }
      }
    };

    if (toggleSecondDropdown) {
      window.addEventListener("mousedown", handleOutsideClick);
    }

    if (toggleFirstDropdown) {
      window.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      window.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [toggleSecondDropdown, toggleFirstDropdown]);
  return (
    <SideBarStyle className={classes.root}>
      {
        !extraContent &&
        <div className="firstSqureDiv">
          <img src={RectangleTop} alt="FristSqure" />
        </div>
      }
      <CssBaseline />
      <div style={{ display: "flex" }}>
        <AppBar position="fixed" className={classes.appBar}>
     
          <Toolbar className={classes.customToolbar}>
          { isMobile && !sidebarOpen && ( <div className={classes.drawerButton} >
              <IconButton
                color="inherit"
                aria-label='open drawer'
                edge="start"
                onClick={toggleSidebar}>

                <MenuIcon />
              </IconButton>
            </div>
         )}
            <div className={classes.customExtraContentToolbar}>
            <Typography variant="h6" noWrap className="pageTitle">
              {childComponentTitle ? childComponentTitle : pageTitle}
            </Typography>
            {
              extraContent && <StyledTabContainer>
                {
                  extraContent.tabs.map((tab: string, idx: number) => idx === extraContent.activeTab ? <ProjectText TextSize="18px" fontweight="700" textcolor="#118799">{tab}</ProjectText> : <ProjectText TextSize="18px" fontweight="700" textcolor="#1A1A1A" onClick={() => extraContent.tabHandler(idx)}>{tab}</ProjectText>)
                }
              </StyledTabContainer>
            }
            </div>
          </Toolbar>
        </AppBar>
        {
          isMobile && !sidebarOpen ? null : (

            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
              style={{
                zIndex: 100
              }}
              anchor="left"
              open={sidebarOpen}
              onClose={toggleSidebar}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className={`${classes.toolbar} SideBarLogo`}>
                  <img src={SideBarLogo} />
                </div>
             

               
              {
                isMobile &&
                sidebarOpen && (
                  <div style={{ marginRight: '30px', cursor: 'pointer', display: 'flex', justifyContent: 'flex-end' }} onClick={() => toggleSidebar()}>
                    <CloseIcon />
                  </div>
                )
              }
              </div>
              <Box className='SidebarContainer'>
                <List className='SidebarList'>
                  {AppSideBarFirstFilter?.map((item: MenuItem) => {
                    return (
                      <ListItem style={{ paddingLeft: "8px", paddingRight: "8px", paddingTop: "15px", paddingBottom: "15px", position: "relative" }} button key={item.text} onClick={() => {
                        SetSecondDrawerSet(item)
                      }
                      }>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", width: "100%" }}>

                          <div style={{ display: "flex", alignItems: "center", gap: "6px", width: "100%" }}>
                            <div className={MenuOpenText === item.text ? "DrawerIconActive" : "FristDrawerIcon"}>
                              {item.icon}
                            </div>
                            <ProjectText variant='subtitle2' fontfamily="Montserrat-SemiBold" TextSize="16px" fontweight="600" style={{ color: MenuOpenText === item.text ? "#118799" : "#333", }}>
                              {item.text}
                            </ProjectText>
                          </div>
                          <div style={{ width: "100%" }}>

                            {
                              (item.SubMenu && toggleFirstDropdown) && <FirstdDropdownList className="firstDrawerDropdwon">
                                {SecondDrawerData?.map((secondItem: SubMenuItem) => (
                                  <ListItem style={{ display: "flex", flexDirection: "column", paddingLeft: "8px", paddingRight: "8px", paddingTop: "15px", paddingBottom: "15px", borderLeft: ThirdDrawerText === secondItem.text ? '4px solid #118799' : 'none', backgroundColor: ThirdDrawerText === secondItem.text ? '#E8FAFC' : 'transparent', position: "relative" }} button key={secondItem.text}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      SetThirdDrawerSet(secondItem)
                                      secondItem.ThirdSide && handleSecondDrawerDropdown(secondItem.text)
                                      if (secondItem.text === 'Account Creation') {
                                        handleAccountCreationClick();
                                      } else if (secondItem.text === 'Need Help?') {
                                        handleNeedHelpClick();
                                      }
                                    }
                                    }

                                  >
                                    <div style={{ display: "flex", alignItems: "center", width: "100%", gap: "6px" }}>
                                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className={ThirdDrawerText === secondItem.text ? "DrawerIconActive" : "SecondDrawerIcon"}>
                                        {secondItem.icon}
                                      </div>
                                      <ProjectText variant='subtitle2' TextSize="16px" fontfamily={ThirdDrawerText === secondItem.text ? "Montserrat-Bold" : "Montserrat-SemiBold"} textcolor={ThirdDrawerText === secondItem.text ? "#118799" : "#999"} fontweight={ThirdDrawerText === secondItem.text ? "700" : "600"}>
                                        {secondItem.text}
                                      </ProjectText>
                                      {secondItem.ThirdSide && ((secondItem.text === activeSecondDrawerText && ThirdDrawer) ? <StyledUpIcon /> : secondItem.text === activeSecondDrawerText ? <StyledMoreIconActive /> : <StyledMoreIcon />)}
                                    </div>
                                    {/* {(secondItem.ThirdSide && toggleSecondDropdown) && <SecondDropdownList className="secondDrawerDropdwon">
                              {renderThirdDropdown()}
                            </SecondDropdownList>
                            } */}

                                    <div style={{ width: '100%' }}>

                                      {
                                        (secondItem.ThirdSide && toggleSecondDropdown) && (
                                          <>
                                            {secondItem.text === 'Account Creation' && isAccountCreationOpen && (
                                              <SecondDropdownList className="secondDrawerDropdwon">
                                                {renderThirdDropdown()}
                                              </SecondDropdownList>
                                            )}

                                            {secondItem.text === 'Need Help?' && isNeedHelpOpen && (
                                              <SecondDropdownList className="secondDrawerDropdwon">
                                                {renderThirdDropdown()}
                                              </SecondDropdownList>
                                            )}
                                          </>
                                        )
                                      }
                                    </div>

                                  </ListItem>
                                ))}
                              </FirstdDropdownList>
                            }
                          </div>
                        </div>
                      </ListItem>
                    )
                  }
                  )}
                </List>
              </Box>
            </Drawer>
          )
        }

        {/* {Second Drawer} */}
        <Drawer
          className={`${SecondDrawer ? `${classes.drawer}  SecondDrawer` : "SecondDrawerClose"} toggleSecondDrawer`}
          variant="persistent"
          anchor="left"
          open={SecondDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={`${classes.toolbar} ${classes.secondDrawerTitle}`}>
            {secondMenuOpenText}
          </div>
          <Box>
            <List>
              {SecondDrawerData?.map((item: SubMenuItem) => (
                <ListItem
                  style={{ paddingLeft: "8px", paddingRight: "8px", paddingTop: "15px", paddingBottom: "15px", borderLeft: ThirdDrawerText === item.text ? '4px solid #118799' : 'none', backgroundColor: ThirdDrawerText === item.text ? '#E8FAFC' : '#fff' }}
                  button
                  key={item.text}
                  onClick={() => {
                    SetThirdDrawerSet(item)
                    item.ThirdSide && handleSecondDrawerDropdown(item.text)
                    if (item.text === 'Account Creation') {
                      handleAccountCreationClick();
                    } else if (item.text === 'Need Help?') {
                      handleNeedHelpClick();
                    }
                  }
                  }
                  className={item.ThirdSide ? "secondDrawerDropdwon" : ""}
                >
                  <div style={{ display: "flex", alignItems: "center", width: "100%", gap: "6px" }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", color: ThirdDrawerText === item.text ? "#118799" : "#999" }} className={ThirdDrawerText === item.text ? "DrawerIconActive" : 'SecondDrawerIcon'}>
                      {item.icon}
                    </div>
                    <ProjectText variant='subtitle2' TextSize="16px" fontfamily={ThirdDrawerText === item.text ? "Montserrat-Bold" : "Montserrat-SemiBold"} textcolor={ThirdDrawerText === item.text ? "#118799" : "#999"}>
                      {item.text}
                    </ProjectText>
                    {item.ThirdSide && ((item.text === activeSecondDrawerText && ThirdDrawer) ? <><StyledUpIcon /><StyledRightIcon /></> : item.text === activeSecondDrawerText ? <StyledMoreIconActive /> : <StyledMoreIcon />)}
                    {/* {
                      (item.ThirdSide && toggleSecondDropdown) && <SecondDropdownList>
                        {renderThirdDropdown()}
                      </SecondDropdownList>
                    } */}


                    {(item.ThirdSide && toggleSecondDropdown) && (
                      <>
                        {item.text === 'Account Creation' && isAccountCreationOpen && (
                          <SecondDropdownList>
                            {renderThirdDropdown()}
                          </SecondDropdownList>
                        )}

                        {item.text === 'Need Help?' && isNeedHelpOpen && (
                          <SecondDropdownList>
                            {renderThirdDropdown()}
                          </SecondDropdownList>
                        )}
                      </>
                    )}
                  </div>

                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        {/* {Third Drawer} */}
        <Drawer
          className={`${ThirdDrawer ? classes.drawer + " " + "ThirdDrawer" : "ThirdDrawerClose"} toggleThirdDrawer`}
          variant="persistent"
          anchor="left"
          open={ThirdDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          style={{
            zIndex: 1
          }}
        >
          <div className={`${classes.toolbar}`} />
          <Box className='ThirdDrawerInnerDiv'>
            <List>
              {renderThirdDropdown()}
            </List>
            <div className="ThirdDrawerSqureFromLeft">
              <img src={formleftsidesqure} alt="FristSqure" />
            </div>
            <div className="ThirdDrawerSqure">
              <img src={drawersqure} alt="FristSqure" />
            </div>
          </Box>
        </Drawer>
      </div>
      {location.pathname === '/Logout' && <div style={{ width: '100%', height: '100%', background: '#070D12', position: 'absolute', top: 0, opacity: 0.3 }}></div>}
      <main className={classes.content} id="scrollll">
        <div
          className="mainContent"
        >
          <div className="FormTopSqure">
            <img src={formtopsqure} alt="FristSqure" />
          </div>
          <div className="FromRightSideSqure">
            <img src={formrightsidesqure} alt="FristSqure" />
          </div>
          {children}
          <div className="FormBottomSqure">
            <img src={formbottomsqure} alt="FristSqure" />
          </div>
          <div className="FormBottomSqureTwo">
            <img src={formbottomsqure} alt="FristSqure" />
          </div>
        </div>
      </main>
      {/* <div className={extraContent ? "SceondSqureDiv displayMedia" : "SceondSqureDiv"}> */}
      <div className='SceondSqureDiv'>
        <img src={RectangleBottom} alt="FristSqure" />
      </div>
      <div className="ThirdSqureDiv">
        <img src={SideBarbottomRightIcon} alt="SideBarbottomRightIcon" />
      </div>
      <div className='RingInnerSqure'>
        <img src={ringinnerSqeure} alt="ringinnerSqeure" />
      </div>
    </SideBarStyle>
  );
}

export default withRouter(ProjectSideBar)

const SideBarStyle = styled('div')({
  height: "100vh",
  position: "relative",
  '& .SideBarLogo': {
    height: "80px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "12px",
    img: {
      objectFit: "cover",
      width: "100%",
    }
  },
  '& .SidebarContainer': {
    height: "65%",
    overflow: "auto",
    marginTop: "auto",
    marginBottom: "auto",
    '@media (max-width: 896px)': {
      marginTop: '20px',
      marginBottom: 0,
      marginLeft: '20px',
      marginRight: '20px'
    }
  },
  "& .SecondDrawer": {
    width: "0px",
    "@media(min-width:900px)": {
      width: "240px"
    },
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      transform: `translateX(${drawerWidth}px) !important`,
      transition: "0.999s ease",
      zIndex: 1,
      "@media(max-width:900px)": {
        zIndex: 1,
        transform: "translateX(0) !important"
      }
    },
  },
  "& .SecondDrawerClose": {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      transform: `translateX(${0}px) !important`,
      zIndex: 1,
      transition: "0.999s ease",
      "@media(max-width:900px)": {
        transform: "translateX(-100%) !important"
      }
    },
  },
  "& .ThirdDrawer": {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      transform: `translateX(${drawerWidth * 2}px) !important`,
      transition: "0.999s ease",
    },
  },
  "& .ThirdDrawerClose": {
    "& .MuiDrawer-paper": {
      boxSizing: "border-box",
      transform: `translateX(${drawerWidth}px) !important`,
      transition: " display 0s, opacity 0.5s linear 0.999s ease",
    },
  },
  "& .toggleThirdDrawer": {
    "@media(max-width:1200px)": {
      display: "none"
    }
  },
  "& .toggleSecondDrawer": {
    "@media(max-width:900px)": {
      display: "none"
    }
  },
  "& .FristDrawerIcon": {
    minWidth: "30px", display: "flex", justifyContent: "center", alignItems: "center",
    '& svg': {
      "& path": {
        fill: "#333"
      }
    }
  },
  "& .DrawerIconActive": {
    minWidth: "30px", display: "flex", justifyContent: "center", alignItems: "center",
    '& svg': {
      "& path": {
        fill: "#118799"
      }
    }
  },
  "& .SecondDrawerIcon": {
    minWidth: "30px", display: "flex", justifyContent: "center", alignItems: "center",
    '& svg': {
      "& path": {
        fill: "#999"
      }
    }
  },
  "& .firstSqureDiv": {
    position: "fixed",
    right: "0",
    top: "0",
    width: "250px",
    height: "60px",
    zIndex: 5,
    '& img': {
      height: "100%",
      width: "100%",
    },
    "@media(max-width:600px)": {
      display: "none"
    }
  },
  "& .SceondSqureDiv": {
    position: "fixed",
    bottom: '0',
    left: "0",
    width: "262px",
    height: "60px",
    zIndex: 5,
    '& img': {
      height: "100%",
      width: "100%",
      objectPosition: "center",
    },
    "@media(max-width:600px)": {
      display: "none"
    }
  },
  "& .displayMedia img": {
    display: "block !important"
  },
  "& .ThirdSqureDiv": {
    position: "fixed",
    bottom: '0',
    right: "0",
    zIndex: -1,
    "@media (max-width: 1550px)": {
      bottom: "0"
    },
    "@media (max-width: 1441px)": {
      bottom: "0"
    },
    '& img': {
      width: "200px",
      flex: 1,
      objectPosition: "center",
      objectFit: "contain",
      verticalAlign: "middle",
      "@media (max-width: 1450px)": {
        width: "100%",
        height: "190px",
      },
      "@media (max-width: 1200px)": {
        display: "none"
      }
    },
  },
  '& .ThirdDrawerInnerDiv': {
    position: "relative",
    height: "100%"
  },
  '& .ThirdDrawerSqure': {
    position: "absolute",
    bottom: "10%",
    left: "15px"
  },
  '& .ThirdDrawerSqureFromLeft': {
    position: "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-60%, 50%)',
  },
  '& .RingInnerSqure': {
    position: "fixed",
    right: "0px",
    bottom: '50px',
    zIndex: -1
  },
  "& .FormBottomSqure": {
    top: "310px",
    left: "540px",
    position: "fixed",
  },
  "& .FormBottomSqureTwo": {
    bottom: "80px",
    right: "340px",
    position: "fixed",
  },
  "& .FromRightSideSqure": {
    bottom: "350px",
    right: "80px",
    position: "fixed",
  },
  "& .FormTopSqure": {
    bottom: "100px",
    left: "500px",
    position: "fixed",
  },
  "& .PhoneInputPackgeClass": {
    width: "100%",
    borderRadius: "8px",
    fontWeight: 700,
    paddingLeft: "35px",
    fontSize: "14px",
    height: "56px",
    borderColor: "rgb(182, 206, 226)"
  },
  '& .flag-dropdown ': {
    cursor: "unset",
    border: 'none',
    background: 'transparent',
  },
  "& .selected-flag": {
    "&:hover": {
      backgroundColor: "unset"
    },
    '&:focus': {
      backgroundColor: "unset"
    }
  },
  '& .CheckIcon': {
    color: "#5DBB65",
    height: '40px',
    right: '10px',
    display: 'flex',
    zIndex: 1,
    position: 'absolute',
    top: "50%",
    transform: "translateY(-50%)",
    alignItems: 'center'
  },
  "& .ColorSuccess": {
    color: "#5DBB65",
    borderColor: "#5DBB65",
  },
  "& .mainContent": {
    position: "relative",
    "@media(max-width:900px)": {
      // minWidth: "500px"
    }
  },
  "& .pageTitle": {
    textTransform: "capitalize",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "32px",
    fontFamily: "Montserrat-regular"
  }
});

const SecondDropdownList = styled(List)({
  position: "absolute",
  background: "#fff",
  zIndex: 1,
  top: "100%",
  left: "-2px",
  width: "100%",
  boxShadow: "0 0 10px rgba(0,0,0,0.4)",
  "@media(min-width:1200px)": {
    display: "none"
  },
  "@media(max-width: 900px)": {
    position: "relative",
    top: "auto",
    boxShadow: "none",
    background: "transparent",
    "& > .MuiListItem-root": {
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderLeft: "none",
      backgroundColor: "transparent",
      position: "relative",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "1px",
        backgroundColor: "#ccc",
        bottom: "0",
        left: "0",
      },
      "& .MuiListItemText-root": {
        padding: "0 12px",
      }
    }
  }
})
const FirstdDropdownList = styled(List)({
  position: "absolute",
  background: "#fff",
  zIndex: 1,
  top: "-380%",
  boxShadow: "0 0 10px rgba(0,0,0,0.4)",
  "@media(min-width:900px)": {
    display: "none"
  },
  "@media(max-width: 900px)": {
    position: "relative",
    top: "auto",
    boxShadow: "none",
    background: "transparent",
    "& > .MuiListItem-root": {
      paddingLeft: "0",
      paddingRight: "0",
      paddingTop: "10px",
      paddingBottom: "10px",
      borderLeft: "none",
      backgroundColor: "transparent",
      position: "relative",
      "&::after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "1px",
        backgroundColor: "#ccc",
        bottom: "0",
        left: "0",
      },
      "& .MuiListItemText-root": {
        padding: "0 12px",
      }
    }
  }
})

const StyledRightIcon = styled(ChevronRightIcon)({
  color: "#118799",
  marginLeft: "auto",
  "@media(max-width:1200px)": {
    display: "none"
  }
});
const StyledMoreIcon = styled(ExpandMoreIcon)({
  color: "#999",
  marginLeft: "auto"
});
const StyledUpIcon = styled(ExpandLessOutlinedIcon)({
  color: "#118799",
  marginLeft: "auto",
  display: "none",
  "@media(max-width:1200px)": {
    display: "block"
  }
});
const StyledMoreIconActive = styled(ExpandMoreIcon)({
  color: "#118799",
  marginLeft: "auto"
});

const StyledTabContainer = styled(Box)({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  alignItems:"center",
  gap: "20px",
  "@media(max-width:1000px)": {
    justifyContent: "flex-start",
    marginLeft: "30px"
  },
  "@media(max-width:500px)": {
    justifyContent: "flex-start",
    marginLeft: "0px"
  },
  "&:hover": {
    cursor: "pointer"
  }
});