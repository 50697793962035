import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Grid,Button } from "@material-ui/core";
import LoginLayout from "../../../components/src/LoginLayout";
import { styled } from "@material-ui/styles";
import { PhoneButton } from '../../../components/src/GlobalButton';
import { deleteIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {},
});
// Customizable Area End

import PasscodeLockController, {
  Props,
  configJSON,
} from "./PasscodeLockController";
import ProjectText from "../../../components/src/ProjectText";

export default class PasscodeLock extends PasscodeLockController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderDots = () => (
    <Grid item xs={12}>
      <Box className="PasscodeContainer">
        {
          this.state.passCode.map((item: { value: string | number }, index: number) => {
            return <div className={`PasscodeSet ${item.value === "" ? "" : "Passcodeactive"}`} key={`${item.value} + ${index + 1}`} />
          })
        }
      </Box>
    </Grid>
  );

  renderButton = () => (
    <Grid item container className="dialBox">
      <Grid item >
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-1" className="phoneButton" id="PassBtn1" value={1} variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn1" onClick={() => this.SetPasscode("1")}>
            1
          </PhoneButton>
          <PhoneButton data-test-id="btnID-2" className="phoneButton" value={2} id="PassBtn2" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn2" onClick={() => this.SetPasscode("2")}>
            2
          </PhoneButton>
          <PhoneButton data-test-id="btnID-3" className="phoneButton" value={3} id="PassBtn3" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn3" onClick={() => this.SetPasscode("3")}>
            3
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item >
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-4" className={this.state.passCodeFalse ? "phoneButton pointer-events" : "phoneButton"} value={4} id="PassBtn4" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn4" onClick={() => this.SetPasscode("4")}>
            4
          </PhoneButton>
          <PhoneButton data-test-id="btnID-5" className="phoneButton" value={5} id="PassBtn5" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn5" onClick={() => this.SetPasscode("5")}>
            5
          </PhoneButton>
          <PhoneButton data-test-id="btnID-6" className="phoneButton" value={6} id="PassBtn6" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn6" onClick={() => this.SetPasscode("6")}>
            6
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item >
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-7" className="phoneButton" value={7} id="PassBtn7" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn7" onClick={() => this.SetPasscode("7")}>
            7
          </PhoneButton>
          <PhoneButton data-test-id="btnID-8" className="phoneButton" value={8} id="PassBtn8" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn8" onClick={() => this.SetPasscode("8")}>
            8
          </PhoneButton>
          <PhoneButton data-test-id="btnID-9" className="phoneButton" value={9} id="PassBtn9" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn9" onClick={() => this.SetPasscode("9")}>
            9
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item >
        <Box className="ButoonContainer">
          <PhoneButton variant="contained" style={{ visibility: "hidden" }}>
            1
          </PhoneButton>
          <PhoneButton value={0} data-test-id="btnID-0" className="phoneButton" variant="contained" onClick={() => this.SetPasscode("0")} disabled={this.state.passCodeFalse}>
            0
          </PhoneButton>
          <PhoneButton value={-1} data-test-id="ClearLastDigitID" id="ClearLastDigit" variant="contained" className="clearButton" onClick={() => this.ClearLastDigit()} disabled={this.state.passCodeFalse}>
            <img src={deleteIcon} alt="delete icon" />
          </PhoneButton>
        </Box>
      </Grid>
      {this.state.popVisible && (

          <Box className="ErrorBoxContainer" style={{marginBottom:"20px"}} >
            {this.state.ErrorPopUpType === "Error" &&(       
              <Button
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "#DC2626",
                  width:"199px",
                  height:"42px",
                  borderRadius:"8px",
                  padding:"12px 16px 12px 16px",
                  top:24
                  }}
                  >
                  Maximum outreach.
              </Button> 
            )}
            </Box>
      )}
    
    </Grid>
  )
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <PasscodeLayout>
          <LoginLayout BackButton BackButtonOnClick={() => {
            this.props.navigation.goBack()
          }}>
            <Grid container spacing={2} className="dialContainer">
              <Grid item xs={12} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Grid item xs={12}>
                  <ProjectText variant="h5" fontweight="700" className={`forgotPasswordTextTitle`} fontfamily="Montserrat-Bold" textcolor="#06130E" align="center">
                    {this.state.passcodeAttempet > 2 ? `Try again in ${this.state.seconds} sec` : configJSON.enterPasscodeTxt}
                  </ProjectText>
                </Grid>
                {this.state.ErrorText &&
                  <Grid item xs={12}>
                    <ProjectText variant="subtitle1" TextSize="14px" fontweight="400" className={`forgotPasswordText ${this.state.ErrorText && "PasscodeError"} `} align="center">
                      {configJSON.passcodeIncorrectTxt}
                    </ProjectText>
                  </Grid>
                }
                {this.renderDots()}
                <Box className="forgotBtn">
                  <PhoneButton data-test-id="forgotpasscode-id" style={{ textTransform: "unset" }} className={this.state.ForogotButtonActive ? "forgotpasswordActive" : ""} shape="squre" fontweight="700" size="small" onClick={this.goToForgotPasscodeScreen}>
                    {configJSON.forgotPasscodeTxt}
                  </PhoneButton>
                </Box>
              </Grid>
              {this.renderButton()}
            </Grid>
          </LoginLayout>
        </PasscodeLayout>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const PasscodeLayout = styled(Box)({
  "& .contentContainer":{
    display: "flex",
    alignItems: "center",
    width: '80%',
    height: "100%",
    margin: "auto",
    "@media(min-width:360px) and (max-width:449px)": {
      position: "inherit",
      alignItems: "flex-start",
      width: "92px",
      marginTop: "20px"
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      position: "inherit",
      alignItems: "flex-start",
      height: "100%",
    },
  },
  "& .resetPasscode": {
    marginTop: "111px",
    gap: "20px",
    position: "relative",
    zIndex: 6
  },
  "& .dialBox":{
    display: "flex",
    flexDirection: "column",
    justifyContent:"center",
    alignItems: "center",
    "@media (min-width:450px) and (max-width:889px)":{
      width: "64vw"
    },
    "@media (min-width:890px) and (max-width:1280px)":{
      width: "42vw"
    },
  },
  "& .dialContainer":{ 
    width:"100%",
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    marginBottom: "2vh",
    "@media (min-width:360px) and (max-width: 449px)": {
      width: "100vw",
    },
    "@media (min-width:450px) and (max-width: 1280px)": {
      width: "80vw",
      justifyContent: "center",
      marginTop: "15%"
    },
  },
  '& .ButoonContainer': {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "32px",
    marginBottom: "24px",
    width: "300px"
  },
  '& .PasscodeContainer': {
    display: "flex",
    justifyContent: "center",
    margin: "48px 0"
  },
  "& .forgotBtn": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "32px"
  },
  "& .PasscodeSet": {
    width: "10px !important",
    border: "1px",
    height: "10px",
    margin: "0px 15px",
    outline: "none",
    fontSize: "20px",
    background: "#fff",
    borderColor: " #B6CEE2",
    borderStyle: "solid",
    borderRadius: "5px",
    backgroundColor: "#DBE7F0",
  },
  "& .Passcodeactive": {
    backgroundColor: "#203A4F"
  },
  "& .PasscodeError": {
    color: "#DC2626",
    marginTop: "16px"
  },
  "& .forgotpasswordActive": {
    color: "white",
    backgroundColor: "#3FB98C"
  },
  "& .phoneButton": {
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  },
  "& .phoneButton .MuiButton-root.Mui-disabled": {
    backgroundColor: "#EDF3F8 !important",
  },
  "& .pointer-events": {
    pointerEvents: "none",
    cursor: "not-allowed"
  },
  "& .clearButton": {
    background: "transparent",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none"
    }
  },
  "& .passcodeTitle": {
    maxWidth: "340px",
    margin: "auto"
  },
  "& .logoContainer": {
    '& img': {
      "@media (min-width:360px) and (max-width: 449px)": {
        display:"none"
      } ,
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  "& .forgotPasswordTextTitle": {
    width: "max-content",
    "@media (min-width:360px) and (max-width: 499px)": {
      width: "auto"
    },
    "@media (min-width:500px) and (max-width: 1280px)": {
      width: "auto"
    } 
  },
 
})
// Customizable Area End
