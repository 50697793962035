// Customizable Area Start
import React from 'react'

export const CreateAccountIcon = () => {
  return (
    <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 6C13.5933 6 14.1734 5.82405 14.6667 5.49441C15.1601 5.16477 15.5446 4.69623 15.7716 4.14805C15.9987 3.59987 16.0581 2.99667 15.9424 2.41473C15.8266 1.83279 15.5409 1.29824 15.1213 0.878681C14.7018 0.459123 14.1672 0.173401 13.5853 0.0576455C13.0033 -0.0581102 12.4001 0.00129986 11.8519 0.228363C11.3038 0.455426 10.8352 0.839943 10.5056 1.33329C10.1759 1.82664 10 2.40666 10 3C10 3.79565 10.3161 4.55871 10.8787 5.12132C11.4413 5.68393 12.2044 6 13 6ZM13 2C13.1978 2 13.3911 2.05865 13.5556 2.16853C13.72 2.27841 13.8482 2.43459 13.9239 2.61732C13.9996 2.80004 14.0194 3.00111 13.9808 3.19509C13.9422 3.38907 13.847 3.56726 13.7071 3.70711C13.5673 3.84696 13.3891 3.9422 13.1951 3.98079C13.0011 4.01937 12.8 3.99957 12.6173 3.92388C12.4346 3.84819 12.2784 3.72002 12.1685 3.55557C12.0586 3.39112 12 3.19778 12 3C12 2.73478 12.1054 2.48043 12.2929 2.29289C12.4804 2.10536 12.7348 2 13 2ZM17.11 5.86C17.6951 5.02103 18.0087 4.02282 18.0087 3C18.0087 1.97718 17.6951 0.97897 17.11 0.140001C17.3976 0.0474144 17.6979 0.000181955 18 1.1415e-06C18.7956 1.1415e-06 19.5587 0.316072 20.1213 0.878681C20.6839 1.44129 21 2.20435 21 3C21 3.79565 20.6839 4.55871 20.1213 5.12132C19.5587 5.68393 18.7956 6 18 6C17.6979 5.99982 17.3976 5.95259 17.11 5.86ZM13 8C7 8 7 12 7 12V14H19V12C19 12 19 8 13 8ZM9 12C9 11.71 9.32 10 13 10C16.5 10 16.94 11.56 17 12M24 12V14H21V12C20.9766 11.2566 20.8054 10.5254 20.4964 9.84891C20.1873 9.17244 19.7466 8.5643 19.2 8.06C24 8.55 24 12 24 12ZM8 7H5V10H3V7H0V5H3V2H5V5H8V7Z" fill="#118799" />
    </svg>
  )
}
// Customizable Area End