import React from "react";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Box, Grid, Button } from "@material-ui/core";
import LoginLayout from "../../../components/src/LoginLayout";
import { styled } from "@material-ui/styles";
import ResetPasscodeController, {
    Props,
    configJSON,
} from "./ResetPasscodeController.web";
import { PhoneButton } from '../../../components/src/GlobalButton';
import { deleteIcon, passcodeSuccessImg } from "./assets";
import ProjectText from '../../../components/src/ProjectText';
import { ContainedButton } from '../../../components/src/GlobalButton';

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {},
});
// Customizable Area End


export default class ResetPasscode extends ResetPasscodeController {
    // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderButton = () => (
        <Grid item container>
            <Grid item xs={12}>
                <Box className="ButoonContainer">
                    <PhoneButton data-test-id="btnID-1" className="phoneButton" id="PassBtn1" value={1} variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn1" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("1") : this.setPasscode("1")}>
                        1
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-2" className="phoneButton" value={2} id="PassBtn2" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn2" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("2") : this.setPasscode("2")}>
                        2
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-3" className="phoneButton" value={3} id="PassBtn3" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn3" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("3") : this.setPasscode("3")}>
                        3
                    </PhoneButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="ButoonContainer">
                    <PhoneButton data-test-id="btnID-4" className="phoneButton" value={4} id="PassBtn4" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn4" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("4") : this.setPasscode("4")}>
                        4
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-5" className="phoneButton" value={5} id="PassBtn5" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn5" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("5") : this.setPasscode("5")}>
                        5
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-6" className="phoneButton" value={6} id="PassBtn6" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn6" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("6") : this.setPasscode("6")}>
                        6
                    </PhoneButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box className="ButoonContainer">
                    <PhoneButton data-test-id="btnID-7" className="phoneButton" value={7} id="PassBtn7" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn7" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("7") : this.setPasscode("7")}>
                        7
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-8" className="phoneButton" value={8} id="PassBtn8" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn8" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("8") : this.setPasscode("8")}>
                        8
                    </PhoneButton>
                    <PhoneButton data-test-id="btnID-9" className="phoneButton" value={9} id="PassBtn9" variant="contained" disabled={this.state.passCodeFalse} data-testid="Btn9" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("9") : this.setPasscode("9")}>
                        9
                    </PhoneButton>
                </Box>
            </Grid>
            <Grid item xs={12} style={{ padding: "0px" }}>
                <Box className="ButoonContainer">
                    <PhoneButton variant="contained" style={{ visibility: "hidden" }}>
                        1
                    </PhoneButton>
                    <PhoneButton value={0} data-test-id="btnID-0" className="phoneButton" variant="contained" onClick={() => this.state.resetStep === 2 ? this.reEnterPasscode("0") : this.setPasscode("0")} disabled={this.state.passCodeFalse}>
                        0
                    </PhoneButton>
                    <PhoneButton value={-1} data-test-id="ClearLastDigitID" id="ClearLastDigit" variant="contained" className="clearButton" onClick={() => this.state.resetStep === 2 ? this.clearReLastDigit() : this.clearLastDigit()} disabled={this.state.passCodeFalse}>
                        <img src={deleteIcon} alt="delete icon" />
                    </PhoneButton>
                </Box>
            </Grid>
        </Grid>
    )
    renderDots = () => (
        <Grid item xs={12}>
            <Box className="PasscodeContainer">
                {this.state.resetStep === 1 &&
                    <>
                        {
                            this.state.passCode.map((item: { value: string | number }, index: number) => {
                                return <div className={`PasscodeSet ${item.value === "" ? "" : "Passcodeactive"}`} key={`${item.value} + ${index + 1}`} />
                            })
                        }
                    </>
                }
                {
                    this.state.resetStep === 2 &&
                    <>
                        {
                            this.state.matchPasscode.map((item: { value: string | number }, index: number) => {
                                return <div className={`PasscodeSet ${item.value === "" ? "" : "Passcodeactive"}`} key={`${item.value} + ${index + 1}`} />
                            })
                        }
                    </>
                }

            </Box>
        </Grid>
    )
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <ThemeProvider theme={theme}>
                <LoginLayout SecondSide={this.state.resetStep === 3 ? false : true} BackButton BackButtonOnClick={() => this.state.resetStep === 1 ? this.props.navigation.goBack() : this.goBackHandler()}>
                    {
                        this.state.resetStep === 3 ?
                            <Suceess>
                                <img src={passcodeSuccessImg} alt="success" />
                                <ProjectText variant="h5" TextSize="24px" className="DescripionTextSuccess" gutterBottom align="center" fontweight="700">{configJSON.passcodeUpdatedSuccessTxt}</ProjectText>
                                <ContainedButton bgcolor="success" style={{
                                    fontSize: "16px", borderRadius: "8px", width: '220px',
                                    height: '56px',
                                    padding: '16px'
                                }} size="medium" 
                                onClick={this.goToLoginScreen}
                                >
                                    {configJSON.proceedTxt}
                                </ContainedButton>
                            </Suceess>
                            :
                            <HomePageStyle>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <ProjectText variant="h5" fontweight="700" className="passcodeTitle" gutterBottom align="center">
                                            {this.state.resetStep === 1 && configJSON.enterNewPasscodeTxt}
                                            {this.state.resetStep === 2 && configJSON.reEnterNewPasscodeTxt}
                                        </ProjectText>
                                    </Grid>
                                    {(this.state.errorText && this.state.resetStep === 2) &&
                                        <Grid item xs={12}>
                                            <ProjectText variant="subtitle1" style={{ fontSize: "14px" }} fontweight="600" className={`LoginText forgotPasswordText ${this.state.errorText && "PasscodeError"} `} gutterBottom align="center">
                                                {configJSON.passcodeNotMatchTxt}
                                            </ProjectText>
                                        </Grid>
                                    }
                                    {
                                        this.state.resetStep === 1 &&
                                        <Grid item xs={12}>
                                            <ProjectText variant="subtitle1" fontweight="400" style={{ textTransform: "unset" }} className={`LoginText forgotPasswordText `} gutterBottom align="center">
                                                {configJSON.sixDigitsTxt}
                                            </ProjectText>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        {this.renderDots()}
                                    </Grid>
                                    <MarginBottom />
                                    {this.renderButton()}
                                </Grid>
                            </HomePageStyle>
                    }
                </LoginLayout>

            </ThemeProvider>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const HomePageStyle = styled("div")({
    "& .resetPasscode": {
        marginTop: "110px",
        gap: "20px",
        position: "relative",
        zIndex: 6
    },
    '& .ButoonContainer': {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "8px",
        marginBottom: "8px"
    },
    '& .PasscodeContainer': {
        display: "flex",
        justifyContent: "center",
        margin: "16px 0 18px 0"

    },
    "& .PasscodeSet": {
        width: "10px !important",
        border: "1px",
        height: "10px",
        margin: "0px 15px",
        outline: "none",
        fontSize: "20px",
        background: "#fff",
        borderColor: " #B6CEE2",
        borderStyle: "solid",
        borderRadius: "5px",
        backgroundColor: "#DBE7F0",
    },
    "& .Passcodeactive": {
        backgroundColor: "#203A4F"
    },
    "& .PasscodeError": {
        color: "red"
    },
    "& .forgotpasswordActive": {
        color: "white",
        backgroundColor: "#3FB98C"
    },
    "& .phoneButton": {
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none"
        }
    },
    "& .phoneButton .MuiButton-root.Mui-disabled": {
        backgroundColor: "#EDF3F8 !important",
    },
    "& .pointer-events": {
        pointerEvents: "none",
        cursor: "not-allowed"
    },
    "& .clearButton": {
        background: "transparent",
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none"
        }
    },
    "& .passcodeTitle": {
        maxWidth: "340px",
        margin: "auto"
    }
});

const Suceess = styled(Box)({
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    "& img": {
        maxWidth: "513px"
    }
});

const MarginBottom = styled(Box)({
    marginBottom: "10px"
});
// Customizable Area End
