// Customizable Area Start
import React, { CSSProperties } from "react";
import CreateProfileController from "./CreateProfileController.web";
import { IconButton,styled,Box } from "@material-ui/core";
import { CountryData } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ProfileFrontView from "./ProfileFrontView.web";
import { withRouter } from "react-router-dom";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import CustomTextInput from "../../../components/src/CustomTextInput";
import CustomDatePicker from "../../../components/src/CustomDatePicker";
import CustomMobileNoGrid from "../../../components/src/CustomMobileNoGrid";
import CustomNationalityGrid from "../../../components/src/CustomNationalGrid";

export class Profile extends CreateProfileController {
  async componentDidMount() {
    this.getToken();
  }

  render() {
    const { editProfileData, Password, errors ,userType} = this.state;

    return (
      <ProjectSideBar pageThisVar={this}>
        {this.state.editProfileStatus === true ? (
          
          // <div  >
         
            <ProfileFrontView
              userfullName={editProfileData.fullName}
              userNickname={editProfileData.userName}
              userNationality={editProfileData.nationality}
              userDoB={editProfileData.dateOfBirth}
              userCrNumber={editProfileData.crNumber}
              userMobileNo={editProfileData.mobileNo}
              userPassword={Password}
              onOpenFromView={this.toggleEditProfileStatus.bind(this)}
              obscurePassword={this.obscurePassword}
              convertDOB ={this.convertDOB}

            />
              
          // </div>
         
        ) : (
          <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <MainDiv>
            <div style={styles.titleDiv}>Edit Profile</div>

            <form
              onSubmit={(event) => this.handleSubmit(event)}
              id="editProfileForm"
            >
              <CustomTextInput
                label="Full name"
                name="fullName"
                value={this.state.editProfileData.fullName}
                error={this.state.errors.fullName}
                onChange={this.handleChangeEditProfile}
              />

              <CustomNationalityGrid
                nationality={editProfileData.nationality}
                errors={errors}
                onChange={this.handleNationality}
              />

              <CustomDatePicker
                label="Date of Birth"
                value={this.state.editProfileData.dateOfBirth}
                onDateChange={this.handleDateOfBirth}
                error={this.state.errors.dateOfBirth}
                touched={true}
              />
              <CustomTextInput
                label="Username"
                name="userName"
                value={this.state.editProfileData.userName}
                error={this.state.errors.userName}
                onChange={this.handleChangeEditProfile}
              />

              <CustomTextInput
                label="CR number"
                name="crNumber"
                disable={userType === "EmployeeAccount" ? true : false}
                value={this.state.editProfileData.crNumber}
                error={this.state.errors.crNumber}
                onChange={this.handleChangeEditProfile}
              />
              <CustomMobileNoGrid
                
                countryCode={"XX"}
                mobileNo={editProfileData.mobileNo}
                errors={errors}
                onChange={(value: any, country: CountryData) => {
                  this.setState((prevState) => ({
                    editProfileData: {
                      ...prevState.editProfileData,
                      mobileNo: value,
                      countryCode: country.dialCode,
                    },
                  }));
                }}
              />

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom:"30px",
                }}
              >
                <IconButton
                  type="button"
                  style={styles.iconButtonCancel}
                  onClick={this.toggleEditProfileStatus}
                >
                  <div style={styles.editProfileContainer}>
                    <span style={styles.editProfileTextCancel}>CANCEL</span>
                  </div>
                </IconButton>
                <IconButton type="submit" style={styles.iconButtonUpdate}>
                  <div style={styles.editProfileContainer}>
                    <span style={styles.editProfileTextUpdate}>UPDATE</span>
                  </div>
                </IconButton>
              </div>
            </form>
          </MainDiv>
          </div>
        )}
      </ProjectSideBar>
    );
  }
}
const MainDiv = styled(Box)(({ theme }) =>({
  marginTop:'36px',
  marginBottom: '50px',
  width:"620px",
  fontFamily:"Montserrat-Regular",
  overflow:'hidden',
   "@media (min-width:601px) and (max-width: 1280px)": {
      width:"400px"
    },
    "@media (min-width:360px) and (max-width: 414px)": {
      width:"300px"
    },
  }))
const styles: { [key: string]: CSSProperties } = {
  iconButtonUpdate: {
    display: "flex",
    width: "113px",
    height: "44px",
    padding: "10px 32px 10px 32px",
    borderRadius: 4,
    backgroundColor: "#3FB98C",
  
    marginTop: 20,
    marginRight: 20,
  },
  iconButtonCancel: {
    display: "flex",
    width: "113px",
    height: "44px",
    borderRadius: 4,
    padding: "10px 32px 10px 32px",
    zIndex:1,
    backgroundColor: "#EDF3F8",
    marginTop: 20,
    marginRight: 20,
  },

  editProfileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  editIcon: {
    width: "15px",
    height: "15px",
    marginRight: 5,
    marginTop: 2,
    userSelect: "none",
  },
  editProfileTextUpdate: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    color: "#E6E6E6",
    fontFamily:"Montserrat-regular",
  },
  editProfileTextCancel: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "24px",
    color: "#4D4D4D",
    fontFamily:"Montserrat-regular",
  },
  titleDiv: {
    // height: 30,
    justifyContent: "flex-start",
    // fontSize: 24,
    // fontWeight: 600,
    alignContent: "center",
    marginBottom: 40,
    color: 'var(--Secondary-cyan-950, #031417)',
    fontFamily: 'Montserrat-Regular',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '-0.12px',
  },
};
export default withRouter(Profile);


// Customizable Area End
