import React from "react";
 // Customizable Area Start
import HelpCentreController,{Props}  from "./HelpCentreController";
import { Box, Typography } from "@material-ui/core";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
// Customizable Area End

 // Customizable Area Start
 // Customizable Area End
export default class HelpCenter extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
 
  render() {
    // Customizable Area Start

    return (
       <>
       <ProjectSideBar pageThisVar={this}>
            <Box sx={{marginBottom:'5%'}}>
                <Box sx={webStyle.topBartext}>
                  Help center questions:
                </Box>
                {this.state.helpCentreNewQA.map((item:any,i:any) =>(
                  <>
                <Box sx={webStyle.titleTextContainer}>
                <Typography style={webStyle.titleText1}>{i+1}</Typography>
                <Typography style={webStyle.titleText2}>{item.attributes.question}</Typography>
                </Box>
                <Box sx={webStyle.textContainer}>
                {item.attributes.answer.split('\r\n').map((step:any, index:any) => (
                         <>
                      { step!=="" && 
                      
                        (
                    <Typography style={webStyle.contentText}>{String.fromCharCode(65 + index)}. {step}</Typography>
                 
                        )}
                        </>
                ))}
                    </Box>
                </>
               )) }
            </Box>
        </ProjectSideBar>
       </>
    )
    // Customizable Area End
  }
  
}

// Customizable Area Start
const webStyle = {
  textContainer:{
    display:'flex',
    flexDirection:'column',
    marginLeft:'6%',
    paddingLeft:'1%',
    width:'90%',
    marginBottom: "100px"
  },

  titleText1:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
  },

  titleTextContainer:{
    display:'flex',
    flexDirection:'row',
    marginLeft:'4%',
    marginTop:'2%', 
    marginBottom:'1%'
  },
  titleText2:{
    fontSize:'18px',
    fontFamily:'Montserrat-Bold',
    marginLeft:'1%',
  },
  topBartext:{  
    fontSize: 24,
    color: "#1A1A1A",
    userSelect:'none',
    margin:'4%',
    fontFamily:'Montserrat-Bold'
  },
  subcontentText:{
    color:'#4D4D4D',
    fontSize:'16px',
    fontFamily:'Montserrat-SemiBold',
    marginBottom:'1%',
    marginTop:'1%', 
  },
  contentText:{
    color:'#333333',
    fontSize:'16px',
    fontFamily:'Montserrat-Regular',
    marginBottom:'1%',
    marginTop:'1%', 
  },
 
}
// Customizable Area End




