Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.analyticsTxt = "Analytics";
exports.collectionAnalyticsTxt = "Collection Analytics";
exports.refundAnalyticsTxt = "Refund Analytics";
exports.salesPerformanceInsightsTxt = "Sales performance insights";
exports.statisticsTxt = "Statistics";
exports.collectionOverviewTxt = "Collection Overview";
exports.filterByTxt = "Filter by";
exports.totalCollectionTxt = "Total No. of Collection";
exports.totalValueTxt = "Total Value of collection";
exports.refundsAnalysisTxt = "Refunds Analysis";
exports.totalRefundsProcessedTxt = "Total number of refunds processed";
exports.totalRefundAmountTxt = "Total refund amount";
exports.sarTxt = "SAR";
exports.reportsTxt = "Reports";
exports.collectionSummaryReportTxt = "Collection Summary Report";
exports.refundSummaryReportTxt = "Refund Summary Report";
exports.salesPerformanceReportTxt = "Sales Performance Report";
exports.collectionAnalyticsEndPoint = "bx_block_dashboard/list_collection_analytic";
exports.refundAnalyticsEndPoint = "bx_block_dashboard/list_refund_analytic";
exports.salesPerformanceInsightsEndPoint = "bx_block_dashboard/temp";
exports.statsCollectionEndPoint = "bx_block_dashboard/collection_analytic_statistics?time_frame=";
exports.statsRefundEndPoint = "bx_block_dashboard/refund_analytic_calculation";
exports.collectionSummaryReportEndPoint = "bx_block_dashboard/collection_analytics_report?time_frame=";
exports.refundSummaryReportEndPoint = "bx_block_dashboard/refund_analytic_report?time_frame=";
exports.salesPerformanceReportnEndPoint = "bx_block_dashboard/temp";
exports.adHocReportingTxt = "Ad-hoc reporting";
exports.generateReportTxt = "Generate Report";
exports.collectionSummaryReportTxt = "Collection Summary Report";
exports.showHideFieldsTxt = "Show/Hide Fields";
exports.columnHeaderTxt = "Column Header";
exports.dateTxt = "Date";
exports.filterTxt = "Filter";
exports.hideAll = "Hide All";
exports.date = "Date";
exports.applyFilter = "Apply Filter";
exports.startDateTxt  = "Start Date";
exports.endDateTxt  = "End Date";
exports.transactionTypeTxt  = "Transaction Type";
exports.drawerDatePlaceholder = "dd/mm/yy";
// Customizable Area End