import React from "react";
// Customizable Area Start
import SettingsResetPasscodeController, {Props} from "./SettingsResetPassocdeController.web";
import { styled } from "@material-ui/styles";
import { Box, Grid } from "@material-ui/core";
import { PhoneButton } from '../../../components/src/GlobalButton';
import ProjectText from '../../../components/src/ProjectText';
import { ContainedButton } from '../../../components/src/GlobalButton'
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { groupImagePasswordSuccess } from "../../../blocks/forgot-password/src/assets";
import { deleteIcon } from "./assets";
import { configJSON } from "./CreatePasscodeController.web";

// Customizable Area End



export default class SettingsResetPasscode extends SettingsResetPasscodeController {
 // Customizable Area Start
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

  renderButton = () => (
    <Grid item container>
      <Grid item xs={12}>
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-1" className="phoneButton" id="PassBtn1" value={1} variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn1" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("1") : this.SetPasscode("1")}>
            1
          </PhoneButton>
          <PhoneButton data-test-id="btnID-2" className="phoneButton" value={2} id="PassBtn2" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn2" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("2") : this.SetPasscode("2")}>
            2
          </PhoneButton>
          <PhoneButton data-test-id="btnID-3" className="phoneButton" value={3} id="PassBtn3" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn3" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("3") : this.SetPasscode("3")}>
            3
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-4" className="phoneButton" value={4} id="PassBtn4" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn4" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("4") : this.SetPasscode("4")}>
            4
          </PhoneButton>
          <PhoneButton data-test-id="btnID-5" className="phoneButton" value={5} id="PassBtn5" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn5" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("5") : this.SetPasscode("5")}>
            5
          </PhoneButton>
          <PhoneButton data-test-id="btnID-6" className="phoneButton" value={6} id="PassBtn6" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn6" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("6") : this.SetPasscode("6")}>
            6
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className="ButoonContainer">
          <PhoneButton data-test-id="btnID-7" className="phoneButton" value={7} id="PassBtn7" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn7" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("7") : this.SetPasscode("7")}>
            7
          </PhoneButton>
          <PhoneButton data-test-id="btnID-8" className="phoneButton" value={8} id="PassBtn8" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn8" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("8") : this.SetPasscode("8")}>
            8
          </PhoneButton>
          <PhoneButton data-test-id="btnID-9" className="phoneButton" value={9} id="PassBtn9" variant="contained" disabled={this.state.PassCodeFalse} data-testid="Btn9" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("9") : this.SetPasscode("9")}>
            9
          </PhoneButton>
        </Box>
      </Grid>
      <Grid item xs={12} style={{ padding: "0px" }}>
        <Box className="ButoonContainer">
          <PhoneButton variant="contained" style={{ visibility: "hidden" }}>
            1
          </PhoneButton>
          <PhoneButton value={0} data-test-id="btnID-0" className="phoneButton" variant="contained" onClick={() => this.state.resetStep === 3 ? this.reEnterPasscode("0") : this.SetPasscode("0")} disabled={this.state.PassCodeFalse}>
            0
          </PhoneButton>
          <PhoneButton value={-1} data-test-id="ClearLastDigitID" id="ClearLastDigit" variant="contained" className="clearButton" onClick={() => this.state.resetStep === 3 ? this.ClearReLastDigit() : this.ClearLastDigit()} disabled={this.state.PassCodeFalse}>
          <img src={deleteIcon} alt="delete icon" />
          </PhoneButton>
        </Box>
      </Grid>
    </Grid>
  )
  renderDots = () => (
    <Grid item xs={12}>
      <Box className="PasscodeContainer">
        {
          this.state.resetStep === 3 ?
          <>
              {

                this.state.matchPasscode.map((item: { value: string | number }, index: number) => {
                  return <div className={`PasscodeSet ${item.value === "" ? "" : "Passcodeactive"}`} key={`${item.value} + ${index + 1}`} />
                })
              }
            </>
             : 
            <>
              {
                this.state.PassCode.map((item: { value: string | number }, index: number) => {
                  return <div className={`PasscodeSet ${item.value === "" ? "" : "Passcodeactive"}`} key={`${item.value} + ${index + 1}`} />
                })
              }
            </>
        }
      </Box>
    </Grid>
  )
// Customizable Area End
  render() {
    // Customizable Area Start
    return (
            <ProjectSideBar pageThisVar={this}>
              <HomePageStyle>
                <Grid container className="resetPasscode">
                  {
                    this.state.resetStep === 4 ?
                      <Grid item xs={12}>
                        <Suceess>
                          <img src={groupImagePasswordSuccess} alt="success" />
                          <ProjectText variant="h5" TextSize="24px" className="DescripionTextSuccess" gutterBottom align="center" fontweight="700">{configJSON.passcodeUpdatedSuccessTxt}</ProjectText>
                          <ContainedButton bgcolor="success" style={{
                            fontSize: "16px", borderRadius: "8px", width: '220px',
                            height: '56px',
                            padding: '16px'
                          }} size="medium" 
                          onClick={this.goToLoginScreen}
                          >
                            {configJSON.proceedTxt}
                          </ContainedButton>
                        </Suceess>
                      </Grid>
                      :
                      <>
                        <Grid item xs={12}>
                          <ProjectText variant="h5" fontweight="700" className="passcodeTitle" gutterBottom align="center">
                            {this.state.resetStep === 1 && "Please enter your current passcode"}
                            {this.state.resetStep === 2 &&  "Please enter your new passcode"}
                            {this.state.resetStep === 3 &&  "Please re-enter your new passcode"}
                          </ProjectText>
                        </Grid>
                        {(this.state.ErrorText && this.state.resetStep === 3) &&
                          <Grid item xs={12}>
                            <ProjectText variant="subtitle1" style={{ fontSize: "14px" }} fontweight="600" className={`LoginText forgotPasswordText ${this.state.ErrorText && "PasscodeError"} `} gutterBottom align="center">
                              {configJSON.passcodeNotMatchTxt}
                            </ProjectText>
                          </Grid>
                        }
                        {(this.state.ErrorText && this.state.resetStep === 1) &&
                          <Grid item xs={12}>
                            <ProjectText variant="subtitle1" style={{ fontSize: "14px" }} fontweight="600" className={`LoginText forgotPasswordText ${this.state.ErrorText && "PasscodeError"} `} gutterBottom align="center">
                            {configJSON.wrongPasscodeTxt}
                            </ProjectText>
                          </Grid>
                        }
                        {
                          !this.state.ErrorText && this.state.PasscodeAttempet > 2 && this.state.resetStep === 1 && 
                          <Grid item xs={12}>
                            <ProjectText variant="subtitle1" style={{ fontSize: "14px" }} fontweight="600" className={`LoginText forgotPasswordText ${!this.state.ErrorText && "PasscodeError"} `} gutterBottom align="center">
                            {configJSON.tryAgainTxt} {this.state.Seconds} s.
                            </ProjectText>
                          </Grid>
                        }
                        {(this.state.passCodeErrorText && this.state.resetStep === 3) &&
                          <Grid item xs={12}>
                            <ProjectText variant="subtitle1" style={{ fontSize: "14px" }} fontweight="600" className={`LoginText forgotPasswordText ${this.state.apiError && "PasscodeError"} `} gutterBottom align="center">
                            {this.state.passCodeErrorText}
                            </ProjectText>
                          </Grid>
                        }
                        <Grid item xs={12}>
                          {this.renderDots()}
                        </Grid>
                        <MarginBottom />
                        {this.renderButton()}
                      </>
                  }
                </Grid>
              </HomePageStyle>
            </ProjectSideBar>
    )
// Customizable Area End

  }
}

// Customizable Area Start

const HomePageStyle = styled("div")({
  "& .resetPasscode": {
    marginTop: "50px",
    gap: "20px",
    position: "relative",
    zIndex: 6
  },
  '& .ButoonContainer': {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "8px",
    marginBottom: "8px"
},
'& .PasscodeContainer': {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0 18px 0"
},
  "& .PasscodeSet": {
    width: "10px !important",
    border: "1px",
    height: "10px",
    margin: "0px 15px",
    outline: "none",
    fontSize: "20px",
    background: "#fff",
    borderColor: " #B6CEE2",
    borderStyle: "solid",
    borderRadius: "5px",
    backgroundColor: "#DBE7F0",
  },
  "& .Passcodeactive": {
    backgroundColor: "#203A4F"
  },
  "& .PasscodeError": {
    color: "red"
  },
  "& .forgotpasswordActive": {
    color: "white",
    backgroundColor: "#3FB98C"
  },
  "& .phoneButton":{
    boxShadow: "none",
    "&:hover":{
      boxShadow: "none"
    }
  },
  "& .clearButton":{
    background: "transparent",
    boxShadow: "none",
    "&:hover":{
      boxShadow: "none"
    }
  },
  "& .passcodeTitle":{
    maxWidth: "340px",
    margin: "auto"
  }
})

const Suceess = styled(Box)({
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  "& img": {
      maxWidth: "513px" 
  }
});
const MarginBottom = styled(Box)({
  marginBottom: "10px"
})

// Customizable Area End