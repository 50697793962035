Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "POST";
exports.productAPiEndPoint = "/qr_codes/qr_codes";
exports.inputLabelOne = "Wholesaler name";
exports.inputLabelTwo = "Merchant name";
exports.inputLabelThree = "Amount";
exports.inputLabelFour = "Date & time";
exports.inputLabelFive = "Merchant employee name";
exports.inputLabelSix = "Wholesaler employee name";
exports.inputLabelSeven = "Location";
exports.inputPlaceholderOne = "Your full name";
exports.inputPlaceholderTwo = "Enter ID";
exports.inputPlaceholderThree = "Enter Amount";
exports.inputPlaceholderFour = "dd/mm/yyyy  hh:mm ";
exports.inputPlaceholderFive = "Enter name";
exports.inputPlaceholderSix = "Enter name";
exports.inputPlaceholderSeven = "Enter location";
exports.buttonTxt = "Generate QR Code";
exports.genrateQRCodeEndPoint = "bx_block_qr_codes/qr_codes";
// Customizable Area End
