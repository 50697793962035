
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props extends RouteComponentProps {
  id?: string;
}

export const configJSON = require("./config");

interface S {
// Customizable Area Start
ResetAPI: string;
token: string;
termsCondsId: string;
isLoading: boolean;
Phone: string | number,
CountryCode: string | number,
PhoneInputBorder: boolean,
Password: string,
newPassWord:string,
confirmNewPassword:string,
TextFiledType: boolean,
TextBoxColorChange: boolean,
PasswordError: boolean,
PasswordMatch:boolean,
PassWordMatchText:string,
PassWordErrorText: string,
newPasswordscreen:boolean,
ErrorPopUpType:string
PasswordData:string
// Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
// Customizable Area End
}
// Customizable Area Start
// Customizable Area End
export default class ResetPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
// Customizable Area Start
ResetAPI: string = "";
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      ResetAPI: "",
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "in",
      PhoneInputBorder: false,
      Password: "",
      newPassWord:"",
      confirmNewPassword:"",
      TextFiledType: false,
      TextBoxColorChange: false,
      PasswordError: false,
      PasswordMatch:false,
      PassWordMatchText:"",
      PassWordErrorText: "",
      newPasswordscreen:false,
      ErrorPopUpType:"",
      PasswordData:""
    };
   // Customizable Area End
  }
// Customizable Area Start
  SetTextFiledType = () => {
    this.setState({
      TextFiledType: !this.state.TextFiledType
    })
  }
   onButton =(values:any) => {
    if(this.state.newPasswordscreen){
        this.ChangePassword(values)
        this.setNewPassword(values)
    }
    else{
        this.ConfirmPassword(values)    
    }
    
}

  SetPassword = (e:any, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ Password: e.target.value, PasswordError: false, PassWordErrorText: "" })
    setFieldValue("password", e.target.value);
  }

  SetnewPassword = (e: any, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ newPassWord: e.target.value, PasswordError: false, PassWordErrorText: "" })
    setFieldValue("newPassWord", e.target.value);
  }
  SetconfirmNewPassword = (e: any, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ confirmNewPassword: e.target.value, PasswordError: false, PassWordErrorText: "" })
    setFieldValue("confirmNewPassword", e.target.value);
  }

  ConfirmPassword = async (values: { password: string}) => {
    const header = {
      "Content-Type": "application/json",
      "token": await getStorageData("authToken")
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResetAPI = requestMessage.messageId;
    let Body = {
      "current_password": values.password
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/reset_password"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setNewPassword = async (values: { newPassWord: string, confirmNewPassword:string }) => {
    const header = {
      "Content-Type": "application/json",
      "token": await getStorageData("authToken")
    };
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResetAPI = requestMessage.messageId;
    let Body = {
        "account": {
            "password": values.newPassWord,
            "password_confirmation": values.confirmNewPassword
          }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/update_password"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.ResetAPI) {
      if (responseJson.errors || responseJson.error) {
        this.ResponseHandle(responseJson)
      } 
      else if(responseJson.message==='Password Confirmed.'){
        this.setState({newPasswordscreen:true})
      }
      else if(responseJson.message==='Password successfully updated.'){
        this.props.history.push({
            pathname: `/Login`
        });
      }
    }
  }

  ResponseHandle = (responseJson: { errors: object, error: string }) => {
    if(responseJson.error ==="Incorrect Password."){
        this.setState({
            PasswordError: true,
            PassWordErrorText: "Incorrect Password"
          })
    }
    else{
        this.setState({
            PasswordError: true,
            PassWordErrorText: responseJson.error
          })
    }
  }

  ChangePassword = (values: { confirmNewPassword: string, newPassWord: string }) => {
    if (values?.confirmNewPassword !== values?.newPassWord) {
        this.setState({
            PasswordMatch: true,
            PasswordError:true,
            PassWordMatchText: "Password do not match",
            ErrorPopUpType: "Error"
          }, ()=> {
            let popupTimeOut = setTimeout(()=> {
            clearTimeout(popupTimeOut)
              this.setState({
                PasswordMatch: false
              })
            }, 5000)
          })
    } 
    
  }
// Customizable Area End
}
// Customizable Area Start
// Customizable Area End
