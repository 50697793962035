// Customizable Area Start
import { Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface PropsTyps {
    type?: string,
    textcolor?: string,
    fontweight?: string
    space?: string,
    variant?: string,
    TextSize?: string,
    fontfamily?:string
}

const ProjectText = styled(Typography)((props: PropsTyps) => (
    {
        ...(props.type === "error" && {
            color: "red",
        }),
        ...(props.type === "success" && {
            color: "#5DBB65",
        }),
        ...(props.TextSize === "string" && {
            
            fontSize: props.TextSize
        }),
        ...(typeof props.textcolor === "string" && {
            color: props.textcolor,
        }),
        ...(props.space === "both" && {
            marginBottom: "10px",
            marginTop: "10px"
        }),
        ...(props.variant === "h4" && {
            "@media (max-width: 1200px)": {
                fontSize: "28px",
            },
            fontWeight: 700,
            ...(typeof props.fontweight === "string" && {
                fontWeight: props.fontweight,
            }) as React.CSSProperties,
        }),
        ...(props.variant === "h5" && {
            fontSize: "24px"
        }),
        ...(props.variant === "h6" && {
            fontSize: "14px",
            fontWeight: 400
    }),
        ...(props.variant === "subtitle1" && {
                fontSize: "16px",
                fontWeight: 700
        }),
        ...(props.variant === "subtitle2" && {
            fontSize: "16px",
            fontWeight: 600,
            "@media (max-width: 768px)": {
                fontSize: "14px"
            },
            ...(typeof props.fontweight === "string" && {
                fontWeight: props.fontweight,
            }) as React.CSSProperties,
        }),
        ...(typeof props.fontweight === "string" && {
            fontWeight: props.fontweight,
        }) as React.CSSProperties,
        fontFamily: props.fontfamily ? props.fontfamily : "Montserrat-regular",
        fontSize: props.TextSize
    }))

export default ProjectText
// Customizable Area End