// Customizable Area Start
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const actionIcon = require("../assets/action_icon.png")
export const viewInvoiceIcon = require("../assets/view_invoice.png")
export const DetailsIcon = require("../assets/details.png")
export const refundIcon = require("../assets/refund.png")
export const shareIcon = require("../assets/share.png")
export const sharedDoneIcon = require("../assets/shareddone.png")
export const successIcon = require("../assets/success.png")
// Customizable Area End
