export interface EditProfile {
  fullName: string;
  nationality: string;
  dateOfBirth: string;
  userName: string;
  crNumber: string;
  mobileNo: string;
}
export interface InitialValue {
  Fullname: string,
  DateofBirth:string,
  Username: string,
  UserID: string,
  Nationality:string,
  termsAndConditions: boolean,
}
export type CountryData = {
  dialCode?: string;
  format?: string;
};

export interface FormValues {
  fullName: string;
  nationality: string;
  dateOfBirth: string;
  emailID: string;
  userName: string;
  crNumber: string;
  mobileNo: string;
}
export const defaultUserProfileData: EditProfile = {
  fullName: "Enter Full Name",
  nationality: "Choose Nationality",
  dateOfBirth: "Eneter D.o.B.",
  userName: "Enter User Nickname",
  crNumber: "EnterUser CR number",
  mobileNo: "Enter User Mobile No.",
};

export const defaultProfileError: EditProfile = {
  fullName: "",
  nationality: "",
  dateOfBirth: "",
  userName: "",
  crNumber: "",
  mobileNo: "",
};
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
