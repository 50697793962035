// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { defaultUserProfileData,EditProfile, InitialValue} from "./consts";
import { universalApiCall } from "../../../components/src/universalAPICallAndMore";
import React from "react";
import moment from "moment";

export interface Props extends RouteComponentProps {
  id?: string;
  navigation: any;
}
const defaultInitialUserProfileData:InitialValue={
  Fullname: "",
  DateofBirth: "",
  Username: "",
  UserID: "",
  Nationality: "" || "Saudi Arabia",
  termsAndConditions: false,
}
interface ResponseJsonOtpData {
  message: string;
}
interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextFiledTypeForConfirm: boolean,
  otp: string | number,
  Sendotp: boolean,
  VerifyCode: Array<string> | string,
  DilogRender: boolean
  editProfileData:EditProfile;
  errors:any;
  editProfileStatus:boolean;
  data:any;
  apiError: boolean,
  userType:string,
  initialValues:InitialValue,
  isEmployee:boolean,
  isLoadingData:boolean
}

interface SS {
  id: string;
}

export default class CreateProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDataCallId:string="";
  putUserUpdatedDataCallId:string="";
  _isMounted = false;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage), 
      getName(MessageEnum.NavigationPayLoadMessage), 
      getName(MessageEnum.CountryCodeMessage),  
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      token: "",
      CountryCode: "sa",
      otp: "",
      Sendotp: false,
      termsCondsId: "",
      TextFiledTypeForConfirm: false,
      Password: "",
      TextFiledType: false,
      isLoading: false,
      Phone: "",
      PhoneInputBorder: false,
      VerifyCode: "",
      DilogRender: false,
      editProfileData:defaultUserProfileData,
      errors:{},
      editProfileStatus:true,
      data:null,
      apiError:false,
      userType:"",
      initialValues:defaultInitialUserProfileData,
      isEmployee:true,
      isLoadingData:true
    };
    this._isMounted=true;
    
  }
  

  CreateProfileAPiCall: string = "";
  createEmployeeAccountApiCall: string = "";
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    
    if(getName(MessageEnum.SessionResponseMessage) === message.id){
      let token=message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({token:token},async ()=>{
        if (this.state.token!=="") {
          await this.getUserExistingData();
          await this.getUserTypeData()
        }
      });
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallCreateProfile = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonOtpData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJsonOtpData?.errors) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            userName: responseJsonOtpData?.errors[0].user_name
          }
        }));
        
      }
      this.handleApiResponse(apiRequestCallCreateProfile,responseJsonOtpData)

    }
  }
 async componentDidMount() {
    this._isMounted = true;

    await this.getUserTypeData()
    let AccountDetails = await getStorageData("accountDetails");
    if (AccountDetails !== "null") {
      AccountDetails = JSON.parse(AccountDetails);
      const profileData = {
        Fullname: AccountDetails.full_name,
        DateofBirth: moment(AccountDetails.dob).format("YYYY-MM-DD"),
        UserID: AccountDetails.user_id,
        Username: AccountDetails.user_name,
        Nationality: AccountDetails.nationality,
        termsAndConditions: false,
      }
      this.setState({
        initialValues:profileData,
        isEmployee:true,
        isLoadingData:false
      })
    }else{
      this.setState({
        isLoadingData:false,
        isEmployee:false,
      })
    }
  }

 async componentWillUnmount() {
    this._isMounted = false;
  }

  SetFullName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Fullname", e.target.value);
  }

  SetDateofBirth = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("DateofBirth", e.target.value);
  }

  SetUserName = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Username", e.target.value);
  }

  SetUserId = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("UserID", e.target.value);
  }

  SetNationality = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    setFieldValue("Nationality", e.target.value);
  }

  SetCheckBox = (e: React.ChangeEvent<HTMLInputElement>, setFieldValue: (field: string, value: boolean, shouldValidate?: boolean) => void) => {
    setFieldValue("termsAndConditions", e.target.checked);
  }

  

  LoginApiFuncation = async (values: {
    Fullname: string,
    DateofBirth: string,
    Username: string,
    UserID: string,
    Nationality: string
  }) => {
    const authentice = await getStorageData("newpassword");
    const newpassword = await getStorageData("UserData");
    

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CreateProfileAPiCall = requestMessage.messageId;
    let Body = {
      "token": authentice,
      "data": {
        "type": "sms_account",
        "attributes": {
          "password": newpassword,
          "full_name": values.Fullname,
          "nationality": values.Nationality,
          "date_of_birth": values.DateofBirth,
          "user_id": "",
          "user_name": values.Username,
          "accept_term_and_condition": true,
          "role_id": "wholesaler"
        }
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/account_block/accounts");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      isLoading: true
    })
  };
  getUserTypeData = async ()=>{
    const data = await getStorageData("userType");
    this.setState({userType:data});

  }

  createEmployeeAccountApiFuncation = async (values: {
    Fullname: string,
    DateofBirth: string,
    Username: string,
    UserID: string,
    Nationality: string
  }) => {
    const authentice = await getStorageData("newpassword");
    const newpassword = await getStorageData("UserData");
    

    const header = {
      "Content-Type": "application/json"
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createEmployeeAccountApiCall = requestMessage.messageId;
    let Body = {
      "token": authentice,
      "data": {
        "type":"employee_account",
        "attributes":{
            "password":newpassword,
            "user_name":values.Username,
            "accept_term_and_condition" :true
        }
      }
    }
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/account_block/accounts/create_employee_account");

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );

    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(Body));

    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({
      isLoading: true
    })
  };

  ChangePassword = (values: {
    Fullname: string,
    DateofBirth: string,
    Username: string,
    UserID: string,
    Nationality: string,
    termsAndConditions: boolean
  }) => {
   if(this.state.isEmployee){
    this.createEmployeeAccountApiFuncation(values)
   }else{
     this.LoginApiFuncation(values)

   }
  }

  
  createEmployeeAccount= (values: {
    Fullname: string,
    DateofBirth: string,
    Username: string,
    UserID: string,
    Nationality: string,
    termsAndConditions: boolean
  }) => {
    this.createEmployeeAccountApiFuncation(values)
  }
  getPhoneFromLocalStorage(){
    return localStorage.getItem("PhoneNumber".toString())
  }
  SetNewPassowrdApiResponse = async(
    responseJson: { errors?: object,  error: object, 
      data: { attributes: { pin: string | number } },
     meta: { token: string, pin: string | number } 
    }) => {
    if (responseJson.errors || responseJson.error) {
      this.setState({
        apiError: true
      })
      this.isMountedTrue();
    } else {
      this.setState({
        DilogRender: true,
        isLoading: false
      })
      setStorageData("SetPasswordToken", responseJson?.meta?.token);
    }
  }
 
  GoBackFuncation = () => {
    this.props.history.goBack()
  }

  NavigateCreatePassword = () => {
      const to = new Message(getName(MessageEnum.NavigationMessage));
      to.addData(getName(MessageEnum.NavigationTargetMessage), "CreatePasscode");
      to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(to);
  }
  isMountedTrue(){
    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  }
  goToTermsAndConditions=()=> {
  
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), "TermsConditions");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props); 

  this.send(message);

  }

validateUsername = (username: string) => {
  const alphabeticRegex = /^[a-zA-Z\s]*$/;
  if (!username.trim()) {
    return 'This field is required';
  } else if (!alphabeticRegex.test(username)) {
    return 'Username should contain only alphabetic characters';
  } else {
    return '';
  }
}
handleChangeEditProfile = (event: React.ChangeEvent<HTMLInputElement>) => {
  const { name, value } = event.target;
  const errors = { ...this.state.errors };

  if (name === 'fullName') {
    if (value.trim() === '' || value.trim().length < 3) {
      errors[name] = 'Full name should not be less than 3 characters or blank';
    } else {
      errors[name] = '';
    }
  } 
 else if (name === 'userName') {
  const usernameError = this.validateUsername(value);
  errors[name] = usernameError;
  }
  this.setState(prevState => ({
    editProfileData: {
      ...prevState.editProfileData,
      [name]: value
    },
    errors: errors
  }));
}


  handleNationality = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const target = e.target as HTMLInputElement | HTMLTextAreaElement;
    let value = target.value;

    if (value === '' || value === 'Nationality') {
        value =  "SA";
    } 
    this.setState(prevState => ({
        editProfileData: {
            ...prevState.editProfileData,
            nationality: value
        },
    }));
  };
  validateDateOfBirth = (dateString: string) => {
    let errors = '';
    if (!dateString) {
        errors= "Date of birth is required";
    } else {
        const selectedDate = new Date(dateString);
        const currentDate = new Date();
        if (currentDate.getFullYear() - selectedDate.getFullYear() < 18) {
            errors = "Date must be above 18+ years";
        }
    }

    return errors;
};
handleDateOfBirth = (dateString: string) => {
  const errors = { ...this.state.errors };
  const isValidDate = /\d{4}-\d{2}-\d{2}/.test(dateString);

  if (!isValidDate) {
      errors.dateOfBirth = "Invalid date format";
  } else {
      const validationErrors = this.validateDateOfBirth(dateString);
      errors.dateOfBirth = validationErrors;
  }

  this.setState(prevState => ({
      editProfileData: {
          ...prevState.editProfileData,
          dateOfBirth: dateString
      },
      errors: errors
  }));
};
  
  
  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
  
   event.preventDefault(); 
   event.stopPropagation();

    const isValid = this.validate();
    if (isValid) {
     
      this.putUserUpdatedData();
      
    }
  };
  
  validate = () => {
    let isValid = true;
    const errors: EditProfile = {
      fullName: '',
      nationality: '',
      dateOfBirth:"",
      userName: '',
      crNumber: '',
      mobileNo: ''
    };
  
    for (const key in this.state.editProfileData) {
      if (key !== 'crNumber' &&  this.state.editProfileData[key as keyof EditProfile] === '' ) {
        errors[key as keyof EditProfile] = 'This field is required';
        isValid = false;
        
      }
    }
    const usernameError = this.validateUsername(this.state.editProfileData.userName);
    if (usernameError !== '') {
        errors.userName = usernameError;
        isValid = false;
    }
    const dobValidationErrors = this.validateDateOfBirth(this.state.editProfileData.dateOfBirth);
    if (dobValidationErrors !== '') {
        errors.dateOfBirth = dobValidationErrors;
        isValid = false;
    }
   
    this.setState({ errors });
    return isValid;
  };
  async getUserExistingData() {
   this.setState ({ Password:await getStorageData("UserData")});
    this.getUserDataCallId = await universalApiCall({
 
      method: "GET",
      endPoint: "account_block/profile",
    },this.state.token);
  }
  async putUserUpdatedData(){
    const{editProfileData}=this.state;


      const header = {
      "Content-Type": "application/json",
      "token": this.state.token
    };

    const msg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.putUserUpdatedDataCallId = msg.messageId;
    let Body = {   
      "account":{
        "full_name":editProfileData.fullName,
        "nationality":editProfileData.nationality,
        "date_of_birth": editProfileData.dateOfBirth,
        "user_id":"",
        "user_name":editProfileData.userName
      }
    }
    msg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/update_account"
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    msg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );

    runEngine.sendMessage(msg.id, msg);
  }
  toggleEditProfileStatus = () => {
    this.getUserExistingData();
    window.scrollTo({top:0});
    this.setState(prevState => ({
      editProfileStatus: !prevState.editProfileStatus,
      errors:{}
    }));
  };
  updateProfileData(responseJsonOtpData: any) {
    const response: any = responseJsonOtpData;

    if (response && response.data && response.data.attributes) {
      const attributes = response.data.attributes;

      this.setState({
        editProfileData: {
          fullName: attributes.full_name || defaultUserProfileData.fullName,
          nationality: attributes.nationality|| defaultUserProfileData.nationality,
          dateOfBirth: attributes.date_of_birth || defaultUserProfileData.dateOfBirth,
          userName: attributes.user_name || defaultUserProfileData.userName,
          crNumber: attributes.user_id || defaultUserProfileData.crNumber,
          mobileNo: attributes.full_phone_number || defaultUserProfileData.mobileNo,
        }
      });
    }
  }
  handleApiResponse(apiRequestCallCreateProfile: string, responseJsonOtpData: any) {
    switch (apiRequestCallCreateProfile) {
      case this.CreateProfileAPiCall:
        this.SetNewPassowrdApiResponse(responseJsonOtpData);
        break;


      case this.createEmployeeAccountApiCall:
        this.SetNewPassowrdApiResponse(responseJsonOtpData);
        break;

      case this.getUserDataCallId:
        this.updateProfileData(responseJsonOtpData);
        break;
      case this.putUserUpdatedDataCallId:
        this.goToProfile(responseJsonOtpData);
        break;

      default:
      
        break;
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  goToProfile(responseJsonOtpData: ResponseJsonOtpData ) {
    if (responseJsonOtpData.message === "Account was successfully updated") {
      window.scrollTo({top:0});
        this.setState({ editProfileStatus: true });
    } 
}
  obscurePassword=(password: string)=> {
    return password.replace(/./g, ".");
  }
  convertDOB =(dateString:string)=> {
    const dateOfBirth = new Date(dateString); 
    const birthDay = dateOfBirth.getDate();
    const birthMonth = dateOfBirth.getMonth() + 1; 
    const birthYear = dateOfBirth.getFullYear();
    const formattedDOB = `${birthDay}/${birthMonth}/${birthYear}`;

    return formattedDOB;
}
}


// Customizable Area End