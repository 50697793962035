import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Box, Grid, InputLabel, Grow, TextField, TextFieldProps } from "@material-ui/core";
import ProjectTextField from "./ProjectTextField";
import ProjectText from "./ProjectText";
import PhoneInput from 'react-phone-input-2'


export function AppHeader() {
  return <View style={styles.container}>
      <Text style={styles.text}>Welcome to React Native Web + Monorepo</Text>
    </View>
}
export const    MobileNoGrid = (
  setFieldValue: (
          field: string,
          value: string,
          shouldValidate?: boolean
        ) => void,
        errors: { MobileNo?: string },
        touched: { MobileNo?: string | boolean },
        values: { MobileNo: string },
        SetPhoneNumber:any,
        phonenoError:string,
        isDisabled?:boolean
) => {
      return (
    <Grid item xs={12}>
      <InputLabel htmlFor="MobileNo">
        <ProjectText variant="subtitle1"style={{
                        fontWeight: 700, color: "#031417",fontFamily:"Montserrat-bold",fontSize:"16px"
                      }}>
          Mobile No.
        </ProjectText>
      </InputLabel>
      <Box style={{ position: "relative" }}>
        <PhoneInput
          country={"in"}
          placeholder={"Enter Mobile No."}
          inputStyle={{
            width: "100%",
          }}
          value={values.MobileNo}
          inputProps={{
            required: true,
            id: "MobileNo",
            "data-test-id": "PhoneNumber"
          }}
          disableSearchIcon={true}
           jumpCursorToEnd={true}
          countryCodeEditable={false}
          disabled={isDisabled}
          inputClass={touched.MobileNo && errors.MobileNo ? "PhoneInputPackgeClass PhoneInputPackgeClassErr" : "PhoneInputPackgeClass" }
          onChange={(value, data, event, formattedValue) =>
            SetPhoneNumber(
                      value,
                      data,
                      event,
                      formattedValue,
                      setFieldValue
                        )
                      }
        />
      </Box>
      {touched.MobileNo && errors.MobileNo && (
        <Grow
          in={errors.MobileNo !== undefined}
          style={{ transformOrigin: "0 0 0" }}
          {...(!errors.MobileNo ? { timeout: 800 } : {})}
        >
          <ProjectText type="error">{errors.MobileNo}</ProjectText>
        </Grow>
      )}
      {touched.MobileNo && phonenoError && (
         <Grow
         in={phonenoError !== undefined}
         style={{ transformOrigin: "0 0 0" }}
         {...(!phonenoError ? { timeout: 800 } : {})}
       >
         <ProjectText type="error">{phonenoError}</ProjectText>
        </Grow>
      )}
    </Grid>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    backgroundColor: '#f3f3f3',
  },
  text: {
   fontSize: 36,
   fontWeight: '600',
  }
})
