import React from "react";
// Customizable Area Start
import ResetPasswordController, { Props } from "./ResetPasswordController.web";
import { withRouter } from "react-router-dom";
import { styled } from "@material-ui/styles";
import { Box, Grid, InputLabel, Button, Grow, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Formik } from "formik";
import ProjectTextField from "../../../components/src/ProjectTextField";
import ProjectText from '../../../components/src/ProjectText';
import { ContainedButton } from '../../../components/src/GlobalButton'
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End



export class ResetPassword extends ResetPasswordController {
 
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderPasswordField = (errors: { password?: string }, touched: { password?: string | boolean }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Box className="mb-8">
                <StyledBox>
                    <ProjectTextField
                        error={(errors.password !== undefined && touched.password !== undefined) || this.state.PasswordError}
                        data-test-id="Passwordtest"
                        type={this.state.TextFiledType ? 'text' : 'password'}
                        name="password"
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    data-test-id="SetTextFiledType"
                                    edge="end"
                                    onClick={() => {
                                        this.SetTextFiledType()
                                    }}
                                >
                                    {this.state.TextFiledType ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            this.SetPassword(e, setFieldValue)
                        }}
                    />
                </StyledBox>
                {(touched.password && errors.password) &&
                    <Grow
                        in={errors.password !== undefined}
                        style={{ transformOrigin: '0 0 0' }}

                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.password}</ProjectText>
                    </Grow>
                }
                {this.state.PasswordError &&
                    <Grow
                        data-test-id="growTest"
                        in={this.state.PasswordError}
                        style={{ transformOrigin: '0 0 0' }}
                    >
                        <ProjectText type="error" TextSize="12px" fontfamily="Montserrat-SemiBold" fontweight="600">{this.state.PassWordErrorText}</ProjectText>
                    </Grow>
                }
                {!this.state.PasswordError && this.state.PassWordErrorText && <ProjectText type="error" className="errorText" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>}
            </Box>
        );
    }
    renderNewPassword = (errors: { newPassWord?: string }, touched: { newPassWord?: string | boolean }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Box className="mb-8">
                <StyledBox>
                    <ProjectTextField
                        error={(errors.newPassWord !== undefined && touched.newPassWord !== undefined)}
                        name="newPassWord"
                        type={this.state.TextFiledType ? 'text' : 'password'}
                        data-test-id="newPassWord"
                        fullWidth
                        inputProps={{ maxLength: 12 }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    id="SetTextFiledType"
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() => {
                                        this.SetTextFiledType()
                                    }}
                                >
                                    {this.state.TextFiledType ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }

                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            this.SetnewPassword(e, setFieldValue)
                        }}
                    />
                </StyledBox>
                {(touched.newPassWord && errors.newPassWord) &&
                    <Grow
                        in={errors.newPassWord !== undefined}
                        style={{ transformOrigin: '0 0 0' }}

                    >

                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.newPassWord}</ProjectText>
                    </Grow>
                }
                {this.state.PasswordError &&
                    <Grow

                        in={this.state.PasswordError}
                        style={{ transformOrigin: '0 0 0' }}

                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" fontweight="600" TextSize="12px" >{this.state.PassWordErrorText}</ProjectText>
                    </Grow>
                }
                {!this.state.PasswordError && this.state.PassWordErrorText && <ProjectText type="error" className="errorText" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>}
            </Box>
        );
    }
    renderConfirmNewPassword = (errors: { confirmNewPassword?: string }, touched: { confirmNewPassword?: string | boolean }, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        return (
            <Box className="mb-8">
                <StyledBox>
                    <ProjectTextField
                        error={(errors.confirmNewPassword !== undefined && touched.confirmNewPassword !== undefined) || this.state.PasswordError}
                        id="Password"
                        type={this.state.TextFiledType ? 'text' : 'password'}
                        name="confirmNewPassword"
                        data-test-id="confirmpassword"
                        fullWidth
                        inputProps={{
                            maxLength: 12,
                            required: true,
                            id: "confirmpassword"
                        }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    id="SetTextFiledType"
                                    aria-label="toggle password visibility"
                                    edge="end"
                                    onClick={() => {
                                        this.SetTextFiledType()
                                    }}
                                >
                                    {this.state.TextFiledType ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            this.SetconfirmNewPassword(e, setFieldValue)
                        }}
                    />
                </StyledBox>
                {(touched.confirmNewPassword && errors.confirmNewPassword) &&
                    <Grow
                        style={{ transformOrigin: '0 0 0' }}
                        in={errors.confirmNewPassword !== undefined}

                        {...(!errors.confirmNewPassword ? { timeout: 800 } : {})}
                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600" className="errorText">{errors.confirmNewPassword}</ProjectText>
                    </Grow>
                }
                {this.state.PasswordError &&
                    <Grow
                        in={this.state.PasswordError}

                        style={{ transformOrigin: '0 0 0' }}
                        {...(this.state.PasswordError ? { timeout: 800 } : {})}
                    >
                        <ProjectText type="error" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>
                    </Grow>
                }
                {!this.state.PasswordError && this.state.PassWordErrorText && <ProjectText type="error" className="errorText" fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600">{this.state.PassWordErrorText}</ProjectText>}
            </Box>
        );
    }
    // Customizable Area End
  render() {
    // Customizable Area Start
    return (
        <>        
        <ProjectSideBar pageThisVar={this}>
        <HomePageStyle>
        <Formik
              initialValues={{
                  password: "",
                  newPassWord:"",
                  confirmNewPassword:""
              }}
              validateOnChange={true}
              validateOnBlur={true}
             data-test-id = "hellobutton"
              onSubmit={this.onButton}
          >
              {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => (
                  <form onSubmit={handleSubmit} autoComplete="off" className="loginContainer" style={{ width:"auto", height:"auto",}}>
                      <StyledBox>
                      <Grid container spacing={2}>
                          <Grid item xs={12}>
                              <StyledBox>
                              <ProjectText variant="h4" className="LoginText" fontfamily="Montserrat-Bold" textcolor="#031417" gutterBottom>
                              {this.state.newPasswordscreen ? "Set New Password":"Reset Password"}
                              </ProjectText>
                              </StyledBox>
                              <StyledBox>
                              <ProjectText variant="subtitle2" className="DescriptionText" TextSize="14px" fontweight="400" textcolor="#333" gutterBottom>
                              Please enter your current password
                              </ProjectText>
                              </StyledBox>
                          </Grid>
                          {this.state.newPasswordscreen ?
                          <>
                          <Grid item xs={12}>
                          <InputLabel htmlFor="newPassWord">
                              <ProjectText variant="subtitle1"  className="inputName" fontweight="700" fontfamily="Montserrat-Bold"  textcolor="#031417">
                              Please enter your new password
                              </ProjectText>
                          </InputLabel>
                          {this.renderNewPassword(errors, touched, setFieldValue)}
                         
                      </Grid>
                      <Grid item xs={12}>
                      <InputLabel htmlFor="confirmNewPassword">
                          <ProjectText className="inputName" variant="subtitle1" fontweight="700" fontfamily="Montserrat-Bold"  textcolor="#031417">
                          Please confirm your new password
                          </ProjectText>
                      </InputLabel>
                      {this.renderConfirmNewPassword(errors, touched, setFieldValue)}             
                    </Grid>
                    </>
                    :
                          <Grid item xs={12}>
                              <InputLabel htmlFor="Password">
                                  <ProjectText className="inputName" variant="subtitle1" fontweight="700" fontfamily="Montserrat-Bold"  textcolor="#031417">
                                      Current Password
                                  </ProjectText>
                              </InputLabel>

                              {this.renderPasswordField(errors, touched, setFieldValue)}
                             
                          </Grid>
                          }
                          <StyledBox style={{width: "100%"}}>
                          <Grid item xs={12} style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                              <StyledBox>
                                  <ContainedButton type="submit" bgcolor="success" data-test-id="SubmitButton" fullWidth size="medium" textalign="center" className="loginButton">
                                      Submit
                                  </ContainedButton>
                              </StyledBox>
                          </Grid>
                          </StyledBox>
                          {this.state.PasswordMatch && (
              <Box className="ErrorBoxContainer">
                {this.state.ErrorPopUpType === "Error" ? <Button
                  variant="contained"
                  style={{
                    backgroundColor: "white",
                    color: "#DC2626",
                  }}
                >
                  {this.state.PassWordMatchText}
                </Button> :
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                    }}
                    startIcon={
                      <CheckCircleIcon style={{ color: "#5DBB65" }} />
                    }
                  >
                    {this.state.PassWordMatchText}
                  </Button>
                }
              </Box>
            )}
                      </Grid>
                      </StyledBox>
                  </form>
              )}
          </Formik>
        </HomePageStyle>
      </ProjectSideBar> 
      </>  
     )
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledBox = styled(Box)({
    position: "relative",
    "& .loginContainer": {
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'auto',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'auto',
        },
        '& img': {
            "@media (min-width:360px) and (max-width:449px)": {
                display: "none"
            },
            "@media (min-width:450px) and (max-width:1280px)": {
                display: "none"
            },
        }
    },
    '& .fogot-pass-text': {
        display: "flex", justifyContent: "flex-end", padding: "0px"
    },
    '& .button': {
        width: "100%"
    },
    "& .MuiGrid-container": {
        justifyContent: "center",
        top: "40%",
        left: "50%",
        width: "400px",
        position: "fixed",
        '@media (max-width: 900px)':{
            left: "40%",
            display: "flex",
            width: "400px"
          },
          '@media (max-width: 700px)':{
            left: "20%",
            display: "flex",
            width: "400px",
          },
          '@media (max-width: 450px)':{
            left: "10%",
            display: "flex",
            width: "300px",
          }
    },
    "& .react-tel-input": {
        width: 'inherit',
        "@media (min-width:360px) and (max-width:449px)": {
            width: "inherit"
        },
        "@media(min-width:450px) and (max-width:1280px)": {
            width: "100%"
        },
    },
    "& .MuiInputBase-fullWidth": {
        width: "26.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: "fit-content"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "100%"
        },
    },
    "& .form-control PhoneInputPackgeClass": {
        "@media (min-width:360px) and (max-width:449px)": {
            paddingRight: "15vw"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            paddingRight: "15vw",
            width: "74vw"
        },
    },
    "& .FogotandSignup": {
        fontWeight: "700",
        fontSize: "14px"
    },
    "& .alreadyTxt": {
        fontSize: "14px",
        fontWeight: "400"
    },
    "& .passwordBoxContainer": {
        width: "34.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'max-content',
            height: "7vh"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'max-content',
            height: "7vh"
        },
    },
    "& .PhoneInputBox": {
        width: "26.05vw",
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: '74vw',
        },
    },
    "& .MuiButton-fullWidth": {
        width: '26vw',
        "@media (min-width:360px) and (max-width:449px)": {
            width: '100%',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "100%",
        }
    },
    "& .WithStyles\(ForwardRef\(Button\)\)-root-31": {
        width: '26vw',
        display: 'flex',
        justifyContent: "flex-end !important",
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw"
        },
    },
    "& .Styled\(MuiBox\)-root-13": {
        fontSize: "14px",
        "@media(min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .MuiTypography-alignCenter": {
        fontSize: "14px",
        width: '26.05vw',
        "@media (min-width:360px) and (max-width:449px)": {
            width: '73.90vw',
            display: "flex",
            justifyContent: "center"
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: "74vw",
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .MuiInputLabel-root": {
        "@media (min-width:360px) and (max-width:449px)": {
            width: 'max-content'
        },
        "@media (min-width:450px) and (max-width:1280px)": {
            width: 'max-content'
        },
    },
})
const HomePageStyle = styled("div")({
    "& .resetPasscode": {
        marginTop: "110px",
        gap: "20px",
        position: "relative",
        zIndex: 6
    },
    '& .ButoonContainer': {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "8px",
        marginBottom: "8px"
    },
    '& .PasscodeContainer': {
        display: "flex",
        justifyContent: "center",
        margin: "16px 0 18px 0"
    },
    "& .PasscodeSet": {
        width: "10px !important",
        border: "1px",
        height: "10px",
        margin: "0px 15px",
        outline: "none",
        fontSize: "20px",
        background: "#fff",
        borderColor: " #B6CEE2",
        borderStyle: "solid",
        borderRadius: "5px",
        backgroundColor: "#DBE7F0",
    },
    "& .Passcodeactive": {
        backgroundColor: "#203A4F"
    },
    "& .PasscodeError": {
        color: "red"
    },
    "& .forgotpasswordActive": {
        color: "white",
        backgroundColor: "#3FB98C"
    },
    "& .phoneButton": {
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none"
        }
    },
    "& .clearButton": {
        background: "transparent",
        boxShadow: "none",
        "&:hover": {
            boxShadow: "none"
        }
    },
    "& .passcodeTitle": {
        maxWidth: "340px",
        margin: "auto"
    }
})

export default withRouter(ResetPassword)
// Customizable Area End