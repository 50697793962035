// Customizable Area Start
import { OutlinedInput, OutlinedInputProps } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface propsType extends OutlinedInputProps{
    screenTypeField?:string;
    textColor?:string;
    fontweight?:number
}

const ProjectTextField = styled(OutlinedInput)((props: propsType) => (
    {
        borderColor: "#B6CEE2",
        "& .MuiOutlinedInput-notchedOutline ":{
            borderColor: "#B6CEE2"
        },
        "& .MuiInputBase-input::placeholder":{
            fontFamily:"Montserrat-regular",fontSize:"14px",fontWeight: 400,color:"#666666",opacity:1
            },
        borderRadius: "8px",
        ...(props.error && {
            borderColor: "red"
        }),
        '& .Mui-focused': {
            outline: "none"
        },
        height: "56px",
        fontWeight: props.fontweight ? props.fontweight : 700,
        color: props.textColor ? props.textColor : "#000"
    }))

export default ProjectTextField
// Customizable Area End