// Customizable Area Start
import React from "react";

import DashboardController, { Props, configJSON } from "./DashboardController";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { styled, Box, Paper, Typography, Select, MenuItem, InputLabel, IconButton, Button, Drawer} from "@material-ui/core"
import ProjectText from "../../../components/src/ProjectText";
import CommonTable from "./CommonTable.web";
import { ContainedButton } from "../../../components/src/GlobalButton";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import CloseIcon from '@material-ui/icons/Close';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import ProjectTextField from "../../../components/src/ProjectTextField";
import DatePicker from "react-datepicker";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
  }

  renderSelect = (handler: (value: string) => void, value: string, id?:string) => {
    return <StyledSelect
      onChange={(event: React.ChangeEvent<{ value: unknown }>) => handler(event.target.value as string)}
      value={value}
      data-test-id={id}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right"
        },
        getContentAnchorEl: null,
        keepMounted: true
      }}
    >
      {
        ["Daily", "Weekly", "Monthly", "Yearly"].map((item: string) => <MenuItem value={item} key={item}>{item}</MenuItem>)
      }
    </StyledSelect>
  };

  renderAnalytics = () => {
    return <>
      <ProjectText TextSize="20px" fontweight="600" style={{color:"#0D0D0D",fontFamily:"Montserrat-semibold"}}  >{configJSON.analyticsTxt}</ProjectText>
      <StyledPaper>
        <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.collectionAnalyticsTxt}</ProjectText>
        <ScrollContainer>
          <Box data-test-id="collectionAnalyticsTableID">
            <CommonTable navigation="" id="" tableHead={["Metric", "Time Frame", "Value", "Comparision to previous year", "Average of last 3 months"]} tableBody={this.state.collectionAnalyticsData} rowKey={this.state.rowKey} />
          </Box>
        </ScrollContainer>
      </StyledPaper>
      <StyledTableGroupAnalytics>
        <StyledPaper className="refund-container" >
          <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.refundAnalyticsTxt}</ProjectText>
          <ScrollContainer>
            <Box>
          <CommonTable navigation="" id="" tableHead={["Time Frame", "Number of refunds", "Total refund amount"]} tableBody={[]} rowKey={[]} />
            </Box>
          </ScrollContainer>
        </StyledPaper>
        <StyledPaper className="sales-performance-container" >
          <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.salesPerformanceInsightsTxt}</ProjectText>
          <ScrollContainer>
            <Box>
          <CommonTable navigation="" id="" tableHead={["Shop", "Locations", "Visiting timings", "Number of generated invoices"]} tableBody={[]} rowKey={[]} />
            </Box>
          </ScrollContainer>
        </StyledPaper>
      </StyledTableGroupAnalytics>
    </>
  };

  renderStats = () => {
    return <>
      <ProjectText TextSize="20px" fontweight="600" style={{color:"#0D0D0D",fontFamily:"Montserrat-semibold"}}>{configJSON.statisticsTxt}</ProjectText>
      <StatsGroup>
        <StyledPaper>
          <StatHeader>
            <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.collectionOverviewTxt}</ProjectText>
            <FilterContainer>
              <ProjectText TextSize="16px" fontweight="600" style={{color:"#000000",fontFamily:"Montserrat-semibold"}}>{configJSON.filterByTxt}</ProjectText>
              {this.renderSelect(this.handleStatsCollection, this.state.statsCollectionDuration, "statsCollectionID")}
            </FilterContainer>
          </StatHeader>
          <StatCards>
            <StatCard>
              <ProjectText TextSize="14px" fontweight="600"  style={{color:"#333333",fontFamily:"Montserrat-semibold"}}>{configJSON.totalCollectionTxt}</ProjectText>
              <BoldText>{this.state.statsCollection?.total_number_of_collection}</BoldText>
            </StatCard>
            <StatCard>
              <ProjectText TextSize="14px" fontweight="600"  style={{color:"#333333",fontFamily:"Montserrat-semibold"}}>{configJSON.totalValueTxt}</ProjectText>
              <BoldText>{this.state.statsCollection?.total_collection_amount} {configJSON.sarTxt}</BoldText>
            </StatCard>
          </StatCards>
        </StyledPaper>
        <StyledPaper>
          <StatHeader>
            <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.refundsAnalysisTxt}</ProjectText>
          </StatHeader>
          <StatCards>
            <StatCard>
              <ProjectText TextSize="14px" fontweight="600" style={{color:"#333333",fontFamily:"Montserrat-semibold"}}>{configJSON.totalRefundsProcessedTxt}</ProjectText>
              <BoldText>{this.state.statsRefund?.total_number_of_collection}</BoldText>
            </StatCard>
            <StatCard>
              <ProjectText TextSize="14px" fontweight="600" style={{color:"#333333",fontFamily:"Montserrat-semibold"}}>{configJSON.totalRefundAmountTxt}</ProjectText>
              <BoldText>{this.state.statsRefund?.total_number_of_collection} {configJSON.sarTxt}</BoldText>
            </StatCard>
          </StatCards>
        </StyledPaper>
      </StatsGroup>
    </>
  };

  renderReports = () => {
    return <>
      <ProjectText TextSize="20px" fontweight="600" style={{color:"#0D0D0D",fontFamily:"Montserrat-semibold"}}>{configJSON.reportsTxt}</ProjectText>
      <StyledTableGroupReport>
        <StyledPaper className="item-container">
          <TableHeader>
            <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.collectionSummaryReportTxt}</ProjectText>
            {
              this.renderSelect(this.handleCollectionSummaryReport, this.state.collectionSummaryDuration, "collectionSummaryReportID")
            }
          </TableHeader>
          <ScrollContainer>
            <Box>
          <CommonTable navigation="" id="" tableHead={["Collection amount", "Date", "Merchant name", "Transaction reference"]} tableBody={[]} rowKey={[]} />
            </Box>
          </ScrollContainer>
        </StyledPaper>
        <StyledPaper  className="item-container">
          <TableHeader>
            <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.refundSummaryReportTxt}</ProjectText>
            {
              this.renderSelect(this.handleRefundSummaryReport, this.state.refundSummaryDuration, "refundSummaryReportID")
            }
          </TableHeader>
          <ScrollContainer>
            <Box>
          <CommonTable navigation="" id="" tableHead={["Refund amount", "Date", "Merchant name", "Transaction reference"]} tableBody={[]} rowKey={[]} />
            </Box>
          </ScrollContainer>
        </StyledPaper>
      </StyledTableGroupReport>
      <StyledPaper style={{marginBottom: "80px"}}>
        <TableHeader>
          <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.salesPerformanceReportTxt}</ProjectText>
          {
            this.renderSelect(this.handleSalesPerformanceReport, this.state.salesPerformanceDuration)
          }
        </TableHeader>
        <ScrollContainer>
          <Box>
            <CommonTable navigation="" id="" tableHead={["Number of shop visited", "Locations", "Timings of visit ", "Per driver", "Generated invoices"]} tableBody={[]} rowKey={[]} />
          </Box>
        </ScrollContainer>
      </StyledPaper>
    </>
  };

  renderAdHocReporting = () => {
    return (
      <>
        <StyledHeader >
          <ProjectText TextSize="20px" fontweight="600" style={{color:"#0D0D0D",fontFamily:"Montserrat-semibold"}}>{configJSON.adHocReportingTxt}</ProjectText>
          <ContainedButton bgcolor="success" className="generate-button">{configJSON.generateReportTxt}</ContainedButton>
        </StyledHeader>
        <StyledPaper>
          <TableHeader className="header-items">
            <ProjectText TextSize="18px" fontweight="600" className="HeadingText">{configJSON.collectionSummaryReportTxt}</ProjectText>
            <Box className="header-left">
              <Button data-test-id = "showColumnDrawerBtnID" onClick={() => this.openDrawerhandler(1)}><VisibilityOutlinedIcon style={{color:"#666666"}} /> {configJSON.showHideFieldsTxt}
              </Button>
              <Button data-test-id = "showFilterDrawerBtnID" className="action-container" onClick={() => this.openDrawerhandler(2)}>{configJSON.filterTxt} <FilterListOutlinedIcon style={{color:"#666666"}}/> </Button>
              {this.renderDrawer()}
            </Box>
          </TableHeader>
          <ScrollContainer>
            <Box>
              <CommonTable navigation="" id="" tableHead={
                ["Collection amount",
                  "Date", "Merchant name",
                  "Transaction reference", " Average collection",
                  "Total collection", "Collection  status",
                  "Collection method",
                  "Driver name"]} tableBody={[]} rowKey={[]} />
            </Box>
          </ScrollContainer>
        </StyledPaper>
      </>
    )
  };

  renderDrawer = () => {
    return <Drawer data-test-id="drawerID" anchor={"right"} open={this.state.isDrawerOpen} onClose={this.closeDrawerhandler}>
      <StyledDrawerMain>
        <StyledHeader className="tableHeader">
          <ProjectText TextSize="24px" fontweight="700" style={{fontFamily:"Montserrat-bold",color:"#1A1A1A"}}>{this.state.drawerType === 1 ? configJSON.showHideFieldsTxt : configJSON.filterTxt}</ProjectText>
          <IconButton onClick={this.closeDrawerhandler} data-test-id="drawerCloseIcon" ><CloseIcon /></IconButton>
        </StyledHeader>
        <StyledHeader>
          <ProjectText TextSize="16px" fontweight="700" style={{fontFamily:"Montserrat-semibold",color:"#1E293B",textTransform:'capitalize'}}>{this.state.drawerType === 1 ? configJSON.columnHeaderTxt : configJSON.dateTxt}</ProjectText>
          {this.state.drawerType === 1 && <ContainedButton style={{fontFamily:"Montserrat-semibold",color:"#118799",textTransform:'capitalize'}} onClick={this.hideAllColumnHandler} data-test-id="hideAllBtnId" >{configJSON.hideAll}</ContainedButton>}
        </StyledHeader>
        <Box data-test-id="drawerColumnToggleContainerId" >
          {
            this.state.drawerType === 1 ? this.state.showHideColumnData.map((column: { [key: string]: string | boolean }, index) => (
              <Box className="drawerlistItem" key={index}>
                <ProjectText className="columnName">{column.columnName.toString().replace("_", " ")}</ProjectText>
                <IconButton data-test-id="columnShowBtnID" onClick={() => this.columnShowHideHandler(column.columnName.toString())}>{column.isVisible ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}</IconButton>
              </Box>
            ))
              :
              <form style={{marginTop:"24px"}}>
                <StyledFormControl>
                  <InputLabel className="input-label">{configJSON.startDateTxt}</InputLabel>
                  <DatePicker
                    showIcon
                    icon={<StyledIcon htmlFor="startDate"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3647 3.81818H19.2738C20.2738 3.81818 21.092 4.63636 21.092 5.63636V20.1818C21.092 21.1818 20.2738 22 19.2738 22H4.72834C3.72834 22 2.91016 21.1818 2.91016 20.1818V5.63636C2.91016 4.63636 3.72834 3.81818 4.72834 3.81818H5.63743V2.90909C5.63743 2.40909 6.04652 2 6.54652 2C7.04652 2 7.45561 2.40909 7.45561 2.90909V3.81818H16.5465V2.90909C16.5465 2.40909 16.9556 2 17.4556 2C17.9556 2 18.3647 2.40909 18.3647 2.90909V3.81818ZM5.63743 20.1818H18.3647C18.8647 20.1818 19.2738 19.7727 19.2738 19.2727V8.36364H4.72834V19.2727C4.72834 19.7727 5.13743 20.1818 5.63743 20.1818Z" fill="#999999" />
                    </svg></StyledIcon>}
                    selected={this.state.startDateValue}
                    onChange={(date: Date) => this.onStartDateChange(date)}
                    dateFormat="dd/MM/yyyy"
                    name="startDate"
                    id="startDate"
                    data-test-id="startDateId"
                    placeholderText={configJSON.drawerDatePlaceholder}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <InputLabel className="input-label">{configJSON.endDateTxt}</InputLabel>
                  <DatePicker
                    showIcon
                    icon={<StyledIcon htmlFor="endDate"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3647 3.81818H19.2738C20.2738 3.81818 21.092 4.63636 21.092 5.63636V20.1818C21.092 21.1818 20.2738 22 19.2738 22H4.72834C3.72834 22 2.91016 21.1818 2.91016 20.1818V5.63636C2.91016 4.63636 3.72834 3.81818 4.72834 3.81818H5.63743V2.90909C5.63743 2.40909 6.04652 2 6.54652 2C7.04652 2 7.45561 2.40909 7.45561 2.90909V3.81818H16.5465V2.90909C16.5465 2.40909 16.9556 2 17.4556 2C17.9556 2 18.3647 2.40909 18.3647 2.90909V3.81818ZM5.63743 20.1818H18.3647C18.8647 20.1818 19.2738 19.7727 19.2738 19.2727V8.36364H4.72834V19.2727C4.72834 19.7727 5.13743 20.1818 5.63743 20.1818Z" fill="#999999" />
                    </svg></StyledIcon>}
                    selected={this.state.endDateValue}
                    onChange={(date: Date) => this.onEndDateChange(date)}
                    dateFormat="dd/MM/yyyy"
                    name="endDate"
                    id="endDate"
                    data-test-id="endDateId"
                    placeholderText={configJSON.drawerDatePlaceholder}
                  />
                </StyledFormControl>
                <StyledFormControl>
                  <InputLabel className="input-label-transaction">{configJSON.transactionTypeTxt}</InputLabel>
                  <ProjectTextField value={this.state.transactionType} onChange={this.onTransactionTypeChange} data-test-id="transactionChangeFieldId" />
                </StyledFormControl>
                <ContainedButton fullWidth disableRipple className="apply-btn">{configJSON.applyFilter}</ContainedButton>
              </form>
          }
        </Box>
      </StyledDrawerMain>
    </Drawer>
  }

  render() {
    return <ProjectSideBar pageThisVar={this} data-test-id = "commonCompID" extraContent={{ tabs: ["Analytics", "Reporting"], activeTab: this.state.activeTab, tabHandler: this.tabHandler }}>
    
        {
          this.state.activeTab === 0 ? <>
            <StyledContainer>
          {this.renderAnalytics()}
        {this.renderStats()}
        {this.renderReports()}
        </StyledContainer>
          </> :
          <>
          <StyledContainerSecond>
          {this.renderAdHocReporting()}
          </StyledContainerSecond>
          </>
        }

    </ProjectSideBar>;
  }
}

const StyledContainer = styled(Box)({
 
  padding:"48px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",

  '@media (max-width: 1061px)': {
    width: 720,
    marginTop:"10px",
    padding:"20px"
  },
  '@media (max-width: 962px)': {
    marginTop:"48px",
    width: 550,
  },
  '@media (max-width: 794px)': {
    width:500,
  },
  '@media (max-width: 494px)': {
    width:400,
  },
  '@media (max-width: 400px)': {
    width:350,
  },
  
  overflowX: "auto",
 
});
const StyledContainerSecond = styled(Box)({
  padding: "48px",
  display: "flex",
  flexDirection: "column",
  gap: "32px",
  overflowX: "auto",
  minWidth: "300px",
  maxWidth: "100%",  

  '@media (max-width: 1494px)': {
    width: "80vw", 
    padding: "20px",
    marginTop: "10px",
  },
  '@media (max-width:  1157px)': {
    marginTop: "10px",
    width: "70vw", 
    padding: "20px",
  },
 
  '@media (max-width: 962px)': {
    width: "70vw", 
    marginTop: "48px",
  },
  '@media (max-width: 794px)': {
    width: "60vw", 
  },
  '@media (max-width: 701px)': {
    width: "90vw",
  },
  '@media (max-width: 494px)': {
    width: "85vw",
  },
  '@media (max-width: 400px)': {
    width: "90vw",
  },
});


const StatsGroup = styled("div")({
  display: "flex",
  gap: "20px",

  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    width:"50%"
  },
  '@media (max-width: 1432px)': {
    flexDirection:'column',
    "& .MuiPaper-root": {
      display: "flex",
      flexDirection: "column",
      width:"100%"
    },
  },
  
  
});

const StyledPaper = styled(Paper)({
  padding: "32px",
  borderRadius: "8px 8px 32px 8px",
  boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.2)",
  border:"1px solid #E6E6E6",
  position: "relative",
  zIndex: 1,
  "& .HeadingText":{
    color:'#1A1A1A',
    fontFamily:"Montserrat-semibold"
  },
  "& .header-items":{
    '@media (max-width: 930px)': {
    display:'block !important'
  },
  },



});



const StyledTableGroupAnalytics = styled("div")({
  display: "flex",
  gap: "32px",
  "& .refund-container":{
   width: '41.85%'
  },
  "& .sales-performance-container":{
   width: '56.18%'
  },
  '@media (max-width: 1432px)': {
    flexDirection:"column",
    "& .refund-container":{
      width: '100%'
     },
     "& .sales-performance-container":{
      width: '100%'
     },
  },

  
});

const StyledTableGroupReport = styled(Box)({
  display: "flex",
  gap: "32px",
 
  "& .item-container":{
    width:"50%"
  },
  '@media (max-width: 1432px)': {
    flexDirection:'column',
    "& .item-container":{
      width:"100%"
    },
  },
 
});

const TableHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",

  "& .header-left": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
  },
 
  
 
  "& .MuiButton-label": {
    gap: "10px",
    alignItems: "center",
    fontSize: "14px",
    color:"#333333",
    fontFamily:"Montserrat-semibold",
    fontWeight: 600,
    textTransform:'none'
  }
});

const ScrollContainer = styled(Box)({
  overflowX: "auto",

  paddingBottom: "20px",
  marginTop: "10px"
});




const StatCards = styled(Box)({
  display: "flex",
  gap: "20px",
  marginTop: "16px",
  height: "140px",
  overflowX: "auto",
  '@media (max-width: 800px)': {
    height: "100%",
  },

});

const StatCard = styled(Box)({
  backgroundColor: "#d3d3d37d",
  borderRadius: "8px",
  padding: "27px 32px",
  flex: "0.5",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "32px"
});

const StatHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between"
});

const StyledSelect = styled(Select)({
  color:"#333333",
  fontFamily:"Montserrat-semibold",
  fontSize:"14px",
  fontWeight:600,
  "& .MUISelect-select:focus": {
    backgroundColor: "transparent"
  },
  "&:focus": {
    outline: "none",
  },
  "&&::before": {
    content: "none",
  },
  "&&::after": {
    content: "none",
  },
});

const BoldText = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color:"#0D0D0D",
  fontFamily:"Montserrat-bold"
});

const FilterContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px"
});

const StyledHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& .generate-button":{

    borderRadius:"4px",
    marginBottom:0,
    padding:"10px 24px 10px 24px",
    "& .MuiButton-label":{
      fontSize:"16px",
      fontWeight:600,
      fontFamily:"Montserrat-semibold",
      color:"#E6E6E6"
    },
  
  },

});

const StyledDrawerMain = styled(Box)({
  width: "619px",
  padding: "100px 40px 0 40px",
  "& .tableHeader": {
    paddingBottom: "30px",
    marginBottom: "30px",
    borderBottom: "1px solid lightgray"
  },
  "& .columnName":{
    fontSize:"14px",
    fontFamily:"Montserrat-regular",
    fontWeight:400,
    color:"#1A1A1A"
  },
  "& .columnName::first-letter": {
    textTransform: "capitalize"
  },
  "& .drawerlistItem": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .apply-btn .MuiButton-label": {
    backgroundColor: "#EDF3F8",
    height: "44px",
    borderRadius: "8px !important",
    color: "#4D4D4D !important",
    fontSize:"16px",
    fontFamily:"Montserrat-regular",
    fontWeight:600,
  },
  "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
    right: 0
  },
  "& .react-datepicker__input-container": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    position: "relative",
    background: "transparent"
  },
  "& .react-datepicker__view-calendar-icon input": {
    flex: 1,
    fontSize: "14px",
    fontWeight: 700,
    borderRadius: "8px",
    height: "100%",
    border: "1px solid #B6CEE2",
    paddingLeft: "14px",
    paddingRight: "14px",
    outline: "none",
    background: "transparent"
  },
  "& .react-datepicker-popper .react-datepicker__triangle":{
    left: "-21px !important",
  },
  "& .react-datepicker__view-calendar-icon input:hover": {
    borderColor: "#000",
  },
  "& .react-datepicker__view-calendar-icon input:focus": {
    borderColor: "rgb(78,98,182)",
    boxShadow: "1px 1px 0px rgb(78,98,182) inset, -1px -1px 0px rgb(78,98,182) inset ",
  },
  "& .react-datepicker__view-calendar-icon input::placeholder": {
    color: "##1A1A1A"
  },
  "& .react-datepicker-wrapper": {
    height: "44px",
    width: "100%"
  },
  "& .dateAndTime .react-datepicker__view-calendar-icon input": {
    borderColor: "red"
  },
  "& .dateAndTime .react-datepicker__view-calendar-icon input:focus": {
    borderColor: "red",
    boxShadow: "1px 1px 0px red inset, -1px -1px 0px red inset ",
  },
  "& .MuiFormLabel-root":{
    color: "#666"
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderRadius: "8px !important"
  }
});

const StyledFormControl = styled(Box)({
  marginBottom: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  "& .input-label":{

    fontFamily:"Montserrat-semibold",
    fontSize:"12px",
    fontWeight:600,
    color:"#666666"
  },
  "& .input-label-transaction":{
    fontFamily:"Montserrat-semibold",
    fontSize:"16px",
    fontWeight:600,
    color:"#333333"
  }
});

const StyledIcon = styled("label")({
  width: "1.5rem",
  height: "1.5rem",
  "& svg": {
    width: "100%",
    height: "100%"
  }
});

// Customizable Area End
