// Customizable Area Start
import React from "react";
import PasswordCreatedController from "./PasswordCreatedController.web";
import PassWordResetLayout from "../../../components/src/PassWordResetLayout";
import 'react-phone-input-2/lib/style.css'
import { styled } from "@material-ui/styles";
import { Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
const center = require("../assets/center.png");
class PasswordCreated extends PasswordCreatedController {
  render() {
    return (
      <PasswordCreatedLayout>
      <PassWordResetLayout 
      BackButtonOnClick={ this.props.history?.goBack?.bind(this)}  
      BackButton Buttontext="PROCEED" 
      ButtonFuncation={this.NavigateToLogin} 
      Descripetion="Password created successfully" 
      data-test-id="proceedID"
      />
      </PasswordCreatedLayout>
    )
  }
}

export default withRouter(PasswordCreated)
const PasswordCreatedLayout = styled(Box)({
  '& .boyImage': {
    '& img': {
      width: '30.47vw',
      height: '59.09vh',
      "@media (min-width:360px) and (max-width: 449px)": {
        width: '100%',
        height: '100%',
      },
      "@media (min-width:450px) and (max-width: 1280px)": {
        width: '100%',
        height: '100%',
      },
    },
  },
})
// Customizable Area End