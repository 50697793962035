// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";

export interface Props extends RouteComponentProps {
  id?: string;
}

interface S {
  token: string;
  CountryCode: string | number,
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  otp: string | number,
  Sendotp: boolean,
  ValidPhoneNumber: boolean,
  apiError: string
}

interface SS {
  id: string;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "sa",
      otp: "",
      Sendotp: false,
      ValidPhoneNumber: false,
      apiError: ""
    };
  }

  OtpSent: string = "";

  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdOtp = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonOtp = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallIdOtp === this.OtpSent) {
        this.OtpSentApiResponse(responseJsonOtp)
      }
      if (apiRequestCallIdOtp === this.NumberIsVaildApiCallId) {
        let data = responseJsonOtp.error ?? responseJsonOtp.errors
        if (data !== undefined) {
          this.setState({
            ValidPhoneNumber: false
          })
        } else {
          this.setState({
            ValidPhoneNumber: true
          })
        }
      }
    }
  }

  OtpSentApiResponse = (responseJsonOtp: { errors: object, error: object, data: { attributes: { pin: string | number } }, meta: { token: string, pin: string | number } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {
      let Responsedata = responseJsonOtp.errors ?? responseJsonOtp.error
      for (const obj of Object.values(Responsedata)) {
        for (const key in obj) {
          this.setState({
            apiError: obj[key]
          })
         
        }
      }
    } else {
      localStorage.setItem("otpScreen", responseJsonOtp?.meta.token)
      localStorage.setItem("forgotOtpScreen", responseJsonOtp?.meta.token)
      this.setState({
        Sendotp: true
      })
      setTimeout(() => {
        this.props.history.push({
          pathname: "/Otp",
          state: responseJsonOtp.data.attributes.pin
        })
      }, 1500);
      localStorage.setItem("forgot", "true")
      localStorage.setItem("LastPath", JSON.stringify(this.props.location.pathname.toString()))
    }
  }

  FoorgotSent = (values: { Phone: string }) => {
    const header = {
      "Content-Type": "application/json",
    };
    localStorage.setItem("PhoneNumber", values?.Phone.toString())

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OtpSent = requestMessage.messageId;
    let Body = {
      "data": {
        "type": "sms_account",
        "attributes": {
          "full_phone_number": values?.Phone
        }
      }
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/otps"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  SetPhoneNumber = (number: string | number, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({ Phone: number.toString(), apiError: "" });
    setFieldValue("Phone", number.toString());
  }


  NumberIsVaildApiCallId: string = ""

  FormSubmit = (values: { Phone: string }) => {
    this.FoorgotSent(values)
  }

  phoneInputClassHandler = (values: { Phone: string }, touched: { Phone?: string | boolean }, errors: { Phone?: string }) => {
    const baseClass = 'PhoneInputPackgeClass';

    let className = baseClass;

    if (!values.Phone && !touched.Phone) {
      className="PhoneInputPackgeClass"
    } else if (errors.Phone && touched.Phone) {
      className = 'PhoneInputPackgeClass PhoneInputPackgeClassErr';
    } else if (!errors.Phone) {
      className = 'PhoneInputPackgeClass ColorSuccess';
    }
    return className
  }
}
// Customizable Area End