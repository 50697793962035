// Customizable Area Start
import React from "react";
import HomePageController from "./HomePageController.web";
import { Box, Grid, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import LoginLayout from "../../../components/src/LoginLayout";
import ProjectText from "../../../components/src/ProjectText";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
const merchant = require("../assets/merchant.png");
const WholeSeller = require("../assets/wholeseller.png");

class HomePage extends HomePageController {
    render() {
        return (
            <HomePageStyle>
                <LoginLayout SecondSide={false}>
                    <Box className="AreSureContainer">
                        <Box data-test-id="HomePage" className="HomepageContainer">
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Box className="AreSure">
                                        <ProjectText className="areYouText" fontweight="700">
                                            Are you
                                        </ProjectText>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Box className="maincontentContainer">
                                <Box className="merchantBox">
                                    <Box className="merchantContainer">
                                        <Box>
                                            <IconButton  disableRipple aria-label="delete" data-test-id="merchant" className="CircleLightBlue">
                                                <img src={merchant} alt="merchent" /></IconButton>
                                            <ProjectText className="userText" space="both" fontweight="600" align="center" style={{ textTransform: "capitalize", fontSize: "24px" }} variant="h4">Merchant</ProjectText>
                                        </Box>
                                    </Box>
                                    <Box className="SubtitleConatainer">
                                        <ProjectText space="both" fontweight="400" className="subtitleClass" variant="h4">( Merchant can access the application</ProjectText>
                                        <ProjectText space="both" fontweight="400" className="subtitleClass" variant="h4">exclusively through the mobile app. )</ProjectText>
                                    </Box>
                                </Box>
                                <Box className="orText">
                                    <ProjectText style={{ fontSize: "16px", textAlign: "center" }} fontweight="700">OR</ProjectText>
                                </Box>
                                <Box className="wholeSalerBox">
                                    <IconButton aria-label="delete" data-test-id="wholesaler" onClick={() => this.SetRole("wholesaler")} className="CircleLightBlueW">
                                        {this.state.Role.toLowerCase() === "wholesaler" &&
                                            <div className="CheckIcon">
                                                <CheckCircleIcon />
                                            </div>
                                        }
                                        <img src={WholeSeller} alt="wholesaler" />
                                    </IconButton>
                                    <ProjectText className="userText" space="both" fontweight="600" align="center" style={{ textTransform: "capitalize", fontSize: "24px" }} variant="h4">Wholesaler</ProjectText>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </LoginLayout>
            </HomePageStyle>
        )
    }
}

export default withRouter(HomePage)

const HomePageStyle = styled(Box)({
    height: "100%",
    "& .HomepageContainer": {
        position: "absolute",
        width: "33.03vw",
        top:"347px",
        left:"33.50vw",
        height:"35.75vh",
        "@media (min-width:360px) and (max-width: 449px)": {
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: "100%",
            top: "23vh",
            left: "1vw",
          },
        "@media (min-width:450px) and (max-width:1280px)":{
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            width: "84vw",
            top: "23vh",
            left: "8vw",
          },
    },
    "& .maincontentContainer": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        position: "relative",
        "@media (min-width:360px) and (max-width: 449px)": {       
            flexDirection: "column",
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            flexDirection: "column",
          },
    },
    "& .orText": {
        position: "absolute",
        top: "32%",
        left: "51%",
        color: "#666",
        "@media (min-width:360px) and (max-width: 449px)": {       
            width: "93%",
            display: "flex",
            position: "initial",
            justifyContent: "center",
            margin:"3%"
        },
        "@media (min-width:450px) and (max-width:1280px)":{
            width: "93%",
            display: "flex",
            position: "initial",
            justifyContent: "center",
            margin:"3%"
        },
    },
    "& .wholeSalerBox":{
        position:"relative",
        "@media (min-width:360px) and (max-width: 449px)": {       
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column"
          },

    },
    "& .orText .MuiTypography-root":{
        fontFamily: "Montserrat-bold",
    },
    '& .AreSureContainer': {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .AreSure": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "34px",
    },
    "& .AreSure .MuiTypography-root":{
        fontFamily: "Montserrat-bold",
    },
    "& .areYouText":{
        fontSize: "36px",
        lineHeight: "44px",
        width: "fit-content",
        textAlign: "center", 
        textTransform: "uppercase",
        "@media (min-width:360px) and (max-width: 449px)": {       
            fontSize: "24px",
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            fontSize: "27px",
          },
    },
    "& .ChilderContainer": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%"
    },
    "& .CircleLightBlue": {
        width: '164px',
        height: '164px',
        backgroundColor: "#E8FAFC",
        cursor: "default",
        "@media (min-width:360px) and (max-width: 449px)": {       
            width: '96px',
            height: '96px',
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            width: '96px',
            height: '96px',
          },
    },
    "& .CircleLightBlueW": {
        width: '164px',
        height: '164px',
        backgroundColor: " #ecf9f4",
        "@media (min-width:360px) and (max-width: 449px)": {       
            width: '96px',
            height: '96px',
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            width: '96px',
            height: '96px',
          },
    },
    "& .CheckIcon": {
        position: "absolute",
        top: "22px",
        right: "20px !important",
        "& .MuiSvgIcon-root": {
            fontSize: "26px"
        }
    },
    "& .subtitleClass": {
        fontSize: "14px",
        "@media (min-width:360px) and (max-width: 449px)": {       
            textAlign:"center"
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            textAlign:"center"
          },
    },
    '& .merchantContainer': {
        display: "flex",
        justifyContent: 'center'
    },
    '& .merchantBox': {
        position:"relative",
        "@media (min-width:360px) and (max-width: 449px)": {       
            width:"100%"
          },
          "@media (min-width:450px) and (max-width:1280px)":{
            width:"100%",
            display: "flex",
            marginTop: "2%",
            alignItems: "center",
            flexDirection: "column",
          },
        
    },
    "& .userText":{
        lineHeight: "32px"
    },
    "& .contentContainer":{
        position: "initial"
    }
})

// Customizable Area End