import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  correctPin: string;
  showRemoveButton: boolean;
  enteredPin: string;
  showCompletedButton: boolean;
  passCode: Array<{ value: string }>,
  passCodeFalse: boolean,
  ErrorText: boolean,
  ForogotButtonActive: boolean,
  passcodeAttempet: number,
  seconds: number,
  popVisible:boolean,
  ErrorPopUpType:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PasscodeLockController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  pinviewRef = React.createRef();
  pin: any;
  apiConfirmPasscodeID = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.NavigationMessage),
      getName(MessageEnum.NavigationTargetMessage),
      getName(MessageEnum.NavigationPropsMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      correctPin: "56771",
      showRemoveButton: false,
      showCompletedButton: false,
      enteredPin: "",
      passCode: [{ value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }, { value: "" }],
      passCodeFalse: false,
      ErrorText: false,
      ForogotButtonActive: false,
      passcodeAttempet: 0,
      seconds: 30,
      popVisible:false,
      ErrorPopUpType:""
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        this.showAlert('Error', 'Internal Server Error')
        return;
      }
      if (responseJson && responseJson.message) {
        
        this.apiSuccess(apiRequestCallId)
      } else if (responseJson && responseJson.errors) {
        this.apiFailure(apiRequestCallId)
      }
    };

    // Customizable Area End
  }

  // Customizable Area Start

  apiCall = async (data: { [key: string]: string | { [key: string]: string | number } }) => {
    let { contentType, method, endPoint, body } = data;
    let token = await getStorageData("otpScreen");
    let header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  }

  SetPasscode = (val: string) => {
    let passCode = [...this.state.passCode]
    let findIndex: number = this.state.passCode.findIndex((i: { value: string }) => i.value === "")
    passCode[findIndex === -1 ? 5 : findIndex].value = val
    this.setState({
      passCode,
    }, () => {
      let AllAnwserOrNot = this.state.passCode.filter((item: { value: string }) => item.value === "").length === 0
      if (AllAnwserOrNot) {
        this.confirmPasscode();
      }
    })
  };

  ClearLastDigit = () => {
    let passCode = [...this.state.passCode]
    let findIndex = this.state.passCode.findIndex((i: { value: string }) => i.value === "")
    if (findIndex === -1) {
      findIndex = 5
    } else if (findIndex === 0) {
      findIndex = 0
    } else {
      findIndex = findIndex - 1
    }
    passCode[findIndex].value = ""
    this.setState({
      passCode
    })
  };

  confirmPasscode = async () => {
    const passcode = this.state.passCode.map((item: { value: string }) => item.value).join("").toString()
    this.apiConfirmPasscodeID = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.postApiMethod,
      endPoint: configJSON.confirmPasscodeApiEndPoint,
      body: {
        "passcode": Number(passcode)
      }
    })
  };

  apiSuccess = async (apiCallID: string) => {
    if (apiCallID === this.apiConfirmPasscodeID) {
      let type = await getStorageData("userType");
      if(type === "SmsAccount"){
        this.goToDashboardScreen("Dashboard")
      }
      else{
        this.goToDashboardScreen("GenerateQrCode")
      }     
    }
  };

  apiFailure = (apiCallID: string) => {
    if (apiCallID === this.apiConfirmPasscodeID) {
      this.confiormPasscodeApiCallResponse();
    }
  };

  goToDashboardScreen = (navigate:string) => {
    const navigateToPage = new Message(getName(MessageEnum.NavigationMessage));
    navigateToPage.addData(getName(MessageEnum.NavigationTargetMessage), navigate);
    navigateToPage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateToPage);
  };

  goToForgotPasscodeScreen = () => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), "ForgotPasscode");
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  confiormPasscodeApiCallResponse = () => {
    this.setState({
      seconds: 30,
      passcodeAttempet: this.state.passcodeAttempet + 1,
      ErrorText: true,
      passCode: this.state.passCode.map((item: { value: string }) => {
        return { ...item, value: "" }
      })
    }, () => {
      this.AfterStateSet()
    })
  };

  AfterStateSet = () => {
    if (this.state.passcodeAttempet > 2) {
      this.TimeFor30Sec()
      this.setState({
        ErrorText: false,
        ForogotButtonActive: true
      })
    }
  };

  TimeFor30Sec = () => {
    this.setState({
      passCodeFalse: true,
      popVisible: true,
      ErrorPopUpType: "Error"
    },()=> {
      let popupTimeOut = setTimeout(()=> {
      clearTimeout(popupTimeOut)
        this.setState({
          popVisible: false,
          ErrorPopUpType: ""
        })
      }, 5000)
    })
    const Timer = setInterval(() => {
      if (this.state.seconds !== 0) {
        this.setState({
          seconds: this.state.seconds - 1
        })
      }
      if (this.state.seconds === 0) {
        clearInterval(Timer)
        this.setState({
          seconds: 30,
          passCodeFalse: false,
          passcodeAttempet: 0
        })
      }
    }, 1000)
  };

  validatePin(enteredPin: string) {
    this.setState({
      enteredPin: enteredPin,
      showRemoveButton: enteredPin.length > 0,
      showCompletedButton: enteredPin.length === 5,
    });
  }

  setEnteredPin(value: any) {
    console.log("entered pin value::" + value);
    this.validatePin(value);
  }


handleButtonPress(key: any) {
    if (key === "custom_left") {
      //@ts-ignore
      this.pinviewRef.current.clear();
    }
    if (key === "custom_right") {
      if (this.state.correctPin === this.state.enteredPin) {
        this.showAlert("", "Correct Pin Entry Success");
      } else {
        this.showAlert("", "Incorrect Pin. Try Again.");
        //@ts-ignore
        this.pinviewRef.current.clearAll();
      }
    }
  }
  // Customizable Area End
}
