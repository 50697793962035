// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { RouteComponentProps } from "react-router";
import { setStorageData } from "../../../framework/src/Utilities";
export interface Props extends RouteComponentProps {
  id?: string;
}

export const configJSON = require("./config");

interface S {
  token: string;
  termsCondsId: string;
  isLoading: boolean;
  Phone: string | number,
  CountryCode: string | number,
  PhoneInputBorder: boolean,
  Password: string,
  TextFiledType: boolean,
  TextFiledTypeForConfirm: boolean,
  otp: Array<string> | string,
  Sendotp: boolean,
  VerifyCode: Array<string> | string,
  NewOtp: Array<string> | string,
  OtpError: boolean,
  OtpErrorText: string
}

interface SS {
  id: string;
}

export default class ForgotPasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  OtpConform: string = "";
  FotgotOtpConform: string = "";
  SignUpResendOtpAPiCallId: string = "";
  ForgotPasswordResendOtpApiCallFunctionId: string = "";


  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      token: "",
      termsCondsId: "",
      isLoading: false,
      Phone: "",
      CountryCode: "",
      PhoneInputBorder: false,
      Password: "",
      TextFiledType: false,
      TextFiledTypeForConfirm: false,
      otp: "",
      Sendotp: false,
      VerifyCode: "",
      OtpErrorText: "Didn’t receive the OTP?",
      NewOtp: `${localStorage.getItem("NewOtp")}`,
      OtpError: false
    };
  }

  SetOtp = (event: string, setFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
    this.setState({
      otp: event,
      OtpError: false,
      OtpErrorText: "Didn’t receive the OTP?"
    })
    setFieldValue('otp', event)
  }

  VerifyCode = (values: { otp: string }) => {
    const forhotpassword = localStorage.getItem("LastPath")?.toLowerCase()?.includes("/signup")
      if (forhotpassword) {
        this.LoginApiFuncation(values)
      } else {
        this.ForgotPassword(values)
      }
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallIdOtp = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJsonOtp = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallIdOtp === this.OtpConform) {
        this.OtpConformApiResponse(responseJsonOtp)
      }
      if (apiRequestCallIdOtp === this.FotgotOtpConform) {
        this.FotgotOtpConformApiResponse(responseJsonOtp)
      }
      if (apiRequestCallIdOtp === this.ForgotPasswordResendOtpApiCallFunctionId) {
        this.ForgotPasswordResendOtpResponse(responseJsonOtp)
      }
      if (apiRequestCallIdOtp === this.SignUpResendOtpAPiCallId) {
        this.SignUpResendOtpAPiResponse(responseJsonOtp)
      }
    }
  }


  SignUpResendOtpAPiResponse = (responseJsonOtp: { errors: object, data: { attributes: { pin: string } }, error: object, meta: { token: string, pin: string } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {

          this.setState({
            otp: "",
            isLoading: false
          })
    } else {
      localStorage.setItem("otpScreen", responseJsonOtp.meta.token)
      localStorage.setItem("OtpNew", responseJsonOtp.meta.pin)
      this.setState({
        otp: "",
        isLoading: false
      })
    }
  }
  ForgotPasswordResendOtpResponse = (responseJsonOtp: { errors: object, data: { attributes: { pin: string } }, error: object, meta: { token: string } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {
          setTimeout(() => {
            this.setState({
              otp: "",
              isLoading: false
            })
          }, 1000);
      
    } else {
      localStorage.setItem("otpScreen", responseJsonOtp.meta.token)
      localStorage.setItem("forgotOtpScreen", responseJsonOtp.meta.token)
      localStorage.setItem("OtpNew", responseJsonOtp.data.attributes.pin)
      setTimeout(() => {
        this.setState({
          otp: "",
          isLoading: false
        })
      }, 1000);
    }
  }
  OtpConformApiResponse = async(responseJsonOtp: { errors: object, error: object, meta: {account_detail:object, token: string } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {
      this.setState({
        OtpError: true,
        OtpErrorText: "Invalid or incorrect OTP."
      })
    } else {
      localStorage.setItem("newpassword", responseJsonOtp.meta.token)
      await setStorageData("accountDetails",JSON.stringify(responseJsonOtp.meta.account_detail))
  
      this.setState({
        OtpError: false
      })
      this.props.history.replace({
        pathname: "/NewPasswordWeb",
        state: responseJsonOtp
      })
    }
  }

  FotgotOtpConformApiResponse = (responseJsonOtp: { errors: object, error: object, meta: { token: string } }) => {
    if (responseJsonOtp.errors || responseJsonOtp.error) {
      this.setState({
        OtpError: true,
        OtpErrorText: "Invalid or incorrect OTP"
      })
    } else {
      this.setState({
        OtpError: false
      })
      this.props.history.replace({
        pathname: "/NewPasswordWeb",
        state: responseJsonOtp
      })
    }
  }

  ResendOtpAPiCallFuncation = () => {
    const forhotpassword = localStorage.getItem("LastPath")?.includes("/signup")
    this.setState({
      OtpError: false,
      OtpErrorText:"Didn’t receive the OTP?"
    })
    if (forhotpassword) {
      this.SignUpResendOtpAPiCall()
    } else {
      this.ForgotPasswordResendOtpApiCallFunction()
    }
  }

  ForgotPasswordResendOtpApiCallFunction = () => {
    const header = {
      "Content-Type": "application/json",
    };
    this.setState({
      otp: "",
      isLoading: true
    })

    const ForgotPasswordResendrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ForgotPasswordResendOtpApiCallFunctionId = ForgotPasswordResendrequestMessage.messageId;
    let Body = {
      "data": {
        "type": "sms_account",
        "attributes": { "full_phone_number": localStorage.getItem("PhoneNumber") }
      }
    }
    ForgotPasswordResendrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/otps"
    );

    ForgotPasswordResendrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    ForgotPasswordResendrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    ForgotPasswordResendrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(ForgotPasswordResendrequestMessage.id, ForgotPasswordResendrequestMessage);
  };

  SignUpResendOtpAPiCall = () => {
    const header = {
      "Content-Type": "application/json",
    };
    this.setState({
      otp: "",
      isLoading: true
    })

    const SignUpResendMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.SignUpResendOtpAPiCallId = SignUpResendMessage.messageId;
    let Body = {
      "data": {
        "attributes": {
          "full_phone_number": localStorage.getItem("PhoneNumber"),
        }
      }
    }
    SignUpResendMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/send_otps"
    );

    SignUpResendMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    SignUpResendMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    SignUpResendMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(SignUpResendMessage.id, SignUpResendMessage);
  };

  LoginApiFuncation = async (values: { otp: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const LoginApirequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.OtpConform = LoginApirequestMessage.messageId;
    let Body = {
      "pin": values.otp,
      "token": localStorage.getItem("otpScreen")
    }
    LoginApirequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/account_block/accounts/sms_confirmations"
    );

    LoginApirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    LoginApirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    LoginApirequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(LoginApirequestMessage.id, LoginApirequestMessage);
  };

  ForgotPassword = async (values: { otp: string }) => {
    const header = {
      "Content-Type": "application/json",
    };

    const ForgotrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FotgotOtpConform = ForgotrequestMessage.messageId;
    let Body = {
      "data": {
        "token": localStorage.getItem("otpScreen"),
        "otp_code": Number(values.otp)
      }
    }
    ForgotrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_forgot_password/otp_confirmations"
    );

    ForgotrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    ForgotrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(Body)
    );

    ForgotrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(ForgotrequestMessage.id, ForgotrequestMessage);
  };
}
// Customizable Area End