export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const bL = require("../assets/icons/bL.png");
export const bR = require("../assets/icons/bR.png");
export const edit = require("../assets/icons/edit.png");
export const email= require("../assets/icons/email.png");
export const greenBackground = require("../assets/icons/greenBackground.png");

export const lock= require("../assets/icons/lockicon.svg");
export const mapPoint= require("../assets/icons/location.svg");
export const note= require("../assets/icons/note.png");
export const phone= require("../assets/icons/phoneicon2.svg");
export const profileIcon= require("../assets/icons/cricon.svg");
export const someIcon= require("../assets/icons/dob.svg");

export const bgImage = require("../assets/bgimage.png")