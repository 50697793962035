import React, { Component, CSSProperties } from "react";
import {
  bgImage,
  bL,
  bR,
  edit,
  email,
  greenBackground,
  lock,
  mapPoint,
  note,
  phone,
  profileIcon,
  someIcon,
} from "./assets";
import { IconButton, styled, Box } from "@material-ui/core";
interface ProfileViewProps {
  userfullName: string;
  userNickname: string;
  userNationality: string;
  userDoB: string;
  userCrNumber: string;
  userMobileNo: string;
  userPassword: string;
  onOpenFromView: () => void;
  obscurePassword: (password: string) => any;
  convertDOB: (dateString: any) => any;
}
class ProfileFrontView extends Component<ProfileViewProps, {}> {
  handleDragStart = (e: any) => {
    e.preventDefault();
  };
  render() {
    const {
      userfullName,
      userNickname,
      userNationality,
      userMobileNo,
      userDoB,
      userCrNumber,
      userPassword,
      onOpenFromView,
      obscurePassword,
      convertDOB,


    } = this.props;

    return (

      <div style={styles.container}  >

        <StyledDiv  >
          <div className="mainConatiner">
            <img
              onDragStart={this.handleDragStart}
              onClick={this.handleDragStart}
              src={bgImage}
              className="bgImage"
            />

            <IconButton type="submit" className="editbutton" onClick={onOpenFromView}>
              <img
                src={edit}
                alt="edit"
                onDragStart={this.handleDragStart}
                onClick={this.handleDragStart}
                className="editIcon"
              />
              <div style={{ fontSize: "14px",fontWeight:400, color: "#4D4D4D", fontFamily: "Montserrat-Regular", marginLeft: '5px' }}>
                Edit profile
              </div>
            </IconButton>

            <div className="item-container">
              <div className="fullname">{userfullName}</div>
              <div className="username">{userNickname}</div>
            </div>

            <div className="details-container">
              <div className="item-details">
                <img
                  src={mapPoint}
                  alt="pointIcon"
                  onDragStart={this.handleDragStart}
                  onClick={this.handleDragStart}
                  className="item-details-icon"
                />
                <div style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Montserrat-Regular", textAlign: "center" }}>
                  {userNationality}
                </div>
              </div>

              <div className="item-details">
                <img
                  src={someIcon}
                  alt="pointIcon"
                  onDragStart={this.handleDragStart}
                  onClick={this.handleDragStart}
                  className="item-details-icon"
                />
                <div style={{ fontSize: "14px", textAlign: "center", fontWeight: 400, fontFamily: "Montserrat-Regular" }}>
                  {convertDOB(userDoB)}
                </div>
              </div>

              <div className="item-details">
                <img
                  src={profileIcon}
                  alt="pointIcon"
                  onDragStart={this.handleDragStart}
                  onClick={this.handleDragStart}
                  className="item-details-icon "
                />
                <div style={{ fontSize: "14px", textAlign: "center", fontWeight: 400, fontFamily: "Montserrat-Regular" }}>
                  {userCrNumber}
                </div>
              </div>
            </div>
          </div>


          <div className="first-container">
            <div className="second-container">
              <div className="user-details-container">
                <div className="user-details-items">
                  <img

                    src={phone}
                    alt="phone_icon"

                    onDragStart={this.handleDragStart}
                    onClick={this.handleDragStart}
                    className="img1"
                  />
                  <div className="user-details-items-container">
                    <div className="user-details-items-sub">MOBILE NO.</div>
                    <div className="items-user-text">{"+" + userMobileNo}</div>
                  </div>
                </div>
                <img

                  src={note}
                  alt="note_icon"

                  onDragStart={this.handleDragStart}
                  onClick={this.handleDragStart}
                  className="item-user-note"
                />
              </div>

              <div className="user-details-container">
                <div className="user-details-items">
                  <img
                    src={lock}
                    alt="lock_icon"

                    onDragStart={this.handleDragStart}
                    onClick={this.handleDragStart}
                    className="img1"
                  />
                  <div className="user-details-items-container">
                    <div className="user-details-items-sub">PASSWORD</div>
                    <div className="items-user-text"> {obscurePassword(userPassword)}</div>
                  </div>
                </div>
                <img

                  src={note}
                  alt="note_icon"

                  onDragStart={this.handleDragStart}
                  onClick={this.handleDragStart}
                  className="item-user-note"
                />
              </div>
            </div>
          </div>

        </StyledDiv>
      </div>

    );
  }
}
const StyledDiv = styled(Box)(({ theme }) => ({

  fontFamily: "Montserrat-Regular",
  overflow: 'hidden',
  "@media (min-width:601px) and (max-width: 1280px)": {
    width: "500px"
  },
  "@media (max-width: 600px)": {
    width: "100vw"
  },
  "& .mainConatiner": {

    overflow: 'hidden',
    position: 'relative',
    // minHeight: '294px',
    height:"294px",
    width: '800px',
    marginTop: "10%",

    flexDirection: 'column',
    alignItems: 'start',
    fontSize: '14px',
    color: 'var(--Basic-White, #fff)',
    // padding: " 24px 32px 24px 80px",
    fontWeight: 400,
    // paddingTop: '24px',
    display: 'flex',
    // paddingRight: '24px',
    borderBottomRightRadius: '43px',
    fontFamily: "Montserrat-Regular",
    "@media (max-width: 1280px)": {
      width: "500px",
      height:"100%"
      // padding: " 24px 32px 24px 0px",
    },
    '@media (max-width: 600px)': {
      width: "100vw",
      // width: "500px",
      // height:"100%"
    }

  },

  "& .bgImage": {
    overflow: 'hidden',
    position: 'absolute',
    inset: 0,
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    borderBottomRightRadius: '43px',
  },
  "& .editbutton": {
    position: 'relative',
    justifyContent: 'center',
    borderRadius: '4px',
    radius: "4px",
    width:119,
    height:32,
    alignSelf: 'end',
    display: 'flex',
    backgroundColor: '#EDF3F8',
    gap: '6px',
    padding: '6px 10px 6px 10px',
    marginTop:"24px",
    marginRight:"32px"
  },
  "& .editIcon": {
    aspectRatio: '1',
    objectPosition: 'center',
    width: '16px',
    alignSelf: 'start',
  },
  "& .item-container": {
    "@media (max-width: 1280px)": {
      width: "100%",
      textAlign: "center"
    },
  },
  "& .fullname": {
    fontSize: "30px",
    fontWeight: "600px",
    position: 'relative',
    fontFamily: "Montserrat-semibold",
    lineHeight:"36.57px",

    letterSpacing: '-0.5px',
    margin: '113px 0px 0px 116px',
    "@media (min-width:360px) and (max-width: 1280px)": {
      marginLeft:0
    },
  },
  "& .username": {
    fontSize: "18px",
    position: "relative",
    margin: "8px 0 0 116px",
    fontWeight: "600px",
    fontFamily: "Montserrat-semibold",
    lineHeight:"21.94px",
    "@media (min-width:360px) and (max-width: 1280px)": {
      marginLeft:0
    },
    // " @media (max-width: 991px)": {
    //   marginLeft: "10px"
    // }
  },

  "& .details-container ": {
    position: 'relative',
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
    margin: '16px 0 24px 116px',
    "@media (min-width:360px) and (max-width: 1280px)": {
      width: "100%",
      marginLeft:0,
      justifyContent:'space-evenly',
    },
    // " @media (max-width: 991px)": {
    //   marginLeft: "10px"
    // }
  },

  "& .item-details": {
    justifyContent: 'center',
    display: 'flex',
    gap: '8px',
lineHeight:"17.07px",
    "@media (max-width: 1280px)": {
      width: "30%",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "centers"
    },
    "@media (max-width: 600px)" : {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "centers"
    }
  },
  "&  .item-details-icon ": {
    aspectRatio: '1',
    objectPosition: 'center',
    width: '18px',
  },

  "& .first-container": {
    // paddingLeft: "10%", 
    // paddingRight: "10%", 
    marginTop: "5%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems:"center",
    width: "100%",
    "@media (min-width:360px) and (max-width: 1280px)": {
      alignItems: "unset"
    },

    '@media (max-width: "600px")': {
      alignItems: "unset"
    }
  },

  "& .second-container": {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '616px',
    // lineHeight: '150%',

  },

  "& .img1 ": {
    width: '48px',
    height: '48px',
    objectPosition: 'center',
  },
  "& .user-details-container": {
    borderColor: '#F2F2F2',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    backgroundColor: '#fff',
    border: '0px 0px 1px 0px',
    display: 'flex',
    width: "616px",
    height:"80px",
    gap: '20px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    padding: '16px 24px',
    '@media (max-width: 1280px)': {

      maxWidth: '100%',
      flexWrap: 'wrap',
      whiteSpace: 'initial',
      // padding: '0 20px',

    },
    '@media (max-width: 600px)': {
      width: "100vw"
    }
  },

  "& .user-details-items": {
    display: 'flex',
    gap: '16px',
    '@media (max-width: 1280px)': {

      whiteSpace: 'initial',

    },
  },

  "& .user-details-items-container": {
    display: 'flex',
    flexDirection: 'column',

    margin: 'auto 0',
    '@media (max-width: 1280px)': {

      whiteSpace: 'initial',
    },
  },

  "& .user-details-items-sub": {
    color: '#666666',
    fontFamily: 'Montserrat-Regular',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight:"18px",
  },
  "& .items-user-text": {
    color: '#1A1A1A',
    fontFamily: 'Montserrat-bold',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight:"24px",
  },
  "& .item-user-note": {
    aspectRatio: '0.88',
    objectPosition: 'center',
    width: '21px',
    margin: 'auto 0',
  },

}))

const styles: { [key: string]: CSSProperties } = {
  bLeft: {
    display: "flex",
    position: "absolute",
    width: 120,
    height: 133,
    top: 195,
    left: 0,
    zIndex: 10,
    objectFit: "cover",
    overflow: "hidden",
    userSelect: "none",
  },
  bRight: {
    display: "flex",
    position: "absolute",
    width: 120,
    height: 133,
    top: 21,
    right: "10%",
    zIndex: 10,
    objectFit: "cover",
    overflow: "hidden",
    userSelect: "none",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: "5em"
    // paddingLeft:"10%",
    // paddingRight:"10%",
    // position: "relative",
    // marginBottom:"10em",
    // marginTop:"64px",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    margin: "auto"
  },
  imageBackground: {
    width: "100%",

    height: "294px",
    display: "flex",
    position: "relative",
    marginTop: 20,
    marginBottom: 88,
    backgroundImage: `url(${greenBackground})`,
    userSelect: "none",
    borderBottomRightRadius: 43,


  },
  imageContent: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  editProfileRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  iconButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "119px",
    height: "32px",
    padding: "6px 10px 6px 10px",
    borderRadius: 4,
    backgroundColor: "#EDF3F8",
    marginTop: 20,
    marginRight: 20,
    zIndex: 20,
  },
  editProfileContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  editIcon: {
    width: "15px",
    height: "15px",
    marginRight: 5,
    marginTop: 2,
    userSelect: "none",
  },
  editProfileText: {
    userSelect: "none",
    fontSize: 14,
    fontWeight: 400,
  },
  userDetailsContainer: {
    width: "auto",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    marginTop: 90,
    marginLeft: 35,
  },
  fullNameDiv: {
    fontWeight: 600,

  },
  nikNameDiv: {
    fontSize: "18px",
    fontWeight: 400,
    color: "#FFFFFF",
    marginTop: 10,
  },
  iconsRow: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
  },
  iconDiv: {
    display: "flex",
    flexDirection: "row",
    marginRight: 10,
  },
  iconStyle: {
    width: "14px",
    height: "14px",
    marginRight: 4,
    userSelect: "none",
  },
  iconText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "17px",
    fontFamily: "Montserrat",
    color: "#fff",
  },
  whiteContainer: {
    display: "flex",
    flexDirection: "column",
    width: 610,
    height: 240,

  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    width: 610,
    height: 80,
    borderBottom: "1px solid #F2F2F2",
    justifyContent: "space-between",
    marginBottom: 15,
    paddingLeft: 20,
    paddingRight: 20,
  },
  inputIconDiv: {
    width: 48,
    height: 48,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    backgroundColor: "#F2F2F2",
    textAlign: "center",
  },
  inputIcon: {
    width: 24,
    height: 24,
    marginLeft: 4,
    marginTop: 10,
    userSelect: "none",
  },
  inputView: {
    height: 44,
    width: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  inputLabel: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "18px",
    color: "#666666",
    userSelect: "none",
  },
  inputText: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: "24px",
    color: "#1A1A1A",
    userSelect: "none",
  },

  inputEditIcon: {
    width: 24,
    height: 24,
    marginTop: 10,
    userSelect: "none",
  },

};

export default ProfileFrontView;
