import React from "react";
// Customizable Area Start
import LandingPageController, { Props } from "./LandingPageController.web";
import { Box, Grid } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { ContainedButton } from "../../../components/src/GlobalButton";
import ProjectText from "../../../components/src/ProjectText";
import { withRouter } from "react-router-dom";
const AlphaLogo = require("../assets/whitelogo.png");
const BoyWithPhone = require("../assets/rightsidecredcard.png");
// Customizable Area End

class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    return (
      // Customizable Area Start
      <LoginPageStyle>
        <Box className="mainContainer">
          <Box className="LoginFormContainer">
            <Box>
              <Grid className="landingContainer">
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="logoContainer"
                  item
                >
                  <div className="logoDiv">
                    <img src={AlphaLogo} />
                  </div>
                </Grid>
                <Box className="gridContainer">
                  <Grid xs={12} sm={12} md={5} item className="contentContainer">
                    <Grid container spacing={2}>
                      <Grid item xs={12} className="paddingNone">
                        <ProjectText
                          variant="h4"
                          className="LoginText"
                          textcolor="white"
                          fontweight="700"
                        >
                          Managing money is easy  with Aliph wallet
                        </ProjectText>
                        <ProjectText
                          variant="subtitle2"
                          className="DescriptionText"
                          textcolor="white"
                          TextSize="20px"
                          fontweight="400"
                        >
                          Aliph is an innovative leading Fintech startup
                          interested in developing simple, secure and 100% digital
                          payment
                        </ProjectText>
                      </Grid>
                      <Grid container spacing={2} className="buttonContainer">
                        <Grid 
                        item 
                      
                        >
                          <ContainedButton
                            onClick={this.navigateToLogin}
                            type="submit"
                            variant="outlined"
                            bgcolor="success"
                            fullWidth
                            size="medium"
                            data-testid="loginbutton"
                            id="loginbutoon"
                            className="landingbuttonlogin"
                          >
                            login
                          </ContainedButton>
                        </Grid>
                        <Grid 
                        item 
                  
                        >
                          <ContainedButton
                            type="submit"
                            onClick={this.navigateSignUp}
                            variant="outlined"
                            bgcolor="success"
                            fullWidth
                            size="medium"
                            data-testid="signupbutton"
                            className="landingbutton"
                          >
                            Signup
                          </ContainedButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid xs={12} sm={12} md={5} className="SecondContainer" item>
                    <Box className="boyImage">
                      <img src={BoyWithPhone} />
                    </Box>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
      </LoginPageStyle>
      // Customizable Area End
    );
  }
}

export default withRouter(LandingPage);

// Customizable Area Start
export { LandingPage };
const LoginPageStyle = styled("div")({
  position: "relative",
  height: "100%",
  backgroundColor: "#3FB98C",
  "& .mainContainer": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .LoginFormContainer": {
    maxWidth: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .landingContainer": {
    width: "80vw",
    height: "90vh",
    marginTop: "5%",
        "@media (max-width: 1280px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }
  },
  "& .contentContainer": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "50%",
    boxSizing: "border-box",
    "@media (min-width:360px) and (max-width: 449px)": {
      alignItems: "flex-end",
      width: "100%",
      marginTop: "5%"
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      maxWidth: "100%",
      flexBasis: "100%",
      alignItems: "flex-end",
      width: "100%",
    }
  },
  "& .paddingNone": {
    "@media (max-width:1280px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position:'relative',
      top:'-7rem'
    },
    "@media (max-width:1280px) and (max-height: 900px) ": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position:'relative',
      top:'1rem'
    },
    "@media (max-width:430px)": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position:'relative',
      top:'1rem'
    }
  },
  "& .firstSqureDiv": {
    position: "absolute",
    top: "0",
    right: "0",
    "& img": {
      width: "400px",
      height: "40%"
    },
    "@media (max-width: 800px)": {
      display: "none"
    }
  },
  "& .boyImage": {
    "& img": {
      height: "58.25vh",
      width: "39.60vw",
    },
    "@media (min-width:360px) and (max-width: 449px)": {
      "& img": {
        width: "36vw",
        height: "14vh",
        top: "17vh",
        left: "31vw",
        position: "absolute",
        display: "none"
      }
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      "& img": {
        width: "150px",
        height: "100px",
        top: "20vh",
        left: "44vw",
        position: "absolute",
        display: "none"
      }
    }
  },
  "& .SecondContainer": {
    display: "flex",
    alignItems: "center",
    height: "100%",
    width: "50%",
    "@media (min-width:600px) and (max-width:1280px)": {
      flexDirection: "column-reverse"
    }
  },
  "& .SceondSqureDiv": {
    position: "absolute",
    bottom: "0",
    left: "0",
    "& img": {
      width: "400px",
      height: "40%"
    },
    "@media (max-width: 800px)": {
      display: "none"
    }
  },
  "& .logoContainer": {
    display: "flex",
    "@media (max-width:1700px)":{
      marginBottom: "100px",
    },
    "@media (max-width: 1280px)": {
      justifyContent: "center",
      marginBottom: "10px !important",
    },


    marginLeft: "5px",
    "& .logoDiv": {
      height: "100%",
      width: "100%",
      "& img": {
        objectFit: "cover",
        width: "202.53px",
        height:"124px"
      },

    }
  },
  "& .FormContainer": {
    display: "flex",
    justifyContent: "space-between",
    "& .FormTextFileContainer": {
      minWidth: "400px",
      backgroundColor: "lightgrey"
    }
  },
  "& .react-tel-input": {
    "& .country-list": {
      minWidth: "340px",
      maxWidth: "800px",
      width: "630px",
      margin: "0px 0 10px -1px",
      "@media (max-width: 500px)": {
        width: "unset"
      },
      "& .search-box": {
        width: "99%",
        marginLeft: "0px"
      }
    }
  },
  "& .LoginText": {
    fontFamily: "Montserrat-Bold",
    fontSize: "64px",
    "@media (max-width: 1280px)": {
      fontSize: "26px",
      width: "300px",
      textAlign: "center"
    },
  },
  "& .DescriptionText": {
    margin: "20px 0 30px 0",
    lineHeight: "32px",
    "@media (min-width:360px) and (max-width: 449px)": {
      textAlign: "center",
      lineHeight: "none",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      textAlign: "center",
    },
  },
  "& .landingbuttonlogin":{
    height:"56px",
    width:"270px",
    "@media (max-width:1683px)":{
      width:"200px"
    },
    "@media (max-width: 1280px)": {
      width:"100%",
    },
  },
  "& .landingbutton": {
   
    width:"270px",
    height:"56px",

  "@media (max-width:1683px)":{
    width:"200px"
  },
  "@media (max-width: 1280px)": {
    width:"100%",
  },
      },
  "& .MuiGrid-grid-xs-6": {
    "@media (min-width:450px) and (max-width: 1280px)": {
      flexBasis: "0"
    }
  },
  "& .gridContainer": {
    width: "100%",
    height: "70%",
        display: "flex",
    gap: "128px",

    "@media (max-width: 1280px)": {
      flexDirection: "column",
      width: "100%",
    },

  },
  "& .buttonContainer": {
    "@media (min-width:360px) and (max-width: 449px)": {
      display: "flex",
      justifyContent: 'center',
      flexDirection: "column",
    },
    "@media (min-width:450px) and (max-width:1280px)": {
      display: "flex",
      justifyContent: 'center',
      width: "inherit",
      flexDirection: "column"
    }
  },
  "& .MuiGrid-spacing-xs-2": {
    margin: "-3px",

  }
});
// Customizable Area End
