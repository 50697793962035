import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}

export interface DataTypeInterface {
  [key: string]: { [key: string]: string | number }
}
export interface ShowHideColumnType { [key: string]: string | boolean }
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  tableHead?: string[];
  tableBody?: Array<{ [key: string]: string | number }>;
  rowKey?: string[];
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: any;
  token: string;
  errorMsg: string;
  loading: boolean;
  collectionAnalyticsData: Array<{ [key: string]: string | number }>;
  rowKey: string[];
  statsCollection: { [key: string]: string | number };
  statsRefund: { [key: string]: string | number };
  collectionSummaryDuration: string;
  refundSummaryDuration: string;
  salesPerformanceDuration: string;
  statsCollectionDuration: string;
  statsRefundDuration: string;
  activeTab: number;
  isDrawerOpen: boolean;
  showHideColumnData: Array<ShowHideColumnType>;
  drawerType: number;
  startDateValue: Date | null;
  endDateValue: Date | null;
  transactionType: string;
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiCollectionAnalyticsID: string = "";
  apiRefundAnalyticsID: string = "";
  apiSalesPerformanceInsightsID: string = "";
  apiStatsCollectionID: string = "";
  apiStatsRefundID: string = "";
  apiCollectionSummaryReportID: string = "";
  apiRefundSummaryReportID: string = "";
  apiSalesPerformanceReportID: string = "";
  // Customizable Area End
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      dashboardData: [],
      errorMsg: "",
      token: "",
      loading: false,
      collectionAnalyticsData: [],
      rowKey: [],
      statsCollection: {},
      statsRefund: {},
      statsCollectionDuration: "Daily",
      statsRefundDuration: "Daily",
      collectionSummaryDuration: "Daily",
      refundSummaryDuration: "Daily",
      salesPerformanceDuration: "Daily",
      activeTab: 0,
      isDrawerOpen: false,
      drawerType: 0,
      showHideColumnData: [
        { columnName: "collection_amount", isVisible: true },
         { columnName: "date", isVisible: true },
      { columnName: "merchant_name", isVisible: true },
      { columnName: "transaction_reference", isVisible: true },
      { columnName: "average_collection", isVisible: true },
      { columnName: "total_collection", isVisible: true },
      { columnName: "collection_status", isVisible: true },
      { columnName: "collection_method", isVisible: true },
      {
        columnName: "driver_name", isVisible: true
      }],
      startDateValue: null,
      endDateValue: null,
      transactionType: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener('willFocus', () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getCollectionAnalytics();
    this.getRefundAnalytics();
    this.getStatsCollection();
    this.getStatsRefund();
    this.getCollectionSummaryReport();
    this.getRefundSummaryReport();
    // this.getSalesPerformanceReport();
    // Customizable Area End
  }
  
  getToken=()=>{
    const msg: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(msg);
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const header = {
      "Content-Type": configJSON.dashboarContentType,
      token: this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dashboardGetUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, loading: true }, () => {
        this.getDashboardData();
      });
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        if (responseJson.data.length === 0) {
          this.setState({
            errorMsg: "Data Not Found",
            loading: false
          });
        } else {
          this.setState({
            dashboardData: responseJson.data,
            errorMsg: "",
            loading: false
          });
        }
      } else if (responseJson) {
        this.apiSuccess(apiRequestCallId, responseJson)
      }
else {
        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        if (errorReponse === undefined) {
          this.setState({
            errorMsg: "Something went wrong",
            loading: false
          });
        } else {
          this.setState({
            errorMsg: errorReponse,
            loading: false
          });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  apiCall = async (data: APIPayloadType) => {
    let { contentType, method, endPoint, body } = data;
    let token = await getStorageData("authToken");
    let header = {
      "Content-Type": contentType,
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getCollectionAnalytics = async () => {
    this.apiCollectionAnalyticsID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.collectionAnalyticsEndPoint
    })
  };

  getRefundAnalytics = async () => {
    this.apiRefundAnalyticsID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.refundAnalyticsEndPoint
    })
  };

  getStatsCollection = async () => {
    this.apiStatsCollectionID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.statsCollectionEndPoint+`${this.state.statsCollectionDuration}`
    })
  };

  getStatsRefund = async () => {
    this.apiStatsRefundID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.statsRefundEndPoint
    })
  };

  getCollectionSummaryReport = async () => {
    this.apiCollectionSummaryReportID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.collectionSummaryReportEndPoint+`${this.state.collectionSummaryDuration}`
    })
  };

  getRefundSummaryReport = async () => {
    this.apiRefundSummaryReportID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.refundSummaryReportEndPoint+`${this.state.refundSummaryDuration}`
    })
  };

  getSalesPerformanceReport = async () => {
    this.apiSalesPerformanceReportID = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.dashboarApiMethodType,
      endPoint: configJSON.salesPerformanceReportnEndPoint
    })
  };

  apiSuccess = (apiCallID: string, response: DataTypeInterface) => {
    if (apiCallID === this.apiCollectionAnalyticsID) {
      const { modifiedList, rowKey } = this.commonDataModifier(response, "ca");
      this.setState({
        collectionAnalyticsData: modifiedList,
        rowKey
      });
    }

    if (apiCallID === this.apiStatsCollectionID) {
      this.setState({
        statsCollection: response.statistics_analytic
      })
    }

    if (apiCallID === this.apiStatsRefundID) {
      this.setState({
        statsRefund: response.statistics_analytic
      })
    }
  }

  commonDataModifier = (response: DataTypeInterface, tableType: string) => {
    const list = [];
    const rowKey = tableType === "ca" ? ["metric"] : [];
    for (const keys in response) {
      list.push(response[keys]);
    }

    for (const keys in list[0]) {
      if (keys !== "count") {
        rowKey.push(keys);
      }
    }
    const modifiedList = list.map((data: { [key: string]: string | number }) => {
      tableType === "ca" && delete data.count;
      return tableType === "ca" ? {
        ...data,
        metric: data.time_frame + " collections"
      }
        :
        data
    });
    return {
      modifiedList,
      rowKey
    }
  };
  
  handleStatsCollection = (duration: string) => {
    this.setState({
      statsCollectionDuration: duration,
    }, ()=> {
      this.getStatsCollection()
    })
  };

  handleStatsRefund = (duration: string) => {
    this.setState({
      statsRefundDuration: duration,
    }, ()=> {
      this.getStatsRefund()
    })
  };

  handleCollectionSummaryReport = (duration: string) => {
    this.setState({
      collectionSummaryDuration: duration,
    }, ()=> {
      this.getCollectionSummaryReport();
    })
  };

  handleRefundSummaryReport = (duration: string) => {
    this.setState({
      refundSummaryDuration: duration,
    }, ()=> {
      this.getRefundSummaryReport();
    })
  };

  handleSalesPerformanceReport = (duration: string) => {
    this.setState({
      salesPerformanceDuration: duration,
    })
  };

  tabHandler = (activeTab:number) => {
    this.setState({
      activeTab
    })
  };

  openDrawerhandler = (drawerType:number) => {
    this.setState({
      isDrawerOpen: true,
      drawerType
    })
  };

  closeDrawerhandler = () => {
    this.setState({
      isDrawerOpen: false
    })
  };

  columnShowHideHandler = (columnName: string) => {
    const visibilityToggledData = this.state.showHideColumnData.map((column: ShowHideColumnType) => column.columnName === columnName ? { ...column, isVisible: !column.isVisible } : column)
    this.setState({
      showHideColumnData: visibilityToggledData
    })
  };

  hideAllColumnHandler = () => {
    const hideAllColumnData = this.state.showHideColumnData.map((column:ShowHideColumnType)=>({...column, isVisible: false}));
    this.setState({
      showHideColumnData: hideAllColumnData
    })
  };

  onStartDateChange = (dateValue:Date | null) => {
    this.setState({
      startDateValue: dateValue
    })
  };

  onEndDateChange = (dateValue:Date | null) => {
    this.setState({
      endDateValue: dateValue
    })
  };

  onTransactionTypeChange = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      transactionType: event.target.value
    })
  };

  // Customizable Area End

}
