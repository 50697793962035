Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodtype = "GET";
exports.passcodelockAPIEndpoint = "";
exports.passcodelockPlaceholder = "Passcode Lock";
exports.labelTitleText = "Passcode Lock";
exports.lebelBodyText = "Passcode Lock Body";
exports.btnExampleTitle = "Passcode Lock";
exports.confirmPasscodeApiEndPoint = "/account_block/accounts/confirm_passcode_login";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.sendResetPasscodeLinkApiEndPoint = "account_block/accounts/send_verify_link_for_reset_passcode";
exports.resetPasscodeApiEndPoint = "account_block/accounts/reset_passcode";
exports.createPasscodeApiEndPoint = "account_block/accounts/create_passcode_lock";
exports.passcodeCreateSuccessTxt = "Passcode created successfully";
exports.setNewPasscodeTxt = "Set new passcode";
exports.reSetNewPasscodeTxt = "Please re-enter your passcode";
exports.sixDigitsTxt = "Enter 6 digits";
exports.passcodeNotMatchTxt = "Passcode do not match. Try again.";
exports.passcodeResetTxt = "passcode reset";
exports.validatePhoneTxt = "Please validate your phone number to reset your passcode";
exports.phoneNumberTxt = "Phone number";
exports.proceedTxt = "Proceed";
exports.passcodeIncorrectTxt = "Passcode is incorrect. Please try again.";
exports.forgotPasscodeTxt = "Forgot Passcode";
exports.enterNewPasscodeTxt = "Please enter your new passcode";
exports.reEnterNewPasscodeTxt = "Please re-enter your new passcode";
exports.wrongPasscodeTxt = "Passcode is wrong. Try again.";
exports.tryAgainTxt = "Try again. After";
exports.passcodeResetFailed = "Failed to reset passcode";
exports.passcodeUpdatedSuccessTxt = "Passcode updated successfully";
exports.enterPasscodeTxt = "Enter your passcode";
// Customizable Area End
