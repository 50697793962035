import { CSSProperties } from "react";
export const accountsUniversalStyles: { [key: string]: CSSProperties } = {

  employeeName: {
    
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Montserrant-bold",
    lineHeight: 24,
  },
  employeeButton: {
    display:"flex",
    width: 100,
    height: 32,
    borderRadius: 4,
    backgroundColor: "#3FB98C",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    textAlign:'center',
    cursor:"pointer",
    
  },
  employeeButtonText: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 700,
    textAlign:'center',
    userSelect:'none',
  fontFamily:"Montserrat-bold"
  },
  container: {
    position: "relative",
    marginLeft: 40,
  },
  topBar: {
    marginTop: 30,
    marginBottom: 25,
  },
  topBarText: {
    fontSize: 18,
    fontWeight: 600,  
    color: "#1A1A1A",
    marginTop:40,
    userSelect:'none',
    fontFamily:"Montserrat-semibold"
  },
  topBarTextMembers: {
    fontSize: 12,
    fontWeight: 700,
    color: "#64748B",
    marginTop: 32,
    marginLeft: 2,
    marginBottom:5,
    userSelect:'none',
    fontFamily:"Montserrat-bold",
    
  },

  rowContent: {
    display: "flex", 
    flexDirection: "row", 
    justifyContent: "flex-start", 
    flexWrap:'wrap',
    marginBottom: 2
  },
};

export interface EmployeeData{
  password:string;
  full_name:string;
  nationality:string;
  date_of_birth:string;
  user_name:string;

}
export const defaultEmployeeData:EmployeeData={
  password:"",
  full_name:"",
  nationality:"",
  date_of_birth:"",
  user_name:""

}
export const listToDeactivated = [
    { name: "Employee 1", isActive: true },
    { name: "Employee 2", isActive: true},
    { name: "Employee 3", isActive: false },
    { name: "Employee 4", isActive: false },
    { name: "Employee 5", isActive: true},
    { name: "Employee 6", isActive: true },
    { name: "Employee 7", isActive: true },
    { name: "Employee 8", isActive: false },
    { name: "Employee 9", isActive: true },
    { name: "Employee 8", isActive: false },
    { name: "Employee 9", isActive: true },
   
  ];

  export const employeeList = [
    { name: "Employee 1", isActive: true },
    { name: "Employee 2", isActive: false },
    { name: "Employee 3", isActive: false },
    { name: "Employee 4", isActive: false },
    { name: "Employee 5", isActive: false },
    { name: "Employee 6", isActive: false },
    { name: "Employee 7", isActive: false },
    { name: "Employee 8", isActive: false },
    { name: "Employee 9", isActive: false },
    { name: "Employee 10", isActive: false },
    { name: "Employee 11", isActive: false },
    { name: "Employee 12", isActive: false },
    { name: "Employee 13", isActive: false },
    { name: "Employee 14", isActive: false },
    { name: "Employee 15", isActive: false },
    { name: "Employee 16", isActive: false },
    { name: "Employee 17", isActive: false },
  ];