import React from "react";
import AccountCreateController from "./AccountCreateController.web";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
import { accountsUniversalStyles} from "./consts";
import { withRouter } from "react-router-dom";
import EmployeeDisplay from "./AccountEmployeeView";
import ModalCustom from "../../../components/src/ModalCustom";
import { styled } from "@material-ui/core/styles";
export class AccountReactivation extends AccountCreateController {
  async componentDidMount() {
    this.getToken();
  }
  render() {
    if (!this.state.reactivationAccountsList || !this.state.reactivationAccountsList.data) {
      return (
        <ProjectSideBar pageThisVar={this}>
          <div  style={accountsUniversalStyles.container}
          data-test-id="AccountDeactivation">
            <div style={accountsUniversalStyles.topBarText}>
            Active accounts
            </div>

           
           
            <div style={accountsUniversalStyles.topBarTextMembers} id={this.state.deactivationAccountsList}>
            0 MEMBERS
          
            </div>
            <div data-test-id="AccountDeactivation" style={{ width: "100%", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: 20, marginTop: 25 }}>
            No Active Accounts found
          </div>
          </div>
        </ProjectSideBar>
      );
    }
   


    return (
      <ProjectSideBar pageThisVar={this}>
        <div
          style={accountsUniversalStyles.container}
          data-test-id="AccountReactivation"
        >
          <div>
            <div style={accountsUniversalStyles.topBarText}>
            Active accounts
            </div>

          
            <div style={accountsUniversalStyles.topBarTextMembers} id={this.state.reactivationAccountsList}>
            {this.formatMembersCount(this.state.reactivationAccountsList.data)}
           
            </div>
          </div>
          <DivStyle >
          {this.state.reactivationAccountsList.data.length>0 && this.state.reactivationAccountsList.data.map((item: any, index: any) => (
              <React.Fragment key={index}>
               <EmployeeDisplay
                  toDeactivatedName={item.attributes.full_name || "N/A"}
                  isActive={item.attributes.activated}
                  onPress={this.toggleModal.bind(
                    this,
                    item.attributes.full_name,
                    item.id
                  )}
                  buttonName="Deactivation"
                />
                 
              </React.Fragment>
            )
            )}
          </DivStyle>

        </div>

        <ModalCustom
            visible={this.state.isModalVisible}
            onClose={this.toggleModal.bind(this, "", null)}
            onConfirm={this.deactivateEmployeeAccount.bind(
              this,
              this.state.selectedEmployeeId
            )}
            workerName={this.state.selectedWorkerName}
            confirmText="DEACTIVATE ACCOUNT"
            confirmed={this.state.changeModalViewDeactivation}
            whatWasDone="deactivated"
           
          />
      </ProjectSideBar>
    );
  }
}

export default withRouter(AccountReactivation);
const DivStyle=styled('div')({
  display: "flex",
  flexWrap:"wrap",
  marginTop:16,
  gap:"25px",
  '@media(max-width: 750px)': {
    marginBottom: 100,
    flexDirection:'column'
  },
  marginBottom:30,
})