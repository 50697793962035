import React, { Component } from "react";
// Customizable Area Start
import { Table, TableBody, TableCell, TableHead, TableRow, styled} from "@material-ui/core";
import { Props } from "./DashboardController"
// Customizable Area End

class CommonTable extends Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    renderTableHead = () => {
        return (
            <TableHead>
                <TableRow >
                    {
                        this.props.tableHead && this.props.tableHead.map((item: string) => <TableCell style={{ borderBottom:'1px solid #DBE7F0',padding:"16px 24px 16px 24px"}} key={item}>{item}</TableCell>)
                    }
                </TableRow>
            </TableHead>
        )
    };

    renderTableBody = () => {
        return (
            <TableBody>
                {
                    this.props.tableBody && this.props.tableBody.map((item: { [key: string]: string | number },  rowIndex: number) => {
                        return <TableRow key={rowIndex}>
                            {
                                this.props.rowKey && this.props.rowKey.map((rowItem: string, index: number) => <TableCell style={{ borderBottom:'1px solid #DBE7F0',padding:"24px"}} key={index}>{item[rowItem]}</TableCell>
                                )
                            }
                        </TableRow>
                    })
                }
            </TableBody>
        )
    };

    render() {
        return (
            // Customizable Area Start
                <StyledTable>
                    {this.renderTableHead()}
                    {this.renderTableBody()}
                </StyledTable>
            // Customizable Area End
        );
    }
}

export default CommonTable;

// Customizable Area Start
const StyledTable = styled(Table)({
    "& .MuiTableCell-head": {
        fontSize: "14px",
        fontWeight: 600,
        color:"#333333",
        fontFamily:"Montserrat-semibold"
    },
    "& .MuiTableBody-root": {
        textTransform: "capitalize"
    },
    "& .MuiTableCell-body": {
        fontSize: "12px",
        color:"#1A1A1A",
        fontFamily:"Montserrat-regular",
        fontWeight:400,
        borderBottom:'1px solid #DBE7F0',
    }
});
// Customizable Area End
