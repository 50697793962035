import React, { CSSProperties } from "react";

interface CustomTextInputProps {
  label: string;
  name: string;
  value: string;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable?:boolean
}

const CustomTextInput: React.FC<CustomTextInputProps> = ({
  label,
  name,
  value,
  error,
  onChange,
  disable=false
}) => {
  return (
    <div style={styles.inputContainer}>
      <label style={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        style={styles.input}
        type="text"
        id={name}
        name={name}
        value={value}
        disabled={disable}
        onChange={onChange}
      />
      {error && <div style={styles.error}>{error}</div>}
    </div>
  );
};

export default CustomTextInput;

const styles: { [key: string]: CSSProperties } = {
  inputContainer: {
    marginBottom: "20px",
    marginTop:"10px",
    padding:"0.5px"
  },
  label: {
    display: "block",
    marginBottom: "5px",
    // fontWeight:700,
    // fontSize:16,
    color: 'var(--Secondary-cyan-950, #031417)',
    fontFamily: 'Montserrat-bold',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    //fontFamily:"Montserrat"
    
  },
  input: {
    width: "100%",
    padding: "17px 8px 17px 8px",
    border: "1px solid #B6CEE2",
    borderRadius: "8px",
    height:"56px",
    fontFamily: 'Montserrat-bold',
    fontSize: '14px',
    fontWeight: 700,
  },
  error: {
    color: "red",
    marginTop: "5px",
  },
};
