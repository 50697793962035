import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Typography,
  Dialog,
  DialogContent,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { crossIcon, deleteIcon, dotIcon, unReadDot } from "./assets";
import ProjectSideBar from "../../../components/src/ProjectSideBar";
// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ProjectSideBar pageThisVar={this}>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <Box sx={webStyle.mainWrapper}>
              {this.state.data
                ?.sort((a: any, b: any) => a.attributes.id - b.attributes.id)
                ?.map((item: any, index: any) => {
                  return (
                    <StyledItemWrapper
                      key={item.attributes.id}
                      style={{ backgroundColor: item.attributes.is_read ? "#FFFFFF" : "#E8FAFC" }}
                      data-test-id= {`main-container${index}`}
                      onClick={() => {
                        this.setState({ selectedData: item });
                        if (!item.attributes.is_read) {
                          this.markAsRead(item.attributes.id);
                        }
                      }}
                    >

                      <Box style={{ marginLeft: 16, flex: 1 }}>
                        <Box style={webStyle.itemHeadingWrapper}>
                          <Box>
                            <img src={item.attributes.is_read ? unReadDot : dotIcon} style={webStyle.iconStyle} />
                          </Box>

                          <StyledContainer>

                            <StyledBox>
                              <Typography
                                variant="h6"
                                style={
                                  item.attributes.is_read
                                    ? webStyle.itemHeading
                                    : webStyle.itemHeadingRead
                                }
                              >
                                {item.attributes.headings}
                              </Typography>
                              <Typography variant="h6" style={webStyle.contents}>
                                {item.attributes.contents}
                              </Typography>
                            </StyledBox>

                            <StyledDeleteContainer>
                              <Box> <img data-test-id= {`delete-container${index}`} src={deleteIcon} style={webStyle.deleteIconStyle} onClick={(e) => {
                                e.stopPropagation();
                                this.deleteNotifications(item.attributes.id);
                              }} /></Box>
                              <Box>
                                <Typography variant="h6" style={{ ...webStyle.contents, textAlign: "center" }}>
                                  {this.timeSince(item.attributes.created_at)}
                                </Typography>
                              </Box>

                            </StyledDeleteContainer>

                          </StyledContainer>
                        </Box>

                      </Box>
                    </StyledItemWrapper>
                  );
                })}
              <StyledDialog
                maxWidth={"md"}
                data-test-id="style-dialogue"
                open={!!this.state.selectedData}
                onClose={this.onClose}
              >
                <StyledDialogContent>
                  <StyledDialogHeader>
                    <StyledHeaderItem>
                      <Box>
                        <Typography
                          variant="h6"
                          style={webStyle.DialogHeading}
                        >
                          {this.state.selectedData?.attributes.headings}
                        </Typography>
                      </Box>

                      <Box data-test-id= "content-container" onClick={() => this.setState({ selectedData: null })}><img src={crossIcon} style={{ width: "14px", height: "14px" }} /></Box>
                    </StyledHeaderItem>
                  </StyledDialogHeader>
                  <StyledFooter>
                    <Typography variant="h6" style={webStyle.textContent}>
                      {this.state.selectedData?.attributes.contents}
                    </Typography>
                  </StyledFooter>
                </StyledDialogContent>

              </StyledDialog>
            </Box>
          </Container>
        </ThemeProvider>
      </ProjectSideBar>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledItemWrapper = styled(Box)({
  display: "flex",
  width: "850px",
  backgroundColor: "#E8FAFC",
  flexDirection: "row" as "row",

  padding: 16,

  cursor: "pointer",
  borderRadius: "8px 8px 0px 0px",
  borderBottom: '1px solid #EDF3F8',
  "@media  (max-width: 1168px)": {
    width: "550px",
  },
  "@media  (max-width: 824px)": {
    width: "400px",
  },
  "@media  (max-width: 450px)": {
    width: "300px",
  },

})
const StyledDialog = styled(Dialog)({
  "& .MuiPaper-rounded": {
    borderRadius: "8px 8px 32px 8px"
  }
})
const StyledDialogContent = styled(DialogContent)({
  padding: 0,
  paddingTop: 0,


  overflow: 'hidden',
  "& .MuiDialogContent-root:first-child": {
    padding: "0px 0px 0px 0px"
  },
  "& .MuiDialogContent-root": {
    padding: 0
  },
  "& .MuiPaper-rounded": {
    borderRadius: "8px 8px 32px 8px",
  }
})
const StyledHeaderItem = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row',
  width: 654,
  "@media  (max-width: 775px)": {
    width: "100%",
  },
})

const StyledFooter = styled(Box)({
  padding: "32px 40px 32px 40px"
})
const StyledBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: "8px",
  width: "80%"
})
const StyledDialogHeader = styled(Box)({
  padding: "4px 40px 24px 40px",
  borderBottom: '1px solid #E2E8F0'
})
const StyledContainer = styled(Box)({
  width: "100%",
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})
const StyledDeleteContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: "8px"
})

const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Montserrat-regular",
    flexDirection: "column",
    alignItems: "center",

    marginTop: 40,
    marginBottom: 60,
    background: "#fff",
    zIndex: 1,
    position: 'relative'
  },
  textContent: {

    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Montserrat-regular"
  },

  itemHeadingWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "row" as "row",

    gap: 10
  },
  DialogHeading: {
    color: "#333333",
    flex: 1,
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Montserrat-semibold"
  },
  iconStyle: {
    width: 8,
    height: 8,

  },
  deleteIconStyle: {
    width: 14,
    height: 18,
  },
  itemHeading: {
    color: "#333333",
    flex: 1,
    fontWeight: 600,
    fontSize: "14px"
  },
  itemHeadingRead: {
    color: "##EDF3F8",
    flex: 1,
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Montserrat-semibold"
  },
  contents: {
    color: "#767676",
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Montserrat-regular"
  },
  okButton: {
    backgroundColor: "#ccc",
    color: "#000",
  },
  deleteButton: {
    backgroundColor: "#FF0000",
  },
};
// Customizable Area End
