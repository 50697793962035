// Customizable Area Start
import React from "react";
import VerifyingCodeController from "./VerifyingCodeController.web";
import { Box, Grid } from "@material-ui/core";
import { ContainedButton } from '../../../components/src/GlobalButton'
import ProjectText from "../../../components/src/ProjectText";
import LoginLayout from "../../../components/src/LoginLayout";
import 'react-phone-input-2/lib/style.css'
import { Formik } from "formik";
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';
import { withRouter } from 'react-router-dom';

class VerifyingCode extends VerifyingCodeController {

  LoginSchema = () => {
    return Yup.object().shape({
      VerfiyCode: Yup.string().required("Enter valid Verifycode").min(6).nullable(),
    })
  };
  render() {
    return (
      <LoginLayout BackButton BackButtonOnClick={() => {
        this.props.history.goBack()
      }}>
        <Formik
          initialValues={{
            Phone: ""
          }}
          validationSchema={() => this.LoginSchema()}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            this.props.history.replace({
              pathname: "/NewPasswordWeb"
            })
          }}
        >
          {({ errors, touched, setFieldValue, handleSubmit }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProjectText variant="h4" className="LoginText" gutterBottom>
                    Enter the verification code
                  </ProjectText>
                  <ProjectText variant="subtitle1" className="DescriptionText" gutterBottom>
                    Please enter the 6-digit verification code
                  </ProjectText>
                </Grid>
                <Grid item xs={12}>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <OtpInput
                      value={this.state.VerifyCode.toString()}
                      onChange={(e: string) => {
                        this.SetVerfiyCode(e, setFieldValue)
                      }}
                      renderInput={(props) => <input {...props} />}
                      shouldAutoFocus
                      numInputs={6}
                      inputStyle="OtpStyle"
                      renderSeparator={<span>-</span>}
                    />
                  </Box>
                  <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px 0px" }}>
                    <ProjectText textcolor="#666666"
                      variant="h6"
                      gutterBottom>
                      Didn’t receive the OTP?
                    </ProjectText>
                    <ContainedButton variant="text" size="medium">
                      <ProjectText variant="subtitle1"
                        textcolor="#1A1A1A"
                        gutterBottom>
                        Retry
                      </ProjectText>
                    </ContainedButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    <ContainedButton type="submit" bgcolor={this.state.VerifyCode.length !== 6 ? "outlined" : "success"} disabled={this.state.VerifyCode.length !== 6} variant="contained" fullWidth size="medium">
                      Verify and Proceed
                    </ContainedButton>
                  </Box>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </LoginLayout>
    )
  }
}
export default withRouter(VerifyingCode)
// Customizable Area End