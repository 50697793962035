import React, { useState } from "react";
import {
  OutlinedInput,
  OutlinedInputProps,
  Grid,
  InputLabel,
  Box,
  Grow,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";

interface CustomDatePickerProps {
  label: string;
  value: string;
  onDateChange: (date: string) => void;
  error?: string;
  touched?: boolean;
  borderColor?: string;
}

// const ProjectTextField = styled(OutlinedInput)<OutlinedInputProps>(({ error }) => ({
//   '& .MuiOutlinedInput-root': {
//     borderColor: "#B6CEE2",//border: "1px solid #B6CEE2", // Your custom border
//     borderRadius: "4px",
//     ...(error && {
//       borderColor: "red" // Border color on error
//     }),
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       borderColor: "#B6CEE2",// Hide the default border when focused
//     },
//     '& .MuiOutlinedInput-notchedOutline': {
//       borderColor: "#B6CEE2", // Hide the default border
//     },
//   },
//   '& .Mui-focused': {
//     outline: "none"
//   },
//   "@media (max-width: 1200px)": {
//     height: "30px"
//   },
//   height: "40px",
//   fontWeight: 700,
// }));
interface ProjectTextFieldProps extends OutlinedInputProps {
  borderColor: string;
}

// const ProjectTextField = styled(OutlinedInput)<ProjectTextFieldProps>(
//   ({ error }) => ({
//     "& .MuiOutlinedInput-root": {
//       borderColor: `${error ? "red" : "#B6CEE2"} !important`,
//       borderRadius: "4px",
//       borderStyle: "solid",
//       "&:hover .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#B6CEE2 !important",
//       },
//       "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#B6CEE2 !important",
//       },
//       "& .MuiOutlinedInput-notchedOutline": {
//         borderColor: "#B6CEE2 !important",
//       },
//     },
//     "& .Mui-focused": {
//       outline: "none",
//     },
//     "@media (max-width: 1200px)": {
//       height: "30px",
//     },
//     height: "40px",
//     fontWeight: 700,
//   })
// );
const ProjectTextField = styled(OutlinedInput)((props: OutlinedInputProps) => (
  {
      borderColor: "#B6CEE2",
      borderRadius: "8px",
      ...(props.error && {
          borderColor: "red"
      }),
      '& .Mui-focused': {
          outline: "none"
      },
      "& .MuiOutlinedInput-notchedOutline" : {
        borderColor : "#B6CEE2",
     },
     "&:hover > .MuiOutlinedInput-notchedOutline" : {
      borderColor : "#B6CEE2",
   },
      "@media (max-width: 1200px)": {
          height: "56px"
      },
      height: "56px",
      fontWeight:700,
  }))

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  label,
  value,
  onDateChange,
  error,
  touched,
}) => {
  const [date, setDate] = useState(value);

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = e.target.value;
    setDate(newDate);
    onDateChange(newDate);
  };

  return (
    <Grid item xs={12} style={{marginBottom: 20,padding:"0.5px"}}>
      <InputLabel
        style={{
          color: "black",
          marginTop: 10,
          marginBottom: 10,
          fontFamily: 'Montserrat-bold',
          fontSize: '14px',
          fontWeight: 700,
        }}
        htmlFor="DateofBirth"
      >
        {label}
      </InputLabel>
      <Box style={{ position: "relative" }}>
        <ProjectTextField
          type="date"
          fullWidth
          error={!!error && touched}
          id="DateofBirth"
          name="DateofBirth"
          inputProps={{
            maxLength: 12,
            "data-test-id": "DateofBirth",
            max: moment().format("YYYY-MM-DD"),
          }}
          onChange={handleDateChange}
          placeholder="DOB"
          value={date}
        />
        {touched && error && (
          <Grow
            in={!!error}
            style={{ transformOrigin: "0 0 0" }}
            {...(!error ? { timeout: 800 } : {})}
          >
            <div style={{ color: "red", marginTop: "5px" }}>{error}</div>
          </Grow>
        )}
      </Box>
    </Grid>
  );
};

export default CustomDatePicker;
