import React from "react";
import {
  Box,
  Grid,
  InputLabel,
  IconButton,
  TextField,
  TextFieldProps,
  Grow,
} from "@material-ui/core";
import ProjectText from "./ProjectText";
import PhoneInput, { CountryData } from "react-phone-input-2";

interface StyleProps {
  errors: any; 
}

interface Props{
    countryCode:string;
    onChange:(value:string, country: CountryData)=>void;
    mobileNo:string;
    errors:any;
}
const CustomMobileNoGrid:React.FC<Props>=({countryCode,onChange,mobileNo,errors}) =>{

    return (
      <Grid item xs={12}>
        <InputLabel htmlFor="PhoneNumber" style={{  fontFamily: 'Montserrat-bold',
    fontSize: '16px',
    fontWeight: 700,color: "black" ,marginBottom:10}}>
         
            Mobile No.
        
        </InputLabel>
        <Box style={{ position: "relative" }}>
          <PhoneInput
          disabled
            country={countryCode}
            value={mobileNo}
            onChange={onChange}
            inputProps={{
              id: "PhoneNumber",
              // name: "PhoneNumber",
              required: true,
              // autoFocus: true,
            }}
            inputClass="PhoneInputPackgeClass"
            enableSearch={true}
            disableSearchIcon={false}
            countryCodeEditable={true}
            inputStyle={{
              borderColor: errors.Phone ? "red" : "#B6CEE2"
            }}
          />
          {mobileNo && (
            <ProjectText
              type="error"
              style={{ position: "absolute", bottom: "-20px" }}
            >
              {errors.mobileNo}
            </ProjectText>
          )}
        </Box>
      </Grid>
    );
  }

  export default CustomMobileNoGrid;