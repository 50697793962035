// Customizable Area Start
import React from "react";
import SignUpController from "./SignUpController.web";
import { Box, Grid, InputLabel, Button, Grow, styled } from "@material-ui/core";
import { ContainedButton } from "../../../components/src/GlobalButton";
import ProjectText from "../../../components/src/ProjectText";
import LoginLayout from "../../../components/src/LoginLayout";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { configJSON } from "./EmailAccountRegistrationController";

class SignUp extends SignUpController {
  SignUpSchema = () => {
    return Yup.object().shape({
      Phone: Yup.string().required("Phone number is required").min(12, "The phone number you have entered is invalid").max(15, "The phone number you have entered is invalid").nullable(),
    });
  };
  render() {
    return (
      <SignUpLayout>
      <LoginLayout
        BackButton
        BackButtonOnClick={() => {
          this.props.history.goBack()
        }}
      >
        {!this.state.isLoadingData  && 
        <Formik
          initialValues={this.state.initialStateValue}
          validationSchema={() => this.SignUpSchema()}
          validateOnBlur={true}
          onSubmit={(values) => {
            this.FormSubmit(values);
          }}
          isInitialValid
          validateOnChange={true}
        >
          {({ errors, touched, setFieldValue, handleSubmit, isValid, values }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ProjectText variant="h4" className="LoginText" fontfamily="Montserrat-Bold" fontweight="700"  TextSize="25px" textcolor="#031417" gutterBottom>
                    Signup
                  </ProjectText>
                  <ProjectText
                    variant="h6"
                    className="DescriptionText"
                    fontweight="400"
                    TextSize="14px"
                    textcolor="#333"
                    gutterBottom
                  >
                    Please enter your phone number
                  </ProjectText>
                </Grid>
                <Grid item xs={12}>
                  <Box className="mb-16">
                  <InputLabel htmlFor="PhoneNumber">
                    <ProjectText
                      variant="subtitle1"
                      fontfamily="Montserrat-Bold" fontweight="700"  TextSize="16px"
                      textcolor="#031417"
                      className="inputName"
                    >
                      Phone number
                    </ProjectText>
                  </InputLabel>
                  <Box style={{ position: "relative" }}>
                    {errors.Phone === undefined && values.Phone !== "" &&
                      <div className="CheckIcon">
                        <DoneAllIcon />
                      </div>
                    }
                    <PhoneInput
                      country={this.state.CountryCode}
                      placeholder={"Enter Phone Number"}
                      value={this.state.Phone?.toString()}
                      onChange={(number: string) => {
                        this.SetPhoneNumber(number, setFieldValue);
                      }}
                      inputProps={{
                        required: true,
                        id: "PhoneNumber",
                      }}
                      enableSearch={true}
                      disableSearchIcon={true}
                      jumpCursorToEnd={true}
                      countryCodeEditable={false}

                      disabled={this.state.isPhoneThere}

                      inputClass={this.phoneInputClassHandler(values, touched, errors)}
                    />
                  </Box>
                  {(touched.Phone && errors.Phone) && (
                    <Grow
                      in={errors.Phone !== undefined}
                      style={{ transformOrigin: "0 0 0" }}
                      {...(!errors.Phone ? { timeout: 800 } : {})}
                    >
                      <ProjectText
                        type="error"
                        fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                        className="errorText"
                      >
                        {errors.Phone}
                      </ProjectText>
                    </Grow>
                  )}
                  {this.state.errorText && (
                    <ProjectText
                      type="error"
                      fontfamily="Montserrat-SemiBold" TextSize="12px" fontweight="600"
                      className="errorText"
                    >
  
                      {this.state.errorText}
                    </ProjectText>
                  )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box className="mt-10">
                    <ContainedButton
                      type="submit"
                      bgcolor="success"
                      fullWidth
                      size="medium"
                      data-testid="loginbutoon"
                      className="signupButton"
                    >
                      Signup
                    </ContainedButton>
                  </Box>
                  {this.state.Sendotp && (
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        marginBottom: "10px",
                        minHeight: "50px",
                        height: "50px",
                      }}
                    >
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "white",
                          color: "black",
                          textTransform: "unset",
                          fontWeight: 600
                        }}
                        startIcon={
                          <CheckCircleIcon style={{ color: "#5DBB65" }} />
                        }
                      >
                        OTP sent
                      </Button>
                    </Box>
                  )}
                </Grid>
                <Grid item xs={12} style={{ padding: "0px" }}>
                  <ProjectText variant="subtitle1" className="alreadyTxt" align="center">
                    Already have an account?<Button className="FogotandSignup" onClick={this.NavigateToLogin}>Login</Button>
                  </ProjectText>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
        }
      </LoginLayout>
      </SignUpLayout>
    );
  }
}

export default withRouter(SignUp);
const SignUpLayout = styled(Box)({
  "& .logoContainer": {
    '& img': {
      "@media (min-width:360px) and (max-width: 449px)": {
        display:"none"
      } ,
      "@media (min-width:450px) and (max-width:1280px)": {
        display:"none"
      },
    }
  },
  "& .FogotandSignup": {
    fontWeight: "700",
    fontSize: "14px"
  },
  "& .alreadyTxt": {
    fontSize: "14px",
    fontWeight: "400"
  },
  "& .contentContainer": {
    overflow: "hidden",
    "@media (min-width:360px) and (max-width: 600px)": {
      position:"inherit",
      marginTop: "25%"
    } ,
    "@media (min-width:600px) and (max-width:962px)": {
      marginTop:"20%",
      position:"inherit"
    },
    "@media (min-width:962px) and (max-width:1280px)": {
      marginTop:"15%",
      position:"inherit"
    },
  },
  "& .react-tel-input": {
    width:'26.05vw',
    "@media (min-width:360px) and (max-width:449px)":{
        width:"inherit"
    },
    "@media(min-width:450px) and (max-width:1280px)":{
        width:"74vw"
    },
  },
  "& .signupButton": {
    width:"26.05vw",
    "@media (min-width:360px) and (max-width:449px)":{
        width:"100%",
        position:"relative",
        display:"flex"
    },
    "@media (min-width:450px) and (max-width:1280px)":{
        width:"74vw"
    },
  },
})
// Customizable Area End
