// Customizable Area Start
import React from 'react'

export const ReactivationIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 7H14.5L16.36 5.14C14.9 3.23 12.59 2 10 2C5.58 2 2 5.58 2 10C2 11.83 2.61 13.5 3.64 14.85C4.86 13.45 7.15 12.5 10 12.5C12.85 12.5 15.15 13.45 16.36 14.85C17.39 13.5 18 11.83 18 10H20C20 15.5 15.5 20 10 20C4.5 20 0 15.5 0 10C0 4.5 4.5 0 10 0C13.14 0 15.95 1.45 17.78 3.72L19.5 2V7ZM10 18C11.9 18 13.64 17.34 15 16.24C14.36 15.23 12.45 14.5 10 14.5C7.55 14.5 5.64 15.23 5 16.24C6.36 17.34 8.1 18 10 18ZM10 4C11.93 4 13.5 5.57 13.5 7.5C13.5 9.43 11.93 11 10 11C8.07 11 6.5 9.43 6.5 7.5C6.5 5.57 8.07 4 10 4ZM10 6C9.17 6 8.5 6.67 8.5 7.5C8.5 8.33 9.17 9 10 9C10.83 9 11.5 8.33 11.5 7.5C11.5 6.67 10.83 6 10 6Z" fill="#999999" />
    </svg>
  )
}
// Customizable Area End