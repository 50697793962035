// Customizable Area Start
import React from 'react'
import { Button } from "@material-ui/core";
import { styled } from "@material-ui/styles";

interface ButtonTypes {
    textalign?: string,
    space?: string,
    shape?: string,
    bgcolor?: string,
    variant?: string,
    fontweight?: string
}

const Outlined = styled(Button)((props: ButtonTypes) => ({
    textTransform: "capitalize",
    ...(typeof props.textalign === "string" && {
        textalign: props.textalign
    }),
    ...(props.space === "both" && {
        marginBottom: "10px",
        marginTop: "10px"
    }),
    ...(props.space !== "both" && {
        padding: "8px 0"
    }),
}))

const PhoneButton = styled(Button)((props: ButtonTypes) => ({
    backgroundColor: "#EDF3F8",
    ...(props.shape !== "squre" && {
        borderRadius: "30px",
        fontSize: "20px",
        height: "60px",
        width: "60px",
        fontWeight: 600,
    }),
    "&:hover": {
        backgroundColor: "#EDF3F8",
    },
    ...(props.shape === "squre" && {
        fontWeight: 700,
        fontSize: "14px",
        padding: "8px 16px",
        color: "#4D4D4D"
    }),
    margin: "4px",
    }))

const ContainedButton = styled(Button)((props: ButtonTypes) => ({
    ...(props.bgcolor === "success" && {
        backgroundColor: "#3FB98C",
        "&:hover": {
            backgroundColor: "#3FB98C"
        },
    }),
    ...(props.variant === "outlined" && {
        border: '1px solid white',
        "&:hover": {
            backgroundColor: "#ffff",
            color: "#3FB98C",
        },
    }),
    color: "white",
    ...(props.bgcolor !== "success" && {
        backgroundColor: "white",
        color: "#3FB98C",
        "&:hover": {
            backgroundColor: "#ffff",
        },
    }),
    marginBottom: "24px",
    borderRadius: "8px",
    height: "56px",
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Montserrat-regular",
}))

export { ContainedButton, Outlined, PhoneButton }
// Customizable Area End